import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import { getMessageForError } from 'src/nightingale/components/ChartElement/ChartElement.utils';

export const ChartElementSnackbar: React.FC<{
  error: Error | null;
  clearError: () => void;
}> = ({ error, clearError }) => {
  const styles = useStyles();

  return (
    <Snackbar
      action={[
        <IconButton color="inherit" onClick={clearError} key="close">
          <CloseIcon />
        </IconButton>,
      ]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
      className={styles.snackbar}
      data-testid="chart-element-snackbar-error"
      message={
        <>
          <h3 className={styles.snackbarTitle}>Server error</h3>
          <p>We encountered an error trying to save your changes:</p>
          <pre className={styles.snackbarPre}>
            <code>{getMessageForError(error) ?? error?.toString()}</code>
          </pre>
        </>
      }
      onClose={clearError}
      open={!!error}
    />
  );
};

const useStyles = makeStyles({
  snackbar: {
    '& .MuiPaper-root': {
      backgroundColor: '#F07562',
    },
  },
  snackbarTitle: {
    marginTop: 0,
  },
  snackbarPre: {
    whiteSpace: 'pre-wrap',
  },
});
