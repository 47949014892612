import { parseISO } from 'date-fns';
import gql from 'graphql-tag';
import { useContext } from 'react';
import useSWR from 'swr';

import { USE_NEW_QUEUE_API } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { DropInClinicRequestStatus } from 'src/dropInClinic/requestQueue';
import useRootStore from 'src/stores/useRootStore';

type ClinicRequestGql = {
  queuedAt: string;
  requestId: string;
  requestType: string;
  status: DropInClinicRequestStatus;
  acceptable: boolean;
};

type ClinicQueueResponse = {
  getDropInClinicQueue: ClinicRequestGql[];
};

type ClinicRequest = Omit<ClinicRequestGql, 'queuedAt'> & {
  queuedAt: Date;
};

const CLINIC_QUEUE_QUERY = gql`
  query ClinicQueue {
    getDropInClinicQueue {
      queuedAt
      requestId
      requestType
      status
      acceptable
    }
  }
`;

const deserializeClinicRequest = (request: ClinicRequestGql): ClinicRequest => ({
  ...request,
  queuedAt: parseISO(request.queuedAt),
});

type AcceptableClinicRequestGql = {
  request: {
    requestTime: string;
    id: string;
    requestType: string;
    status: DropInClinicRequestStatus;
  };
  acceptableForProvider: {
    acceptable: boolean;
  };
};

type AcceptableClinicQueueResponse = {
  staff_getClinicDropInQueue: AcceptableClinicRequestGql[];
};

type AcceptableClinicRequest = {
  requestTime: Date;
  id: string;
  requestType: string;
  status: DropInClinicRequestStatus;
  acceptable: boolean;
};

const ACCEPTABLE_CLINIC_QUEUE_QUERY = gql`
  query AcceptableClinicQueue($providerId: ID!) {
    staff_getClinicDropInQueue {
      request {
        requestTime
        id
        requestType
        status
      }
      acceptableForProvider(providerId: $providerId) {
        acceptable
      }
    }
  }
`;

const deserializeAcceptableClinicRequest = (
  entry: AcceptableClinicRequestGql,
): AcceptableClinicRequest => ({
  ...entry.request,
  requestTime: parseISO(entry.request.requestTime),
  acceptable: entry.acceptableForProvider.acceptable,
});

export const useAcceptableClinicRequest = (): ClinicRequest | AcceptableClinicRequest | null => {
  const {
    data: clinicData,
    error: clinicError,
    isValidating: clinicIsValidating,
  } = useSWR<ClinicQueueResponse>(CLINIC_QUEUE_QUERY);

  const rootStore = useRootStore();
  const providerId = rootStore.auth.user?.id;

  const { data, error, isValidating } = useSWR<AcceptableClinicQueueResponse>([
    providerId ? ACCEPTABLE_CLINIC_QUEUE_QUERY : null,
    { providerId },
  ]);

  const flags = useContext(FeatureFlagContext);
  const useNewQueueApi = !!flags[USE_NEW_QUEUE_API];

  if (!useNewQueueApi) {
    if (clinicError || clinicIsValidating || !clinicData) {
      return null;
    }

    const acceptableRequests = clinicData.getDropInClinicQueue.filter(
      request => request.acceptable,
    );

    if (acceptableRequests.length < 1) {
      return null;
    }

    return deserializeClinicRequest(acceptableRequests[0]);
  }

  if (error || isValidating || !data) {
    return null;
  }

  const acceptableRequests = data.staff_getClinicDropInQueue.filter(
    entry => entry.acceptableForProvider.acceptable,
  );

  if (acceptableRequests.length < 1) {
    return null;
  }

  return deserializeAcceptableClinicRequest(acceptableRequests[0]);
};
