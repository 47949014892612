import { gql } from 'src/generated/gql';

export const GET_VISIT_REASON = gql(`
  query GetVisitReason($eventId: ID!) {
    staff_getVisitReasonFromEvent(eventId: $eventId) {
      reason
      medicationsToRefill
      moreThanTwoDaysBup
      questionOrConcern
    }
  }
`);
