import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import React, { useState } from 'react';

import AttendeeControl from 'src/components/forms/controls/attendee';
import Field from 'src/components/forms/field';
import ChatComponent from 'src/components/pages/pageElements/chat';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import { PATIENT_CHAT_CONVERSATION_VALIDATOR } from 'src/shared/client/forms/validation/conversation';
import { User } from 'src/shared/stores/resource';

const ParticipantChat = props => {
  const [createConversationWith, setCreateConversationWith] = useState(false);

  const handleSelectConversation = conversation => {
    props.rootStore.routerStore.goTo('showPatient', {
      params: {
        id: props.rootStore.patients.patient.id,
        tab: 'conversation',
        tabItem: conversation.id,
      },
    });
  };

  const handleSelectNewConversation = () => {
    props.rootStore.routerStore.goTo('showPatient', {
      params: {
        id: props.rootStore.patients.patient.id,
        tab: 'conversation',
        tabItem: 'create',
      },
    });
  };

  const handleOpenVideoConversation = message => {
    const generatedUrl = props.rootStore.generateRouteUrl('chatVc', {
      conversation: props.rootStore.routerStore.routerState.params.tabItem,
      message: message.id,
    });
    window.open(generatedUrl, '_blank');
  };

  const handleCreatePatientConversation = () => {
    setCreateConversationWith(true);
  };

  const closeCreateDialog = () => {
    setCreateConversationWith(false);
  };

  const handleFinishCreate = ({ conversationAttendees }) => {
    let users = [];

    if (conversationAttendees) {
      users = users.concat(
        conversationAttendees.map(user =>
          User.create({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            pronouns: user.pronouns,
            avatar: user.avatar,
            __typename: user.__typename,
          }),
        ),
      );
    }
    const conversation = props.rootStore.patients.chat.conversationWith(...users);
    closeCreateDialog();

    if (conversation.id) {
      handleSelectConversation(conversation);
    } else {
      handleSelectNewConversation();
    }
  };

  const {
    classes,
    isLoading,
    rootStore: {
      chat: { allConversationsWithId: allOtherConversationsWithId },
      patients,
      providers: { provider },
      flash,
    },
  } = props;

  if (isLoading || !patients.chat) return null;

  const {
    patient,
    chat: {
      sortedConversations,
      newConversation,
      chatOwner,
      allConversationsWithId,
      currentConversation,
      conversationsLoaded,
    },
  } = patients;

  const patientFirstName = patient.preferredFirstName;

  const otherConvos = sortedConversations.filter(({ users }) =>
    users.every(({ user: { id } }) => id !== provider?.id),
  );

  const myConvos = sortedConversations.filter(({ users }) =>
    users.some(({ user: { id } }) => id === provider?.id),
  );

  const myConversationsList = {
    key: 'my-messages',
    chatOwner,
    heading: `My Conversations with ${patientFirstName}`,
    headerIcon: <AddCircleOutlineIcon />,
    headerIconAction: handleCreatePatientConversation,
    headerIconTooltip: 'Add new Patient Conversation',
    conversations: myConvos,
    hasNewConversation: newConversation,
    warn: true,
  };

  const otherConversationsList = {
    key: 'other-messages',
    chatOwner,
    heading: `${patientFirstName}'s Other Messages`,
    conversations: otherConvos,
    warn: true,
    isOtherConversations: true,
  };

  return (
    <div className={classes.root} data-testid="participant-chat">
      <div className={classes.contents}>
        <ChatComponent
          loaded={conversationsLoaded || newConversation}
          currentConversation={currentConversation}
          newConversation={newConversation}
          handleSelectConversation={handleSelectConversation}
          handleSelectNewConversation={handleSelectNewConversation}
          handleOpenVideoConversation={handleOpenVideoConversation}
          user={provider}
          conversationLists={[myConversationsList, otherConversationsList]}
          allConversationsWithId={allConversationsWithId}
          allOtherConversationsWithId={allOtherConversationsWithId}
          showErrorMessage={flash}
          sortedConversations={sortedConversations}
          patientId={props.rootStore.patients.patient.id}
        />
      </div>

      {createConversationWith && (
        <Formik
          isInitialValid
          onReset={closeCreateDialog}
          onSubmit={handleFinishCreate}
          validationSchema={PATIENT_CHAT_CONVERSATION_VALIDATOR}
          initialValues={{ conversationAttendees: [getSnapshot(provider), getSnapshot(patient)] }}
        >
          {({ handleSubmit, handleReset, isValid, dirty }) => (
            <ConfirmDialog
              maxWidth="sm"
              title="Add a conversation with..."
              submitLabel="Ok"
              onCancel={handleReset}
              onSubmit={handleSubmit}
              disabled={!isValid || !dirty}
            >
              <div>
                <Field
                  label="Patient"
                  name="conversationAttendees"
                  component={AttendeeControl}
                  placeholder="Select Patient..."
                  fixed={[getSnapshot(provider), getSnapshot(patient)]}
                />
              </div>
            </ConfirmDialog>
          )}
        </Formik>
      )}
    </div>
  );
};

const styles = () => ({
  root: {
    display: 'flex',
    flex: 1,
    // We don't want to scroll the overall chat UI, so lock it to page height.
    // But make space for the top nav bar.
    height: 'calc(100vh - 64px)',
  },
  contents: {
    flex: 1,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(ParticipantChat)));
