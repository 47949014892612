import gql from 'graphql-tag';

export const ACCEPT_MUTATION = gql`
  mutation AcceptDropInClinicRequest($requestId: String!) {
    acceptDropInClinicRequest(requestId: $requestId) {
      eventId
    }
  }
`;

export const REMOVE_MUTATION = gql`
  mutation RemoveDropInClinicRequest($requestId: String!) {
    removeDropInClinicRequest(requestId: $requestId) {
      status
    }
  }
`;

export const PREPARING_MUTATION = gql`
  mutation PreparingDropInClinicRequest($requestId: String!) {
    prepareDropInClinicRequest(requestId: $requestId) {
      eventId
    }
  }
`;

export const COMPLETE_PREP_MUTATION = gql`
  mutation CompletePrepDropInClinicRequest($requestId: String!) {
    completePrepDropInClinicRequest(requestId: $requestId) {
      status
    }
  }
`;

export const FIX_ZOMBIE_REQUEST_MUTATION = gql`
  mutation FixZombieDropInClinicRequest($requestId: String!) {
    fixZombieDropInClinicRequest(requestId: $requestId) {
      status
    }
  }
`;

export const AVAILABILITY_QUERY = gql`
  fragment ProviderAvailabilityFragment on GeneralAvailability {
    providerAvailability {
      dropInProvider {
        firstName
        lastName
      }
      availability {
        start
        end
      }
    }
    overview {
      windows {
        start
        end
      }
      hasAvailability
    }
  }

  query GetProviderAvailabilityByState($date: DateTime) {
    getClinicAvailabilitiesByDate(date: $date) {
      AK {
        ...ProviderAvailabilityFragment
      }
      CO {
        ...ProviderAvailabilityFragment
      }
      NC {
        ...ProviderAvailabilityFragment
      }
      OH {
        ...ProviderAvailabilityFragment
      }
      OR {
        ...ProviderAvailabilityFragment
      }
      WA {
        ...ProviderAvailabilityFragment
      }
    }
  }
`;

export const GET_QUEUE_SCHEDULE = gql`
  query SuggestClinicSchedule {
    suggestClinicSchedule {
      suggestions {
        request {
          id
        }
        startTime
        duration
        provider {
          id
          firstName
          lastName
        }
      }
      unscheduledRequests {
        request {
          id
        }
        reason
      }
    }
  }
`;
