import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState, useContext } from 'react';

import { DROP_IN_ATC_INTERFACE, USE_NEW_QUEUE_API } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ClinicRequestQueue from 'src/dropInClinic/ClinicRequestQueue';
import { CompletedVisitList } from 'src/dropInClinic/CompletedVisitList';
import { ProviderAvailabilities } from 'src/dropInClinic/providerAvailability';
import { ScheduledQueueSettings } from 'src/dropInClinic/queueSettings/ScheduledQueueSettings';
import RequestQueue from 'src/dropInClinic/requestQueue';
import { useStyles } from 'src/dropInClinic/styles';
import { ProviderSuggestedAssignments } from 'src/dropInClinic/suggestedAssignments';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

export enum DropInClinicSection {
  Queue = 'queue',
  CompletedVisits = 'completedVisits',
  Settings = 'settings',
  Availability = 'availability',
  SuggestedAssignments = 'suggestedAssignments',
}

export type DropInClinicProps = {
  activeSection: DropInClinicSection;
};

export const DropInClinic = (props: DropInClinicProps) => {
  const classes = useStyles();

  const [activeSection, setActiveSection] = useState(props.activeSection);
  const flags = useContext(FeatureFlagContext);
  const showSettings = !!flags[DROP_IN_ATC_INTERFACE];
  const useNewQueueApi = !!flags[USE_NEW_QUEUE_API];

  return (
    <div className={classes.pageContainer}>
      <PageTitle>Pop-In Clinic</PageTitle>
      <Box style={{ borderBottom: '1px solid', marginTop: -15 }}>
        <Tabs value={activeSection} onChange={(_, v) => setActiveSection(v)}>
          <Tab value={DropInClinicSection.Queue} label="Current Queue" />
          <Tab value={DropInClinicSection.CompletedVisits} label="Completed Visits" />
          {showSettings && <Tab value={DropInClinicSection.Settings} label="Queue Settings" />}
          <Tab value={DropInClinicSection.Availability} label="Availability" />
          <Tab value={DropInClinicSection.SuggestedAssignments} label="Suggested Assignments" />
        </Tabs>
      </Box>
      {activeSection === DropInClinicSection.Queue && useNewQueueApi && <ClinicRequestQueue />}
      {activeSection === DropInClinicSection.Queue && !useNewQueueApi && <RequestQueue />}
      {activeSection === DropInClinicSection.CompletedVisits && <CompletedVisitList />}
      {activeSection === DropInClinicSection.Settings && <ScheduledQueueSettings />}
      {activeSection === DropInClinicSection.Availability && <ProviderAvailabilities />}
      {activeSection === DropInClinicSection.SuggestedAssignments && (
        <ProviderSuggestedAssignments />
      )}
    </div>
  );
};
