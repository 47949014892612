import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { getMinutes } from 'date-fns';
import clone from 'lodash/clone';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import type { ModelCreationType } from 'mobx-state-tree';
import React, { useCallback, useState } from 'react';

import { CalendarBounds, getEventToolTip, isSlotDuringEvent } from 'src/calendars';
import AvailabilityCalendar from 'src/calendars/availabilityCalendar';
import AvailabilityTypeSelectMenu from 'src/calendars/availabilityTypeSelectMenu';
import EventCalendar from 'src/calendars/eventCalendar';
import ErrorAlert from 'src/components/general/ErrorAlert';
import { getEventDisplayTitle } from 'src/events/getEventDisplayTitle';
import { PageColumn } from 'src/nightingale/components/common/PageColumn/PageColumn';
import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import {
  EVENT_COLORS,
  UNSCHEDULED_EVENT_STATUSES,
  AVAILABILITY_SUB_TYPE_ITEMS,
  roleHasAvailabilitySubTypes,
} from 'src/shared/util/events';
import type { EventInstance } from 'src/stores/models/event';
import useRootStore from 'src/stores/useRootStore';
import { ProviderRole } from 'src/stores/users/userType';
import { colors as calendarColors } from 'src/util/calendar';

const AVAILABLE_HOUR_VIEWS = ['week', 'day'];

interface AvailabilityState {
  selectedOptions: Partial<ModelCreationType<EventInstance>>;
  position: CalendarBounds;
}

const ProviderShowPage: React.FC = observer(() => {
  const rootStore = useRootStore();
  const classes = useStyles();
  const [availabilityState, setAvailabilityState] = useState<AvailabilityState | null>(null);

  const {
    providers: { calendar },
    routerStore: {
      routerState: {
        params: { tab = 'calendar' },
      },
    },
    events: { createEvent },
    auth: { user },
    providers,
  } = rootStore;
  const { provider } = providers;

  const handleChangeTab = (event, value) => {
    // Preserve the current date that we're viewing to make it easier
    // to swap between scheduled appointments and availables around the same time,
    // but make sure the view we were on was valid for the availabilities calendar
    const updatedDate = get(rootStore.routerStore.routerState, 'queryParams.date');
    let updatedView = get(rootStore.routerStore.routerState, 'queryParams.view');
    if (value === 'hours' && !AVAILABLE_HOUR_VIEWS.includes(updatedView)) {
      updatedView = 'week';
    }
    rootStore.routerStore.goTo('showProvider', {
      params: {
        tab: value,
        id: rootStore.routerStore.routerState.params.id,
      },
      queryParams: {
        date: updatedDate,
        view: updatedView,
      },
    });
  };

  const handleCreateEvent = useCallback(
    options => {
      rootStore.events.createEvent(provider, options);
    },
    [provider],
  );

  const handleCreateAvailability = useCallback(
    (
      options: Partial<ModelCreationType<EventInstance>>,
      wasSelecting: boolean,
      selectPosition: CalendarBounds,
    ) => {
      if (!provider) return;

      // If this create action was performed after selection, it was the result of a drag action,
      // and we just want to auto-create the event without opening up the modal
      const displayClinicianAvailabilitySubtypes =
        provider.teamRole && roleHasAvailabilitySubTypes(provider.teamRole as ProviderRole);
      if (wasSelecting && displayClinicianAvailabilitySubtypes) {
        setAvailabilityState({
          selectedOptions: options,
          position: selectPosition,
        });
      } else {
        createEvent(
          rootStore.providers.provider,
          { type: 'availability', ...options },
          !wasSelecting,
        );
      }
    },
    [provider],
  );

  const handleCloseAvailabilityMenu = () => {
    setAvailabilityState(null);
  };

  const handleSaveEvent = (item, updates) => {
    rootStore.events.updateEventAndSave(item, updates);
  };

  const getAvailabilityEventTitle = event =>
    AVAILABILITY_SUB_TYPE_ITEMS[event.subType]?.label || 'Available';

  if (!provider) return <ProviderNotFoundError />;

  const titlePrefix =
    provider.id === user?.id ? 'My ' : `${provider.firstName} ${provider.lastName}'s `;
  const calendarName = tab === 'calendar' ? 'Calendar' : 'Available Hours';

  return (
    <div key={provider.id}>
      <Tabs className={classes.tabs} value={tab} onChange={handleChangeTab}>
        <Tab label="Calendar" value="calendar" />
        <Tab label="Available Hours" value="hours" />
      </Tabs>
      <div className={classes.tabContent}>
        {tab === 'calendar' && (
          <EventCalendar
            timezone={provider.timezone}
            actionTooltip="Add event"
            calendar={calendar}
            className="striped-calendar"
            events={calendar.eventInstances}
            onCreate={handleCreateEvent}
            onSave={handleSaveEvent}
            title={titlePrefix + calendarName}
            showPatientColumn
            showCheckbox
            titleAccessor={getEventDisplayTitle}
            tooltipAccessor={event => getEventToolTip(event, getEventDisplayTitle(event))}
            slotPropGetter={rawSlotTime => setSlotStyling(rawSlotTime, calendar.availableInstances)}
          />
        )}
        {tab === 'hours' && (
          <AvailabilityCalendar
            timezone={provider.timezone}
            actionTooltip="Add availability"
            calendar={calendar}
            events={calendar.availableInstances}
            onCreate={handleCreateAvailability}
            views={AVAILABLE_HOUR_VIEWS}
            title={titlePrefix + calendarName}
            titleAccessor={getAvailabilityEventTitle}
            slotPropGetter={(slotTime: Date) => {
              const isEventSlot = calendar.eventInstances.some(
                event =>
                  !event.allDay &&
                  !UNSCHEDULED_EVENT_STATUSES.includes(event.status as string) &&
                  isSlotDuringEvent(slotTime, event),
              );

              return isEventSlot
                ? {
                    style: {
                      backgroundColor: EVENT_COLORS.LIGHT_GREEN,
                    },
                  }
                : {};
            }}
          />
        )}
        {availabilityState && (
          <AvailabilityTypeSelectMenu
            position={clone(availabilityState.position)}
            availabilityOptions={availabilityState.selectedOptions}
            handleClose={handleCloseAvailabilityMenu}
            rootStore={rootStore}
          />
        )}
      </div>
    </div>
  );
});

const setSlotStyling = (slotTime: Date, availabilityInstances: EventInstance[]) => {
  const isOnTheHour = getMinutes(slotTime) === 0;
  const availabilitySlot = availabilityInstances.find(availability =>
    isSlotDuringEvent(slotTime, availability),
  );

  let backgroundColor;
  let borderTopColor;
  if (!availabilitySlot) {
    return {
      style: {
        borderTop: 'none',
        backgroundColor: 'transparent',
      },
    };
  } else if (!availabilitySlot.subType) {
    backgroundColor = 'white';
    borderTopColor = isOnTheHour ? calendarColors.OFF_WHITE : 'white';
  } else if (availabilitySlot.subType in AVAILABILITY_SUB_TYPE_ITEMS) {
    backgroundColor = AVAILABILITY_SUB_TYPE_ITEMS[availabilitySlot.subType].backgroundColor;
    borderTopColor = isOnTheHour
      ? calendarColors.OFF_WHITE
      : AVAILABILITY_SUB_TYPE_ITEMS[availabilitySlot.subType].backgroundColor;
  }
  return { style: { backgroundColor, borderTopColor } };
};

const ProviderNotFoundError: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.errorPage}>
      <PageContainer>
        <PageColumn>
          <ErrorAlert
            title="Provider not found"
            message="No provider could be found for the given ID."
          />
        </PageColumn>
      </PageContainer>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  tabs: {
    marginLeft: 15,
  },
  tabContent: {
    padding: theme.spacing(3),
  },
  errorPage: {
    '&, > main': {
      height: 'calc(100vh - 64px)',
    },
  },
  '@global': {
    '.borderless-calendar': {
      '& .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot': {
        backgroundColor: 'inherit !important',
      },
    },
  },
}));

export default ProviderShowPage;
