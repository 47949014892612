import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import classNames from 'classnames';
import stringify from 'json-stringify-safe';
import startCase from 'lodash/startCase';
import { inject, observer } from 'mobx-react';
import AccCore from 'opentok-accelerator-core';
import 'opentok-archiving';
import 'opentok-solutions-css';
import React from 'react';

import { SHOW_VIDEO_CALL_TIMER } from 'src/components/featureflags/currentFlags';
import FeatureFlag from 'src/components/featureflags/featureFlag';
import * as BN from 'src/components/general/BrowserNotification';
import Tooltip from 'src/components/general/Tooltip';
import { VIDEO_END_KEY } from 'src/components/general/VideoEndDialog';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import InviteGuestModal from 'src/components/pages/pageElements/inviteGuestModal';
import SignalConnectionTelemetryDataPanelGroup from 'src/components/pages/pageElements/videoConference/SignalConnectionTelemetryDataPanelGroup';
import { TimerPanel } from 'src/components/pages/pageElements/videoConference/TimerPanel';
import { CLINICIAN_EVENT_SUBTYPES } from 'src/shared/util/events';
import logger from 'src/shared/util/logger';
import { apiUrl, OPENTOK_API_KEY } from 'src/util';
import { getPatientAttendeeName } from 'src/util/users';

const OT_OPTIONS = {
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type) {
    return {
      publisher: {
        camera: '#cameraPublisherContainer',
        screen: '#screenPublisherContainer',
      },
      subscriber: {
        camera: '#cameraSubscriberContainer',
        screen: '#screenSubscriberContainer',
      },
    }[pubSub][type];
  },
  controlsContainer: '#centerControls',
  communication: {
    callProperties: null, // Using default
  },
  screenSharing: {
    extensionID: 'dnjafhififhecmajapclnkkdjmihpplg',
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      style: {
        buttonDisplayMode: 'off',
      },
      videoSource: 'window',
      fitMode: 'contain', // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: '#outerVideoContainer',
      subscriber: '#outerVideoContainer',
    },
  },
  archiving: {
    startURL: `${apiUrl}/video-archive/start`,
    stopURL: `${apiUrl}/video-archive/stop`,
  },
};

const connectingMask = classes => (
  <div className={classes.mask}>
    <CircularProgress className={classes.maskSpinner} />
    <div className={classes.maskMessage}>Connecting</div>
  </div>
);

const startCallMask = (start, goBack, classes, numStreams, patientAttendeeName) => {
  return (
    <div className={classes.mask}>
      <Button variant="contained" color="primary" onClick={start}>
        Join
      </Button>
      <div className={classes.maskMessage}>
        <div className={classes.maskMessageItem}>Attendees currently in visit: {numStreams}</div>
        {!!patientAttendeeName && (
          <div className={classes.maskMessageItem}>Scheduled Patient: {patientAttendeeName}</div>
        )}
      </div>
      {window.history.length > 1 && (
        <Fab size="small" className={classes.back} onClick={goBack}>
          <BackIcon />
        </Fab>
      )}
    </div>
  );
};

const showErrorMask = classes => (
  <div className={classes.mask}>
    <p>
      An unexpected error while connecting to the call.
      <br />
      Please refresh the page to try again.
    </p>
  </div>
);

// Count the number of video input devices that
// we can successfully get a video stream for.
async function getVideoDeviceCount() {
  if (navigator.mediaDevices?.enumerateDevices) {
    // Request default audio and video stream so that the user will be
    // prompted for permission for both of these at the same time,
    // otherwise our subsequent code here will prompt for video and then
    // audio will happen separately when the user joins the call
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      stream.getTracks().forEach(track => track.stop());
    } catch (err) {
      return undefined;
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasMediaChecks = devices
      .filter(device => device.kind === 'videoinput')
      .map(device =>
        navigator.mediaDevices
          .getUserMedia({ video: { deviceId: { exact: device.deviceId } } })
          .catch(() => false),
      );

    const hasMediaResults = await Promise.all(hasMediaChecks);
    hasMediaResults.forEach(stream => {
      if (stream) {
        try {
          stream.getTracks().forEach(track => track.stop());
        } catch (err) {
          console.log(err);
        }
      }
    });
    return hasMediaResults.filter(hasMedia => hasMedia).length;
  }
  return undefined;
}

class VideoConference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      connecting: true,
      failed: false,
      active: false,
      // publishers: null,
      // subscribers: null,
      signalConnections: [],
      meta: null,
      numStreams: 0,
      localAudioEnabled: true,
      localVideoEnabled: true,
      showCameraSwitchButton: false,
      showInviteModal: false,
      showEndCallModal: false,
      completedVideoEndDialog: false,
    };
  }

  videoEndBc = new BroadcastChannel(VIDEO_END_KEY);

  async componentDidMount() {
    const { sessionId, token, enableArchiving = true } = this.props;

    let options = OT_OPTIONS;
    if (enableArchiving) {
      options = { ...options, packages: ['archiving'] };
    }

    getVideoDeviceCount().then(count =>
      this.setState({ showCameraSwitchButton: count !== undefined && count > 1 }),
    );

    /*
     * Initializing a new AccCore will call OT.initSession, which calls
     * OT.checkSystemRequirements. If it detects the system doesn't support
     * OpenTok, a message is displayed to the user indicating the system
     * requirements for video to play.
     * Ideally we would display our own design for this messaging, but this
     * works for now, as OpenTok-accelerator-core doesn't provide a great
     * way to access the OT.checkSystemRequirements underneath.
     */
    this.otCore = new AccCore({
      ...options,
      credentials: {
        apiKey: OPENTOK_API_KEY,
        sessionId,
        token,
      },
    });

    [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
      'endScreenShare',
    ].forEach(eventName =>
      this.otCore.on(eventName, event => {
        this.handleSubscriberEvent(eventName, event);
        const { publishers, subscribers, meta } = event;
        this.setState({ publishers, subscribers, meta }); // eslint-disable-line react/no-unused-state
      }),
    );

    ['streamCreated', 'streamDestroyed'].forEach(eventName =>
      this.otCore.getSession().on(eventName, event => {
        this.handleStreamBoundaryEvent(eventName, event);
      }),
    );
    this.otCore.getSession().on('streamPropertyChanged', this.handleStreamPropertyChanged);
    this.otCore.getSession().on('signal', this.populatePatientTelemetryData);

    this.otCore.connect().then(
      () => this.setState({ connected: true, connecting: false }),
      error => {
        this.setState({ failed: true, connecting: false });
        logger.error('Failed to connect to video: ', error.message);
      },
    );

    const patientAttendee = this.props.attendees?.find(
      attendee => attendee.__typename === 'Patient',
    );

    if (patientAttendee) {
      this.props.rootStore.patients?.loadChat(patientAttendee.id);
    }

    window.addEventListener('beforeunload', this.onComponentClose);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onComponentClose);
    this.videoEndBc.close();
    this.otCore.disconnect();
  }

  onComponentClose = () => {
    if (!this.state.completedVideoEndDialog && this.isClinicianVisit) {
      this.videoEndBc.postMessage(this.props.rootStore.events.event.id);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showBrowserNotification = message => {
    if (this.state.active) {
      BN.show('Boulder Care Staff App', {
        renotify: true,
        tag: 'BoulderCare:Staff',
        requireInteraction: true,
        body: message,
        icon: '/logoBrowserNotification.png',
        image: '/logoBrowserNotification.png',
      });
    }
  };

  /** Indexes the numStreams state by 1 or -1 when streamCreated or streamDestroyed event
   *  is passed into it.
   * @see {@link https://www.tokbox.com/developer/sdks/js/reference/StreamEvent.html|Stream Event Doc}
   */
  updateNumStreams = event => {
    if (event.type === 'streamCreated') {
      this.setState(oldState => ({ numStreams: oldState.numStreams + 1 }));
    } else if (event.type === 'streamDestroyed') {
      this.setState(oldState => ({ numStreams: oldState.numStreams - 1 }));
    }
  };

  logVideoEvent = (eventName, event, details = {}) => {
    const {
      sessionId,
      rootStore: {
        auth: {
          user: { id },
        },
      },
    } = this.props;
    const addedDetails = {
      careProviderId: id,
      ...details,
    };
    const detailsString = stringify(addedDetails, null, 2);
    const message = `OpenTok event ${eventName} in session ${sessionId}: ${detailsString} full event not logged for PII reasons`;
    logger.info(message);
  };

  /** Handles OpenTok session events on the Accelerator Core object.
   * @see {@link https://github.com/opentok/accelerator-core-js#events|Accelerator Core README: Events}
   * @see {@link https://www.tokbox.com/developer/sdks/js/reference/Session.html#events|Session Events Doc}
   */
  handleSubscriberEvent = (eventName, event) => {
    const { subscribers } = event;
    const cameraSubscribers = subscribers?.camera;
    if (!cameraSubscribers) {
      this.logVideoEvent(eventName, event, {
        summary: 'no camera subscribers',
      });
      return;
    }

    Object.keys(cameraSubscribers).forEach(cameraKey => {
      cameraSubscribers[cameraKey].on('videoDisabled', this.handleVideoDisabled);
      cameraSubscribers[cameraKey].on('videoEnabled', this.handleVideoEnabled);
      this.logVideoEvent(eventName, event, { cameraKey });
    });
  };

  /** Handles the 'videoDisabled' event. Note this is a Subscriber event,
   * which we attach to a Subscriber object when they change in the
   * handleSubscriberEvent function.
   * @see {@link https://www.tokbox.com/developer/sdks/js/reference/Subscriber.html#event:videoDisabled|Subscriber videoDisabled Event Doc}
   */
  handleVideoDisabled = event => {
    this.logVideoEvent('videoDisabled', event, {
      reason: event.reason,
      cameraKey: event.target.id,
    });
    const notificationMessage = 'Someone disabled their video';
    this.showBrowserNotification(notificationMessage);
  };

  handleVideoEnabled = () => {
    const notificationMessage = 'Someone enabled their video';
    this.showBrowserNotification(notificationMessage);
  };

  /** Specifically handles 'streamCreated' or 'streamDestroyed' events.
   * @see {@link https://www.tokbox.com/developer/sdks/js/reference/StreamEvent.html|Stream Event Doc}
   */
  handleStreamBoundaryEvent = (eventName, event) => {
    const { stream } = event;
    const incomingConnectionId = stream.connection.connectionId;

    if (eventName === 'streamDestroyed') {
      // remove stream and connection info from signalConnections
      this.setState(prevState => {
        return {
          signalConnections: prevState.signalConnections.filter(connection => {
            return connection.connectionId !== incomingConnectionId;
          }),
        };
      });
    }

    this.logVideoEvent(eventName, event);
    if (eventName === 'streamDestroyed') {
      const notificationMessage = 'Someone left your visit';
      this.showBrowserNotification(notificationMessage);
    }
    // maybe state.signalConnections can be used to determine number of streams?
    this.updateNumStreams(event);
  };

  /** Handles any stream property changes. Note that this function creates
   * a summary to give a quick description of the event before logging.
   * @see {@link https://www.tokbox.com/developer/sdks/js/reference/Session.html#event:streamPropertyChanged|Session Events Doc}
   * @see {@link https://www.tokbox.com/developer/sdks/js/reference/StreamPropertyChangedEvent.html|StreamPropertyChangedEvent Doc}
   */
  handleStreamPropertyChanged = event => {
    let { oldValue, newValue } = event;
    if (event.changedProperty === 'videoDimensions') {
      oldValue = `${event.oldValue.width}x${event.oldValue.height}`;
      newValue = `${event.newValue.width}x${event.newValue.height}`;
    }
    this.logVideoEvent('streamPropertyChanged', event, {
      summary: `${event.changedProperty}: ${oldValue} -> ${newValue}`,
    });
  };

  populatePatientTelemetryData = event => {
    const {
      data, // the signalData payload, parse this stringified json object
      from: { connectionId: incomingConnectionId }, // used to associate signalData with a specific stream/connection
    } = event;
    const incomingSignalData = JSON.parse(data);

    const foundConnection = this.state.signalConnections.find(
      connection => connection.connectionId === incomingConnectionId,
    );

    if (foundConnection) {
      // connection already exists, so update w/ incomingSignalData
      this.setState(prevState => ({
        signalConnections: prevState.signalConnections.map(connection =>
          // attach signalState to the found connection
          connection.connectionId === incomingConnectionId
            ? { ...connection, signalData: incomingSignalData, lastUpdated: new Date() }
            : connection,
        ),
      }));
    } else {
      // add stream and connection info to signalConnections
      this.setState(prevState => ({
        signalConnections: [
          ...prevState.signalConnections,
          {
            connectionId: incomingConnectionId,
            signalData: incomingSignalData,
            lastUpdated: new Date(),
          },
        ],
      }));
    }
  };

  goBack = () => {
    window.history.back();
  };

  startCall = async () => {
    const {
      rootStore: {
        auth: { user },
      },
    } = this.props;

    const { firstName, patientFacingDisplayName, teamRole = '' } = user;
    const nameForVideoVisit = `${patientFacingDisplayName || firstName}, ${startCase(teamRole)}`;

    // set earlier since being active starts as soon as the startCall method
    // is invoked. Waiting until after the call started caused odd behavior
    // with the join button.
    this.setState({ active: true });
    const { publishers, subscribers, meta } = await this.otCore.startCall({
      name: nameForVideoVisit,
    });
    // set audio and video in call to pre-chosen values
    this.otCore.toggleLocalVideo(this.state.localVideoEnabled);
    this.otCore.toggleLocalAudio(this.state.localAudioEnabled);
    this.setState({
      publishers, // eslint-disable-line react/no-unused-state
      subscribers, // eslint-disable-line react/no-unused-state
      meta,
    });
    BN.requestPermission();
  };

  endCall = () => {
    this.otCore.endCall();
    this.setState({ active: false });
  };

  isVideoComplete = async isVideoComplete => {
    this.setState({ showEndCallModal: false, completedVideoEndDialog: true });
    if (this.isClinicianVisit) {
      this.props.rootStore.events.saveEvent({
        isVideoComplete,
        videoEnd: new Date(),
      });
    }
  };

  toggleLocalAudio = () => {
    this.otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState(oldState => ({ localAudioEnabled: !oldState.localAudioEnabled }));
  };

  toggleLocalVideo = () => {
    this.otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState(oldState => ({ localVideoEnabled: !oldState.localVideoEnabled }));
  };

  switchCamera = () => {
    if (this.state.publishers?.camera) {
      const { camera } = this.state.publishers;
      const publisher = camera[Object.keys(camera)[0]];
      if (publisher?.cycleVideo) {
        try {
          publisher.cycleVideo().catch(err => {
            logger.error(err);
            if (err.stack) {
              logger.error(err.stack);
            }
          });
        } catch (err) {
          logger.error(err);
          if (err.stack) {
            logger.error(err.stack);
          }
        }
      }
    }
  };

  openEndCallModal = () => {
    this.setState({ showEndCallModal: true });
  };

  openInviteModal = () => {
    this.setState({ showInviteModal: true });
  };

  isClinicianVisit = CLINICIAN_EVENT_SUBTYPES.includes(this.props.rootStore.events.event.subType);

  render() {
    const {
      attendees,
      classes,
      enableSharing,
      guestUrl,
      inviteGuestByEmail,
      invitePeerWithReason,
    } = this.props;
    const {
      active,
      connected,
      connecting,
      failed,
      localAudioEnabled,
      localVideoEnabled,
      meta,
      numStreams,
      showCameraSwitchButton,
      showInviteModal,
      showEndCallModal,
    } = this.state;

    const sharingScreen = meta ? !!meta.publisher.screen : false;
    const viewingSharedScreen = meta ? meta.subscriber.screen : false;
    const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
    const screenshareActive = viewingSharedScreen || sharingScreen;

    const patientAttendeeName = getPatientAttendeeName(attendees);
    const patientAttendee = attendees?.find(attendee => attendee.__typename === 'Patient');

    const conversationId =
      this.props.rootStore.patients?.chat?.defaultConversationWithCurrentUser?.id;

    let subColumns;
    let subRows;
    if (activeCameraSubscribers > 0) {
      // Set a column and row for the subscriber container to ensure all participants stay on screen during the call
      // Between 1 - 3 participants: Just display them in one row
      // For 4+ participants: tile the participants to be arranged as close to a square as possible
      subRows = activeCameraSubscribers < 4 ? 1 : Math.ceil(Math.sqrt(activeCameraSubscribers));
      subColumns = Math.ceil(activeCameraSubscribers / subRows);
    }

    return (
      <div>
        <div className={classes.main}>
          <div id="outerVideoContainer" className={classes.outerVideoContainer}>
            {connecting && connectingMask(classes)}
            {connected &&
              !active &&
              startCallMask(this.startCall, this.goBack, classes, numStreams, patientAttendeeName)}
            {failed && showErrorMask(classes)}
            {connected && active && (
              <SignalConnectionTelemetryDataPanelGroup
                signalConnections={this.state.signalConnections}
              />
            )}
            {connected && this.props.eventId && patientAttendee && !active && (
              <FeatureFlag name={SHOW_VIDEO_CALL_TIMER}>
                <TimerPanel
                  conversationId={conversationId}
                  eventId={this.props.eventId}
                  patientId={patientAttendee.id}
                  startTime={this.props.startTime}
                />
              </FeatureFlag>
            )}
            <div
              id="cameraPublisherContainer"
              className={classNames(classes.videoContainer, {
                [classes.videoContainerHidden]: !active,
                [classes.videoContainerSmall]: !!activeCameraSubscribers || screenshareActive,
                [classes.videoContainerLeft]: screenshareActive,
              })}
            />
            <div
              id="screenPublisherContainer"
              className={classNames(classes.videoContainer, {
                [classes.videoContainerHidden]: !active || !sharingScreen,
              })}
            />
            <div
              id="cameraSubscriberContainer"
              className={classNames(classes.videoContainer, {
                [classes.videoContainerHidden]: !active || !activeCameraSubscribers,
                [classes.videoContainerSmall]: screenshareActive,
              })}
              style={
                subColumns &&
                subRows && {
                  gridTemplateColumns: `repeat(${subColumns}, 1fr)`,
                  gridTemplateRows: `${(100 / subRows).toString()}%`,
                }
              }
            />
            <div
              id="screenSubscriberContainer"
              className={classNames(classes.videoContainer, {
                [classes.videoContainerHidden]: !viewingSharedScreen || !active,
              })}
            />
          </div>
          <div
            id="controls"
            className={classNames(classes.controls, { [classes.controlsHidden]: !active })}
          >
            {!!patientAttendeeName && (
              <div id="patientName" className={classNames(classes.patientName)}>
                {`Patient: ${patientAttendeeName}`}
              </div>
            )}
            <div className={classes.leftControls}>
              {showCameraSwitchButton && active && (
                <Fab className={classes.cameraSwitchButton} onClick={this.switchCamera}>
                  <SwitchCameraIcon />
                </Fab>
              )}
            </div>
            <div id="centerControls" className={classes.centerControls}>
              <div
                className={classNames('ots-video-control circle microphone', {
                  hidden: !active,
                  muted: !localAudioEnabled,
                  disabled: !localAudioEnabled,
                })}
                onClick={this.toggleLocalAudio}
              />
              <div
                className={classNames('ots-video-control circle video', {
                  hidden: !active,
                  muted: !localVideoEnabled,
                  disabled: !localVideoEnabled,
                })}
                onClick={this.toggleLocalVideo}
              />
              <div
                className={classNames('ots-video-control circle end-call', { hidden: !active })}
                onClick={() => {
                  this.endCall();
                  if (this.isClinicianVisit) {
                    this.openEndCallModal();
                  }
                }}
              />
              {enableSharing && (
                <div>
                  <Tooltip title="Invite Guest">
                    <Fab
                      onClick={this.openInviteModal}
                      className={classNames('ots-video-control circle', classes.inviteGuestButton)}
                    >
                      <PersonAddIcon />
                    </Fab>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={classes.rightControls} />
          </div>
          <div id="chat" />
          {showInviteModal && (
            <InviteGuestModal
              eventId={this.props.eventId}
              inviteGuestByEmail={inviteGuestByEmail}
              invitePeerWithReason={invitePeerWithReason}
              guestUrl={guestUrl}
              onCancel={() => {
                this.setState({ showInviteModal: false });
              }}
            />
          )}
          {showEndCallModal && (
            <ConfirmDialog
              onSubmit={() => {
                this.isVideoComplete(true);
              }}
              onCancel={() => {
                this.isVideoComplete(false);
              }}
              submitLabel="Yes, visit complete"
              cancelLabel="No, visit incomplete"
              title="Did you complete this visit successfully?"
              requireSelection
            />
          )}
        </div>
      </div>
    );
  }
}

const styles = () => ({
  main: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    top: 0,
    backgroundColor: 'black',
  },
  back: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
  cameraSwitchButton: {
    marginLeft: 20,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  inviteGuestButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
  controlsHidden: {
    display: 'none',
  },
  centerControls: {
    display: 'flex',
  },
  leftControls: {
    display: 'flex',
    flex: 1,
  },
  rightControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  outerVideoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mask: {
    width: '100%',
    height: '100%',
    position: 'relative',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  maskSpinner: {
    position: 'absolute',
  },
  maskMessage: {
    fontWeight: 500,
    position: 'absolute',
    top: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  maskMessageItem: {
    marginBottom: 15,
  },
  patientName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'absolute',
    bottom: 86,
    width: '100%',
    zIndex: 1,
    fontSize: 20,
    color: 'white',
  },
  videoContainer: {
    width: '100%',
    height: '100%',
    display: 'grid',
  },
  videoContainerSmall: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 160,
    height: 96,
    border: '1px solid #fcba00',
    zIndex: 2,
  },
  videoContainerHidden: {
    display: 'none',
  },
  '@global': {
    '.ots-video-control': {
      width: 50,
      height: 50,
      margin: '20px 5px !important',
      borderRadius: '50%',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      transition: 'transform .2s',
    },
    '.ots-video-control:hover': {
      transform: 'scale(1.1)',
    },
    '.ots-video-control.microphone.muted': {
      backgroundColor: 'red',
    },
    '.ots-video-control.video.muted': {
      backgroundColor: 'red',
    },
    '.ots-video-control.end-call': {
      backgroundImage: 'url(https://assets.tokbox.com/solutions/images/icon-hang-up.png)',
      backgroundColor: 'red',
    },
  },
});

export default withStyles(styles)(inject('rootStore')(observer(VideoConference)));
