import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import Smooch from 'smooch';

import { ZENDESK_WEB_MESSENGER_DEBUGGING_TOOL } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { ZENDESK_WEB_MESSENGER_INTEGRATION_KEY } from 'src/util';

const QUERY_VENDOR_EXTERNAL_ID = gql`
  query GetVendorExternalId($id: String!) {
    getZendeskPatientMapping(patientId: $id) {
      boulderExternalPatientId
      sunCoUserId
    }
  }
`;

const SYNC_PATIENT = gql`
  mutation SyncPatient($id: String!) {
    syncPatient(patientId: $id)
  }
`;

const CREATE_MESSAGING_JWT = gql`
  mutation CreateMessagingAuthTokenForPatient($id: String!) {
    createMessagingAuthTokenForPatient(patientId: $id)
  }
`;

const WebMessenger = ({ patientId }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const flags = useContext(FeatureFlagContext);
  const isZendeskWebMessengerEnabled = !!flags[ZENDESK_WEB_MESSENGER_DEBUGGING_TOOL];

  const [generatedMessagingJWT, setGeneratedMessagingJWT] = useState<string | null>(null);
  const [loadingVendorExternalId, setLoadingVendorExternalId] = useState<boolean>(false);
  const [vendorExternalId, setVendorExternalId] = useState<string | null>(null);

  useEffect(() => {
    if (!patientId) return;
    getVenderExternalId();
  }, [patientId]);

  useEffect(() => {
    generateMessagingJWT();
  }, [vendorExternalId]);

  const delegate = {
    beforeSend(message) {
      return { ...message, metadata: { ...message.metadata, userExternalId: vendorExternalId } };
    },
  };

  // Instantiate Smooch Web Messenger 💋 as soon as all required values are defined.
  useEffect(() => {
    if (
      isZendeskWebMessengerEnabled &&
      ZENDESK_WEB_MESSENGER_INTEGRATION_KEY &&
      generatedMessagingJWT &&
      vendorExternalId
    ) {
      Smooch.init({
        integrationId: ZENDESK_WEB_MESSENGER_INTEGRATION_KEY,
        externalId: vendorExternalId,
        jwt: generatedMessagingJWT,
      }).then(() => {
        Smooch.setDelegate(delegate);
      });
    }
    return Smooch.destroy;
  }, [generatedMessagingJWT, isZendeskWebMessengerEnabled, vendorExternalId]);

  const getVenderExternalId = async () => {
    setLoadingVendorExternalId(true);
    try {
      const query = await apolloClient?.query({
        query: QUERY_VENDOR_EXTERNAL_ID,
        variables: { id: patientId },
      });

      const entity = query?.data?.getZendeskPatientMapping;
      if (entity?.boulderExternalPatientId && entity.sunCoUserId) {
        setVendorExternalId(entity.boulderExternalPatientId);
      } else {
        setVendorExternalId(null);
      }
    } catch (err) {
      console.log(`Error loading vender external id. ${err}`);
    } finally {
      setLoadingVendorExternalId(false);
    }
  };

  const generateMessagingJWT = async () => {
    if (generatedMessagingJWT) return;
    try {
      const result = await apolloClient?.mutate({
        mutation: CREATE_MESSAGING_JWT,
        variables: { id: patientId },
      });
      const newGeneratedJWT = result?.data.createMessagingAuthTokenForPatient;
      setGeneratedMessagingJWT(newGeneratedJWT);
    } catch (err) {
      console.log(`Error generating messaging JWT. ${err}`);
    }
  };

  const handleSyncPatient = async () => {
    await apolloClient?.mutate({
      mutation: SYNC_PATIENT,
      variables: { id: patientId },
    });
    // TODO: The SYNC_PATIENT mutation should return the Zendesk Vendor Mapping entity.
    // We can then set venderExternalId from that data instead of making another network
    // request here.
    await getVenderExternalId();
  };

  return isZendeskWebMessengerEnabled && !vendorExternalId ? (
    <Button
      disabled={loadingVendorExternalId}
      onClick={handleSyncPatient}
      color="primary"
      variant="outlined"
      size="small"
      style={styles.syncButton}
    >
      Sync Patient to Sunshine Conversations
    </Button>
  ) : null;
};

const styles = {
  syncButton: {
    marginTop: 24,
  },
} as const;

export default WebMessenger;
