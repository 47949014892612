import { Paper, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import {
  IconArrowBigRightLines,
  IconCalendarShare,
  IconClipboardCheck,
  IconClock,
  IconDropletCheck,
  IconInfoSquareRounded,
  IconRotate2,
  IconUserCircle,
  IconUserPlus,
  IconUserStar,
  IconVaccineBottleOff,
  IconFriends,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import React, { useContext } from 'react';

import { DROP_IN_ATC_INTERFACE } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { ActionButtons } from 'src/dropInClinic/ActionButtons';
import { Duration } from 'src/dropInClinic/Duration';
import { EndorsementNotifications } from 'src/dropInClinic/EndorsementNotifications';
import { PatientDetails } from 'src/dropInClinic/PatientDetails';
import { ProviderName } from 'src/dropInClinic/ProviderName';
import { useStyles } from 'src/dropInClinic/RequestRow.styles';
import { RequestType } from 'src/dropInClinic/RequestType';
import { QueueEntry } from 'src/dropInClinic/hooks/useRequestQueue';
import { DropInClinicProvider, UnscheduledRequestReason } from 'src/dropInClinic/types';
import { VisitReason } from 'src/dropInClinic/visitReason.component';
import Colors from 'src/nightingale/Colors';
import { ProviderRole } from 'src/stores/users/userType';

const REQUEST_TYPE_ICON = {
  FOLLOW_UP: <IconCalendarShare />,
  INTAKE: <IconUserPlus />,
  OFT: <IconDropletCheck />,
  PEER_VISIT: <IconUserCircle />,
  REENGAGEMENT: <IconRotate2 />,
  CASE_MANAGER_VISIT: <IconFriends />,
};

const unscheduledReasonToText = {
  [UnscheduledRequestReason.INTERNAL_ERROR]: 'A Scheduling Error Occurred',
  [UnscheduledRequestReason.NO_CREDENTIALED_PROVIDER]: 'No Credentialed Provider Available',
  [UnscheduledRequestReason.NO_TIME]: 'Credentialed Providers at Capacity',
  [UnscheduledRequestReason.SINGLE_PROVIDER_OVERBOOKED]: 'Credentialed Provider at Capacity',
};

const PlainTooltip = withStyles(() => ({
  tooltip: {
    margin: 0,
    padding: 0,
    maxWidth: 220,
    backgroundColor: 'transparent',
    fontSize: '0.875rem',
  },
}))(Tooltip);

type RequestTypeColumnProps = {
  requestType: string;
};

const RequestTypeColumn = React.memo<RequestTypeColumnProps>(({ requestType }) => {
  const classes = useStyles({});

  return (
    <div className={classes.requestType}>
      {REQUEST_TYPE_ICON[requestType]}
      <RequestType requestType={requestType} />
    </div>
  );
});

type RequestRowProps = {
  entry: QueueEntry;
  providerRole: ProviderRole | null;
  currentTime: Date;
  mutate: () => void;
  onRemoveRequest: (requestId: string) => void;
  onUpdateError: (error: any) => void;
  additionalNotes?: string;
  suggestedProvider?: DropInClinicProvider | null;
  suggestedTime?: string | null;
  unscheduledReason?: string | null;
};

export const RequestRow = ({
  additionalNotes,
  currentTime,
  entry,
  mutate,
  onRemoveRequest,
  onUpdateError,
  providerRole,
  suggestedProvider,
  suggestedTime,
  unscheduledReason,
}: RequestRowProps) => {
  const { categories, patient, queuedAt, requestId, requestType, status } = entry;

  const flags = useContext(FeatureFlagContext);
  const atcInterface = !!flags[DROP_IN_ATC_INTERFACE];

  const custom = useStyles({ atcInterface });
  const hasNote = !!additionalNotes;

  const classList = [custom.row];

  if (hasNote) classList.push('with-note');
  if (unscheduledReason) {
    classList.push(custom.unscheduledRow);
  }

  return (
    <div className={classList.join(' ')}>
      <div className={custom.removeButton}>
        <Tooltip title="Remove this patient from the queue">
          <IconButton onClick={() => onRemoveRequest(requestId)} size="small">
            <CloseIcon fontSize="small" data-testid="remove-request" />
          </IconButton>
        </Tooltip>
      </div>
      <PlainTooltip title={<Paper style={{ padding: 8 }}>{additionalNotes}</Paper>}>
        <a
          className={`${custom.indicator} ${custom.noteIndicator}`}
          href={`/patients/${entry.patient.id}/overview#fyi`}
        >
          <IconInfoSquareRounded color={Colors.Coral} />
        </a>
      </PlainTooltip>
      <div className={custom.name}>
        <PatientDetails patient={patient} categories={categories} />
        {unscheduledReason && (
          <span className={custom.unscheduledWarning}>
            <WarningIcon />
            <div>{unscheduledReasonToText[unscheduledReason]}</div>
          </span>
        )}
        {suggestedProvider && (
          <div className={custom.scheduleSuggestion}>
            <IconUserStar size={14} />
            <ProviderName provider={suggestedProvider} />
            <span>&nbsp;at about {suggestedTime}</span>
          </div>
        )}
      </div>
      <div className={custom.visitReason}>
        <VisitReason reason={entry.reason} />
      </div>
      <RequestTypeColumn requestType={requestType} />
      <div className={custom.waitingTime}>
        <IconClock />
        <Duration date={queuedAt} currentTime={currentTime} />
      </div>
      <div className={custom.medicationDate}>
        {patient.medicationRunOutDate && (
          <>
            <IconVaccineBottleOff />
            {format(patient.medicationRunOutDate, 'E MMM dd, yyyy')}
          </>
        )}
      </div>
      <div className={custom.currentStatus}>
        {status === 'PREPARING' && (
          <>
            <IconArrowBigRightLines />
            Prep in progress
          </>
        )}
        {status === 'PREPARED' && (
          <>
            <IconClipboardCheck />
            Prep complete
          </>
        )}
        {entry.preparedBy && (
          <div>
            <ProviderName provider={entry.preparedBy} />
          </div>
        )}
      </div>
      <div className={custom.actions}>
        <ActionButtons
          entry={entry}
          mutate={mutate}
          providerRole={providerRole}
          setRequestUpdateError={onUpdateError}
        />
        <EndorsementNotifications entry={entry} providerRole={providerRole} />
      </div>
    </div>
  );
};
