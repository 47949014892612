import { gql } from 'src/generated/gql';

export const GET_COMPLETED_VISITS = gql(`
  query CompletedDropInClinicVisits {
    getCompletedDropInClinicVisits {
      categories
      event {
        id
        completedAt
      }
      patient {
        id
        firstName
        lastName
        preferredName
        homeState
        dob
      }
      provider {
        firstName
        lastName
      }
      requestId
      requestType
    }
  }
`);
