import BugReport from '@material-ui/icons/BugReport';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import React, { useContext } from 'react';

import { VIDEO_FEEDBACK, COUNTERSIGN } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ActionsPopup from 'src/components/general/actionsPopup';
import { CLINICIAN_EVENT_SUBTYPES, EVENT_STATUSES } from 'src/shared/util/events';
import { ProviderRole } from 'src/stores/users/userType';

const EventStatusActionsPopup = ({
  event,
  user,
  parentElement,
  onCancel,
  onClose,
  onComplete,
  onFeedback,
  onReschedule,
  onUncomplete,
  onCountersign,
  onUncountersign,
}) => {
  const flags = useContext(FeatureFlagContext);
  const isCountersignEnabled = flags[COUNTERSIGN];
  const isVideoFeedbackFormEnabled = flags[VIDEO_FEEDBACK];

  const clinicianSignatureRequired = CLINICIAN_EVENT_SUBTYPES.includes(event.subType);
  const userIsAttendee = !!event.attendees?.find(attendee => attendee.id === user.id);

  const unauthorizedSigner =
    !userIsAttendee || (clinicianSignatureRequired && user.teamRole !== ProviderRole.Clinician);

  let signingDisabledReason;
  if (event.status === EVENT_STATUSES.CANCELED) {
    signingDisabledReason = 'Canceled visits do not need to be signed & completed';
  } else if (unauthorizedSigner) {
    signingDisabledReason = clinicianSignatureRequired
      ? 'This clinical visit can only be signed by a clinician who attended'
      : 'A visit can only be signed by a care team member who attended';
  }

  const isCompletedAndSigned = event.status === EVENT_STATUSES.COMPLETED && event.signedBy;

  return (
    <ActionsPopup
      parentElement={parentElement}
      onClose={onClose}
      actions={[
        {
          isDisplayed: true,
          isDisabled: [EVENT_STATUSES.RESCHEDULED, EVENT_STATUSES.COMPLETED].includes(event.status),
          onClick: () => onCancel(event),
          iconComponent: DeleteIcon,
          label: 'Cancel...',
        },
        {
          isDisplayed: true,
          isDisabled: event.status === EVENT_STATUSES.COMPLETED,
          onClick: () => onReschedule(event),
          iconComponent: CalendarTodayIcon,
          label: 'Reschedule...',
        },
        {
          isDisplayed: isVideoFeedbackFormEnabled,
          onClick: () => onFeedback(event),
          iconComponent: BugReport,
          label: 'Send Video Call Feedback',
        },
        {
          isDisplayed: event.status !== EVENT_STATUSES.COMPLETED,
          isDisabled: event.status === EVENT_STATUSES.RESCHEDULED || !!signingDisabledReason,
          onClick: () => onComplete(event),
          iconComponent: CheckCircleIcon,
          label: 'Sign & Complete',
          tooltip: signingDisabledReason,
        },
        {
          isDisplayed: event.status === EVENT_STATUSES.COMPLETED,
          onClick: () => onUncomplete(event),
          iconComponent: UndoIcon,
          label: 'Unsign & Un-Complete',
        },
        {
          isDisplayed: isCountersignEnabled && isCompletedAndSigned && !event.countersignedBy,
          onClick: () => onCountersign(event),
          iconComponent: CheckCircleIcon,
          label: 'Countersign',
        },
        {
          isDisplayed: isCountersignEnabled && isCompletedAndSigned && event.countersignedBy,
          onClick: () => onUncountersign(event),
          iconComponent: UndoIcon,
          label: 'Un-countersign',
        },
      ]}
    />
  );
};

export default EventStatusActionsPopup;
