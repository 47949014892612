"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = void 0;
var _isomorphicDompurify = _interopRequireDefault(require("isomorphic-dompurify"));
var _marked = require("marked");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_marked.marked.use({
  renderer: {
    link(href, title, text) {
      if (urlAllowed(href)) return `<a href="${href}"${title ? ` title="${title}"` : ''}>${text}</a>`;
      return '[Markdown links may only link to Staff App, Resource Library, Notion, or email addresses]';
    },
    image() {
      return '[Markdown images are not supported]';
    }
  }
});
function urlAllowed(url) {
  if (url.startsWith('mailto:')) {
    return true;
  }
  if (url.match(/^https?:\/\/(www\.)?notion\.so\/boulder\//)) {
    return true;
  }
  if (url.match(/^https?:\/\/(staff|resources)\.boulder\.care($|\/)/)) {
    return true;
  }
  return false;
}
const format = markdown => _isomorphicDompurify.default.sanitize(_marked.marked.parse(markdown, {
  breaks: true
}));
exports.format = format;