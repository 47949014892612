import { addMinutes, format, isValid } from 'date-fns';

import { SubType } from 'src/events/visitTypes';
import { getPreferredFullName } from 'src/shared/stores/resource';
import { BLOCKED_EVENT_SUB_TYPES, labelForEventType } from 'src/shared/util/events';
import { EventInstance } from 'src/stores/models/event';

export const getEventDisplayTitle = (event: EventInstance) => {
  const eventLabel = labelForEventType(event.type, event.subType);
  const timeDisplay = getTimeDisplay(event);

  const firstPatient = event.attendees.find(
    attendee => attendee.__typename === 'Patient' || attendee.__typename === 'MyDayTodayPatient',
  );

  if (event && firstPatient) {
    return `${eventLabel}: ${getPreferredFullName(firstPatient)} ${timeDisplay}`;
  }

  if (event.subType && BLOCKED_EVENT_SUB_TYPES.includes(event.subType as SubType) && event.title) {
    return `${eventLabel}: ${event.title} ${timeDisplay}`;
  }

  return `${eventLabel}: ${timeDisplay}`;
};

export const getEventDisplayTitleWithoutPatient = (event: EventInstance) => {
  const eventLabel = labelForEventType(event.type, event.subType);
  const timeDisplay = getTimeDisplay(event);

  return `${eventLabel} ${timeDisplay}`;
};

export const getTimeDisplay = (event: EventInstance): string => {
  if (!event.start || !event.duration || !isValid(event.start)) {
    return '';
  }

  const end = addMinutes(event.start, event.duration);
  const startMeridian = format(event.start, 'aaa');
  const endMeridian = format(end, 'aaa');
  const displayStartMeridian = startMeridian !== endMeridian;

  return `${format(event.start, 'h:mm')} ${displayStartMeridian ? startMeridian : ''} - ${format(
    end,
    'h:mm',
  )} ${endMeridian}`;
};
