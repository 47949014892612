import { Grid } from '@material-ui/core';
import { isValid } from 'date-fns';
import { parseISO } from 'date-fns/fp';
import isToday from 'date-fns/isToday';
import countBy from 'lodash/fp/countBy';
import get from 'lodash/fp/get';
import React from 'react';
import useSWR from 'swr';

import ErrorAlert from 'src/components/general/ErrorAlert';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { EligibilitySummary } from 'src/dropInClinic/eligibility/EligibilitySummary';
import { EligibilityTable } from 'src/dropInClinic/eligibility/EligibilityTable';
import {
  EligibilitiesByVisitType,
  EligibilityEntry,
  GetPatientsEligibleForDropInClinicResponse,
} from 'src/dropInClinic/eligibility/types';
import type { VisitInfo } from 'src/dropInClinic/eligibility/types';
import { GET_NEXT_VISIT } from 'src/dropInClinic/queries/getNextVisit';
import { GET_PATIENTS_ELIGIBLE_FOR_DROP_IN_CLINIC_QUERY } from 'src/dropInClinic/queries/getPatientsEligibleForDropInClinic';
import { useStyles } from 'src/dropInClinic/styles';

type VisitMap<DateType> = Map<string, VisitInfo<DateType> | null>;

const convertDates = (
  requestType: string,
  entry: EligibilityEntry<string>,
  eventMap: VisitMap<string>,
) => {
  const visitInfo = eventMap.get(entry.patient.id);

  const dob = parseISO(entry.patient.dob);
  const expires = parseISO(entry.eligibility.expires);
  const lastSet = parseISO(entry.eligibility.lastSet);
  const medicationRunOutDate = parseISO(entry.patient.medicationRunOutDate);

  const maybeDate = date => (isValid(date) ? date : null);

  return {
    ...entry,
    eligibility: {
      ...entry.eligibility,
      expires: maybeDate(expires),
      lastSet: maybeDate(lastSet),
    },
    patient: {
      ...entry.patient,
      dob: maybeDate(dob),
      medicationRunOutDate: maybeDate(medicationRunOutDate),
    },
    requestType,
    nextVisit: visitInfo && {
      ...visitInfo,
      start: maybeDate(parseISO(visitInfo.start)),
    },
  };
};

const getPatientIds = (response?: EligibilitiesByVisitType): string[] => {
  if (!response) {
    return [];
  }

  const { followUp, intake } = response;
  const followUpIds: string[] = followUp?.map(entry => entry.patient.id) || [];
  const intakeIds: string[] = intake?.map(entry => entry.patient.id) || [];
  return Array.from(new Set(followUpIds.concat(intakeIds))) as string[];
};

const EligibilityListContainer: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TitleBar title="Pop-In Clinic Eligibility" />
      {children}
    </div>
  );
};

export const EligibilityList = () => {
  const todayOrOtherDay = (date: Date) => (isToday(date) ? 'today' : 'other');

  const { data, error } = useSWR<GetPatientsEligibleForDropInClinicResponse>(
    GET_PATIENTS_ELIGIBLE_FOR_DROP_IN_CLINIC_QUERY,
  );

  const patientIds = getPatientIds(data?.getPatientsEligibleForDropInClinic);

  const { data: nextEvents } = useSWR<{ nextEventForEach: Array<VisitInfo<string> | null> }>(() => {
    return patientIds.length && [GET_NEXT_VISIT, { patientIds }];
  });

  if (error) {
    return (
      <EligibilityListContainer>
        <ErrorAlert title="Unable to load Pop-In eligibility" message={error.message} />
      </EligibilityListContainer>
    );
  }

  if (!data) {
    return (
      <EligibilityListContainer>
        <div>Loading...</div>
      </EligibilityListContainer>
    );
  }

  const eventMap: VisitMap<string> = nextEvents?.nextEventForEach
    ? new Map(patientIds.map((id, index) => [id, nextEvents.nextEventForEach[index]]))
    : new Map();

  const eligibilityResponse = data.getPatientsEligibleForDropInClinic;

  const followUpList = eligibilityResponse.followUp.map(entry =>
    convertDates('FOLLOW_UP', entry, eventMap),
  );
  const intakeList = eligibilityResponse.intake.map(entry =>
    convertDates('INTAKE', entry, eventMap),
  );
  const reengagementList = eligibilityResponse.reengagement.map(entry =>
    convertDates('REENGAGEMENT', entry, eventMap),
  );

  const eligibilitySummaryData = {
    FOLLOW_UP: countBy<Date>(
      todayOrOtherDay,
      Object.values(followUpList).flatMap(get('eligibility.lastSet')),
    ),
    INTAKE: countBy<Date>(
      todayOrOtherDay,
      Object.values(intakeList).flatMap(get('eligibility.lastSet')),
    ),
    REENGAGEMENT: countBy<Date>(
      todayOrOtherDay,
      Object.values(reengagementList).flatMap(get('eligibility.lastSet')),
    ),
  };

  const eligibilityTableData = [...followUpList, ...intakeList, ...reengagementList];

  return (
    <EligibilityListContainer>
      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12} sm={12} md={3}>
          <EligibilitySummary eligibilityResponse={eligibilitySummaryData} />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <EligibilityTable eligibilityList={eligibilityTableData} />
        </Grid>
      </Grid>
    </EligibilityListContainer>
  );
};
