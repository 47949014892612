import { withStyles, Button } from '@material-ui/core';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import { ClassNameMap } from '@material-ui/styles';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { KeyedMutator } from 'swr';

import type {
  PatientDocumentsResponse,
  TreatmentAgreement,
} from 'src/components/pages/patientShow/documents/types';
import { ApolloClientContext } from 'src/data/ApolloClientContext';

const LATEST_TREATMENT_AGREEMENT_DOCUMENT_VERSION = '4';

export const TreatmentAgreementRequest: React.FC<{
  patientId: string;
  treatmentAgreement: TreatmentAgreement | null | undefined;
  onRequest: KeyedMutator<PatientDocumentsResponse>;
  classes: ClassNameMap;
}> = ({ patientId, treatmentAgreement, onRequest, classes }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const requestTreatmentAgreement = async () => {
    await apolloClient?.mutate({
      mutation: gql`
        mutation ($data: PatientInput!, $id: ID!) {
          updatePatient(data: $data, id: $id) {
            id
          }
        }
      `,
      variables: {
        data: {
          treatmentAgreement: {
            documentVersion: LATEST_TREATMENT_AGREEMENT_DOCUMENT_VERSION,
          },
        },
        id: patientId,
      },
    });
    onRequest();
  };

  if (treatmentAgreement?.completedAt) {
    return <span>Signed on {format(new Date(treatmentAgreement.completedAt), 'PP')}</span>;
  } else if (treatmentAgreement?.createdAt) {
    return <span>Requested on {format(new Date(treatmentAgreement.createdAt), 'PP')}</span>;
  } else {
    return (
      <Button onClick={requestTreatmentAgreement} color="secondary" variant="outlined" size="small">
        Request Treatment Agreement
        <MobileScreenShareIcon className={classes.rightIcon} />
      </Button>
    );
  }
};

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

export default withStyles(styles)(TreatmentAgreementRequest);
