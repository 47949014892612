import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { memo } from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import { Duration } from 'src/dropInClinic/Duration';
import { PatientDetails } from 'src/dropInClinic/PatientDetails';
import { ProviderName } from 'src/dropInClinic/ProviderName';
import { RequestType } from 'src/dropInClinic/RequestType';
import { ActiveVisit } from 'src/dropInClinic/hooks/useRequestQueue';
import Colors from 'src/nightingale/Colors';
import { EVENT_STATUSES } from 'src/shared/util/events';

export const useStyles = makeStyles(theme => ({
  visitRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    paddingBottom: '1rem',
    minHeight: '2rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  visitRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& div': {
      flexBasis: '40%',
    },
    '& div + div': {
      flexBasis: '20%',
    },
    '& a': {
      color: Colors.BlueSpruce,
    },
    minHeight: '2rem',
  },
  visitWarning: {
    color: Colors.Coral,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& div': {
      flexBasis: '80%',
    },
    '& button': {
      flexBasis: '20%',
    },
  },
}));

type ActiveVisitRowProps = {
  currentTime?: Date;
  onFixRequest?: (requestId: string) => void;
  visit: ActiveVisit;
};

export const ActiveVisitRow = memo(({ currentTime, onFixRequest, visit }: ActiveVisitRowProps) => {
  const { patient } = visit;

  const classes = useStyles();

  return (
    <div className={classes.visitRowContainer}>
      <div className={classes.visitRow}>
        <PatientDetails patient={patient} />
        <div>
          <ProviderName provider={visit.provider} />
        </div>
        <div>
          <RequestType requestType={visit.requestType} />
        </div>
        <div>
          {visit.event && currentTime && (
            <Duration date={visit.event.start} currentTime={currentTime} />
          )}
          {visit.event && !currentTime && format(visit.event.start, 'h:m a, MMM dd')}
        </div>
        <div>
          {visit.event?.id && (
            <a href={generateRouteUrl('showEvent', { id: visit.event?.id })}>View visit</a>
          )}
        </div>
      </div>
      {onFixRequest && visit.event?.status === EVENT_STATUSES.COMPLETED && (
        <div className={classes.visitWarning}>
          <div>
            This patient has a completed visit, but the associated pop-in queue request is still
            marked as active. Please confirm that the visit has been completed then click the fix
            request button.
          </div>
          <Button variant="contained" color="primary" onClick={() => onFixRequest(visit.requestId)}>
            Fix Request
          </Button>
        </div>
      )}
    </div>
  );
});
