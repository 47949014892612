import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
import VideoIcon from '@material-ui/icons/Videocam';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { RouterLink } from 'mobx-state-router';
import React, { useContext, useEffect, useState } from 'react';
import { Avatar, MessageList, Input } from 'react-chat-elements';

import Submit from 'src/components/forms/controls/submit';
import MiniIconButton from 'src/components/general/miniIconButton';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { getPreferredFullName } from 'src/shared/stores/resource';
import {
  UpdateConversationClosedResult,
  UPDATE_CONVERSATION_CLOSED,
} from 'src/stores/mutations/conversations';

function ConversationView({
  conversation,
  dataSource,
  handleSendVideoConferenceMessage,
  inputRef,
  actionsDisabled,
  onInputChange,
  onInputSubmit,
  onScroll,
  showArchiveButton,
  showArchiveForAllButton,
  submitDisabled,
  submitLoading,
  userMessagingEnabled,
}) {
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const classes = useStyles();

  const { apolloClient } = useContext(ApolloClientContext);

  useEffect(() => {
    if (!initiallyLoaded) {
      // HACK - Scroll to the bottom if we're not already there.
      // This is handled for us by react-chat-element's scroll handler when a conversation is selected,
      // but doesn't seem to kick off if you land directly on the chat page via URL or refresh
      const messageListElement = document.getElementsByClassName('rce-mlist')[0];
      messageListElement.scrollTop = messageListElement.scrollHeight;
      setInitiallyLoaded(true);
    }
  }, []);

  const isArchivedByCurrentUser = conversation.isArchivedForCurrentUser;
  const isArchivedForAll = conversation.isArchivedForAllInConversation;
  const isClosed = conversation.closed;

  const [loadingClosed, setLoadingClosed] = useState(false);

  const updateClosed = async () => {
    if (loadingClosed) {
      return;
    }
    setLoadingClosed(true);

    try {
      const result = await apolloClient?.mutate<UpdateConversationClosedResult>({
        mutation: UPDATE_CONVERSATION_CLOSED,
        variables: { conversationId: conversation.id, value: !isClosed },
      });
      conversation.updateClosed(result?.data?.updateConversationClosed?.closed);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingClosed(false);
    }
  };

  return (
    <div className={classes.contents}>
      <div className={classNames(classes.conversationHeader, 'rce-citem-avatar')}>
        <Avatar src={conversation.otherUsersAvatar} size="large" />
        {conversation.hasPatients && (
          <div
            className={classNames(classes.conversationHeaderPatient, 'rce-citem-body--top-title')}
          >
            <RouterLink
              routeName="showPatient"
              params={{ id: conversation.patient.user.id }}
              className={classes.conversationHeaderLink}
            >
              {getPreferredFullName(conversation.patient.user)}
              {conversation.patient.user.pronouns && ` (${conversation.patient.user.pronouns})`}
            </RouterLink>
          </div>
        )}
        {conversation.otherProviderUsers.length > 0 && (
          <div
            className={classNames(classes.conversationHeaderProviders, 'rce-citem-body--top-title')}
          >
            <span>Provider{conversation.otherProviderUsers.length > 1 && 's'}: </span>
            {conversation.otherProviderUsers.map((otherUser, index) => (
              <RouterLink
                key={otherUser.user.id}
                routeName="showProvider"
                params={{ id: otherUser.user.id }}
                className={classes.conversationHeaderLink}
              >
                {`${getPreferredFullName(otherUser.user)}`}
                {otherUser.user.patientFacingDisplayName &&
                  ` (${otherUser.user.patientFacingDisplayName})`}
                {index + 1 < conversation.otherProviderUsers.length && ', '}
              </RouterLink>
            ))}
          </div>
        )}
        <div className={classes.headerButtonContainer}>
          <div className={classes.row}>
            {showArchiveButton && (
              <Button
                disabled={conversation.messages.length === 0}
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.archiveButton}
                onClick={() => {
                  conversation.updateArchived(!isArchivedByCurrentUser);
                }}
              >
                {isArchivedByCurrentUser ? 'Unarchive for me' : 'Archive for me'}
              </Button>
            )}
            {showArchiveForAllButton && (
              <Button
                disabled={conversation.messages.length === 0}
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.archiveButton}
                onClick={() => {
                  conversation.updateArchivedForAll(!isArchivedForAll);
                }}
              >
                {isArchivedForAll ? 'Unarchive for all' : 'Archive for all'}
              </Button>
            )}
          </div>
          <div className={classes.row}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className={classes.archiveButton}
              onClick={updateClosed}
              disabled={loadingClosed || conversation.messages.length === 0}
            >
              {isClosed ? 'Open thread for patient' : 'Close thread for patient'}
            </Button>
          </div>
        </div>
      </div>
      <MessageList
        className={classes.messageList}
        onScroll={onScroll}
        lockable
        onClick={item => item.onClick?.(item)}
        dataSource={dataSource}
      />
      {userMessagingEnabled && (
        <>
          <Input
            ref={inputRef} // eslint-disable-line react/no-string-refs
            className={conversation.hasPatients && classes.patientInput}
            onChange={onInputChange}
            placeholder="Send a message..."
            multiline
            leftButtons={
              <>
                <MiniIconButton
                  onClick={handleSendVideoConferenceMessage}
                  disabled={actionsDisabled}
                >
                  <VideoIcon
                    classes={conversation.hasPatients ? { root: classes.patientVideoIcon } : {}}
                  />
                </MiniIconButton>
              </>
            }
            rightButtons={
              <Submit
                warn={conversation.hasPatients}
                variant="contained"
                color="primary"
                disabled={submitDisabled}
                loading={submitLoading}
                onClick={onInputSubmit}
              >
                {conversation.hasPatients ? 'Send to Patient' : 'Send'}
              </Submit>
            }
          />
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => {
  const lightWarning = lighten(theme.palette.warning.main, 0.6);

  return {
    row: {
      display: 'flex',
    },
    headerButtonContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: 15,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 210,
    },
    archiveButton: {
      flex: 1,
      margin: 4,
      whiteSpace: 'nowrap',
    },
    contents: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      '& .rce-input': {
        fontFamily: 'roboto',
      },
    },
    conversationHeader: {
      '&.rce-citem-avatar': {
        backgroundColor: 'white',
        borderBottom: '1px solid #dddddd',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 10,
      },
    },
    conversationHeaderPatient: {
      '&.rce-citem-body--top-title': {
        fontSize: 20,
        fontWeight: 500,
        marginTop: 5,
      },
    },
    conversationHeaderProviders: {
      '&.rce-citem-body--top-title': {
        marginTop: 5,
      },
    },
    conversationHeaderLink: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    messageList: {
      padding: 10,
      paddingBottom: 0,
      paddingTop: 0,
      flex: 1,
      overflowY: 'scroll',
    },
    patientInput: {
      backgroundColor: lightWarning,
      '& .rce-input': {
        backgroundColor: lightWarning,
      },
    },
    patientVideoIcon: {
      color: '#696158',
      '&:hover': {
        color: darken('#696158', 0.2),
      },
    },
  };
});

export default observer(ConversationView);
