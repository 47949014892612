import { ApolloError } from 'apollo-client';
import { parseISO } from 'date-fns';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { GET_COMPLETED_VISITS } from 'src/dropInClinic/hooks/useCompletedVisits.gql';
import { DropInClinicPatient } from 'src/dropInClinic/types';

type CompletedVisitsResponse =
  | { completedVisits: CompletedVisit[]; error: undefined }
  | { completedVisits: null; error: ApolloError | undefined };

export function useCompletedVisits(): CompletedVisitsResponse {
  const { data, error } = useTypedSWR(GET_COMPLETED_VISITS);
  if (!data || error) {
    return { completedVisits: null, error };
  }

  return {
    error: undefined,
    completedVisits: data.getCompletedDropInClinicVisits.map(entry => ({
      categories: entry.categories,
      requestId: entry.requestId,
      requestType: entry.requestType,
      event: entry.event
        ? {
            id: entry.event.id,
            completedAt: entry.event.completedAt ? parseISO(entry.event.completedAt) : null,
          }
        : null,
      patient: {
        id: entry.patient.id,
        firstName: entry.patient.firstName,
        lastName: entry.patient.lastName,
        homeState: entry.patient.homeState,
        preferredName: entry.patient.preferredName ?? undefined,
        dob: entry.patient.dob ? parseISO(entry.patient.dob) : undefined,
      },
      provider: entry.provider
        ? {
            firstName: entry.provider.firstName,
            lastName: entry.provider.lastName,
          }
        : null,
    })),
  };
}

export interface CompletedVisit {
  categories: string[];
  event: {
    id: string | null;
    completedAt: Date | null;
  } | null;
  patient: DropInClinicPatient;
  provider: {
    firstName: string;
    lastName: string;
  } | null;
  requestId: string;
  requestType: string;
}
