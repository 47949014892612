import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { getSnapshot } from 'mobx-state-tree';
import React, { ComponentPropsWithoutRef, MouseEventHandler } from 'react';

import AttendeeControl from 'src/components/forms/controls/attendee';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import { useVisitReason } from 'src/components/forms/hooks/useVisitReason';
import FieldGroup from 'src/components/general/fieldGroup';
import RouteLink from 'src/components/general/routeLink';
import {
  EVENT_DETAILS_VALIDATOR,
  isPatientRequired,
} from 'src/shared/client/forms/validation/event';
import { getPreferredFullName } from 'src/shared/stores/resource';
import { labelForEventType } from 'src/shared/util/events';
import { EventInstance } from 'src/stores/models/event';
import { UserDisplayType } from 'src/stores/models/userDisplay';

const EventSubType = ({ eventType, subType, label }) => (
  <FieldGroup label={label}>
    <Typography variant="body2">{labelForEventType(eventType, subType)}</Typography>
  </FieldGroup>
);

const AttendeeList = ({ attendees, label, classes }) => (
  <FieldGroup label={label}>
    <Typography variant="body2">
      {attendees.map((attendee, index) => (
        <RouteLink
          route={attendee.__typename === 'Provider' ? 'showProvider' : 'showPatient'}
          routeParams={[{ id: attendee.id }]}
          key={attendee.id}
          className={classes.attendees}
          newWindow
        >
          {attendee.preferredFullName}
          {index + 1 < attendees.length ? ', ' : ''}
        </RouteLink>
      ))}
    </Typography>
  </FieldGroup>
);

const ViewEventDetails: React.FC<{
  event: EventInstance;
  additionalProps?: {
    createdByDisplay?: UserDisplayType;
  };
}> = ({
  event: {
    id,
    title,
    type,
    subType,
    attendees,
    createdBy: eventCreatedBy,
    createdByDisplay,
    createdVia,
    createdAt,
  },
  additionalProps = null,
}) => {
  const visitReason = useVisitReason(id);
  const styles = useEventDetailsFormStyles();

  // If we click View on the event popup to open a visit note, we will not be able to use the createdBy
  // view defined in the events store and must refer to it as createdByDisplay instead. This is because
  // it was fetched after the initial event loading to improve calendar performance. In the visit summary
  // the event is not an MST object and we also cannot use the createdBy view. Otherwise, use the view.
  const createdBy = eventCreatedBy || createdByDisplay || additionalProps?.createdByDisplay;

  const patients = attendees.filter(attendee => attendee.__typename === 'Patient');
  const providers = attendees.filter(attendee => attendee.__typename === 'Provider');
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <EventSubType eventType={type} subType={subType} label="Visit Type" />
      </Grid>
      {title && (
        <Grid item xs={12} sm={4}>
          <FieldGroup label="Title">
            <Typography style={{ wordBreak: 'break-word' }} variant="body2">
              {title}
            </Typography>
          </FieldGroup>
        </Grid>
      )}
      {!!patients.length && (
        <Grid item xs={12} sm={4}>
          <AttendeeList attendees={patients} label="Patient" classes={styles} />
        </Grid>
      )}
      {!!providers.length && (
        <Grid item xs={12} sm={4}>
          <AttendeeList attendees={providers} label="Providers" classes={styles} />
        </Grid>
      )}
      {createdBy && (
        <Grid item xs={12} sm={4}>
          <FieldGroup label="Visit Creator">
            <Typography variant="body2">
              {createdBy.preferredFullName || getPreferredFullName(createdBy)}
            </Typography>
          </FieldGroup>
        </Grid>
      )}
      {createdVia && (
        <Grid item xs={12} sm={4}>
          <FieldGroup label="Visit Creator">
            <Typography variant="body2">Boulder Staff App</Typography>
          </FieldGroup>
        </Grid>
      )}
      {createdAt && (
        <Grid item xs={12} sm={4}>
          <FieldGroup label="Created At">
            <Typography variant="body2">{createdAt.toString()}</Typography>
          </FieldGroup>
        </Grid>
      )}
      {visitReason && (
        <Grid item xs={12} sm={4}>
          <FieldGroup label="Visit Reason">
            <Typography variant="body2">{visitReason}</Typography>
          </FieldGroup>
        </Grid>
      )}
    </Grid>
  );
};

const FormFields = ({ eventType, subType, fixedAttendees }) => {
  const styles = useFormFieldStyles();

  return (
    <>
      <EventSubType eventType={eventType} subType={subType} label="Visit Type" />
      <Field
        name="title"
        className={styles.formComponent}
        component={TextControl}
        label="Title"
        onKeyDown={e => e.stopPropagation()}
      />
      <div className={styles.formComponent}>
        <Field
          name="attendees"
          component={AttendeeControl}
          fixed={fixedAttendees.map(attendee => getSnapshot(attendee))}
          patientRequired={isPatientRequired(subType)}
        />
      </div>
    </>
  );
};

const useFormFieldStyles = makeStyles({
  formComponent: {
    marginTop: 24,
  },
});

const EditEventDetails: React.FC<{
  event: EventInstance;
  onSave?: (values: unknown) => Promise<unknown>;
  onCancel?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  formId?: string;
  savingDisabled?: boolean;
}> = ({ event, onSave, onCancel, formId, savingDisabled }) => {
  const styles = useEventDetailsFormStyles();
  const patientAttendees = event?.attendees.filter(attendee => attendee?.__typename === 'Patient');

  return (
    <EditForm
      item={event}
      classes={{
        buttons: styles.buttons,
        button: styles.button,
      }}
      validationSchema={EVENT_DETAILS_VALIDATOR}
      onSave={onSave}
      onCancel={onCancel}
      buttonSize="small"
      formId={formId}
      savingDisabled={savingDisabled}
    >
      <FormFields
        eventType={event.type}
        subType={event.subType}
        fixedAttendees={patientAttendees}
      />
    </EditForm>
  );
};

type EditEventProps = ComponentPropsWithoutRef<typeof EditEventDetails>;
type ViewEventProps = ComponentPropsWithoutRef<typeof ViewEventDetails>;

const EventDetailsForm: React.FC<
  ({ isEditing: true } & EditEventProps) | ({ isEditing?: false } & ViewEventProps)
> = ({ isEditing, ...props }) =>
  isEditing ? <EditEventDetails {...props} /> : <ViewEventDetails {...props} />;

const useEventDetailsFormStyles = makeStyles({
  attendees: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    marginRight: 5,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});

export const __test__ = { EventSubType };

export default EventDetailsForm;
