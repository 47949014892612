import { makeStyles } from '@material-ui/core/styles';

import Colors from 'src/nightingale/Colors';

export const useStyles = makeStyles(theme => ({
  pageContainer: {
    overflow: 'auto',
    position: 'absolute',
    top: 64,
    left: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(3),
  },
  queueTime: {
    fontStyle: 'italic',
  },
  requestTable: {
    '& a': {
      color: Colors.BlueSpruce,
    },
  },
  toggleSubText: {
    fontStyle: 'italic',
    fontSize: '80%',
    paddingLeft: 15,
  },
  unacceptable: {
    color: Colors.Coral,
  },
  visitList: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:last-child': {
      borderBottom: 'none',
    },
  },
  textWithIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cellHorizontalAlign: { display: 'flex', flexDirection: 'row' },
  removeRequest: {
    color: Colors.Gray3,
    marginRight: 5,
    '&:hover': { color: Colors.Gray6 },
  },
}));
