/* eslint-disable react/no-array-index-key */
import { IconButton } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import React, { useContext } from 'react';
import uuid from 'uuid/v4';

import { LIST_SORTING } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { ReactComponent as EditIcon } from 'src/nightingale/assets/list_edit.svg';
import { ReactComponent as RequiredBadge } from 'src/nightingale/assets/required_badge.svg';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import HelpText from 'src/nightingale/components/ChartProperty/controls/List/HelpText';
import { getConditionalMap } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.conditions';
import { sortValues } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.sorting';
import type { ListControlProps } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.types';
import { useStyles } from 'src/nightingale/components/ChartProperty/controls/List/ListControlView.styles';
import { isEmptyValue } from 'src/nightingale/data/ChartProperty.utils';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import { textValue } from 'src/nightingale/summarization';
import useRootStore from 'src/stores/useRootStore';

/**
 * Gets empty list item given chart properties for list control
 */
const getNewItem = (properties, itemId: string) => {
  // Set internal id to support item selection
  const newItem = { _internalListItemId: itemId };
  properties.forEach(property => {
    newItem[property.name] = property.default?.value ?? undefined;
  });
  return newItem;
};

/**
 * List Control view mode component
 */
type ListControlViewProps = {
  setEditingItemId: React.Dispatch<React.SetStateAction<string | null>>;
  setInternalValue: React.Dispatch<
    React.SetStateAction<
      Array<{
        [key: string]: any;
      }>
    >
  >;
} & Pick<
  ListControlProps,
  | 'autoFocus'
  | 'conditions'
  | 'disabled'
  | 'hasEmptyValue'
  | 'interactionId'
  | 'isRequired'
  | 'label'
  | 'listOrder'
  | 'name'
  | 'properties'
  | 'value'
>;

export const ListControlView: React.FC<ListControlViewProps> = ({
  autoFocus,
  conditions,
  disabled,
  hasEmptyValue,
  interactionId,
  isRequired,
  label,
  listOrder,
  name,
  properties,
  setEditingItemId,
  setInternalValue,
  value,
}) => {
  const styles = useStyles({ disabled });
  const flags = useContext(FeatureFlagContext);
  const helpText = flags[LIST_SORTING] && listOrder?.expression && listOrder?.helpText;

  const sortedValues =
    flags[LIST_SORTING] && value && listOrder
      ? sortValues(listOrder.expression, listOrder.direction, properties, value)
      : value;

  return (
    <div className={styles.container}>
      <LabeledFormControl hasEmptyValue={hasEmptyValue} isRequired={isRequired} label={label}>
        <ol className={styles.list}>
          {helpText ? <HelpText text={helpText} /> : null}
          {sortedValues
            ? sortedValues.map(val => (
                <ListControlViewItem
                  conditions={conditions}
                  disabled={disabled}
                  id={val._internalListItemId}
                  interactionId={interactionId}
                  key={`list-value-${val._internalListItemId}`}
                  name={name}
                  onClick={() => {
                    if (!disabled) setEditingItemId(val._internalListItemId);
                  }}
                  onKeyDown={({ key }) => {
                    if (key === 'Enter' && !disabled) setEditingItemId(val._internalListItemId);
                  }}
                  properties={properties}
                  value={val}
                />
              ))
            : null}
        </ol>
      </LabeledFormControl>
      <IconButton
        autoFocus={autoFocus}
        classes={{ root: styles.iconButton, disabled: styles.iconButtonDisabled }}
        data-testid="list-control-add"
        disableFocusRipple
        disabled={disabled}
        onClick={() => {
          const newItemId = uuid();
          const newValues = [...(sortedValues || []), getNewItem(properties, newItemId)];
          setInternalValue(newValues);
          setEditingItemId(newItemId);
        }}
        title="Add item"
      >
        <PlaylistAddIcon />
      </IconButton>
    </div>
  );
};

const ListControlViewItem: React.FC<{
  conditions: ListControlViewProps['conditions'];
  disabled?: boolean;
  id: string;
  interactionId: ListControlViewProps['interactionId'];
  name: ListControlViewProps['name'];
  onClick: React.MouseEventHandler;
  onKeyDown: React.KeyboardEventHandler;
  properties: ListControlViewProps['properties'];
  value: { [key: string]: any };
}> = ({ conditions, disabled, id, interactionId, name, onClick, onKeyDown, properties, value }) => {
  const styles = useStyles({ disabled });

  const conditionalMap = getConditionalMap({ properties, conditions }, value);

  const {
    patients: { patient },
  } = useRootStore();

  const requiredChartProperties = useRequiredChartProperties();

  return (
    <li
      className={styles.listItem}
      key={`list-value-${id}`}
      role="tab"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <ol className={styles.innerList}>
        {properties.map(property => {
          const isChildPropertyConditional = property.id in conditionalMap;
          if (isChildPropertyConditional && !conditionalMap[property.id]) {
            return null;
          }
          if (
            !requiredChartProperties.isRequired(name, property.name) &&
            isEmptyValue(value[property.name])
          ) {
            return null;
          }

          return (
            <li
              className={styles.innerListItem}
              data-testid="list-control-view-item"
              key={`${property.name}-${id}`}
            >
              {property.label}:{' '}
              {textValue(property.type, value[property.name], {
                timezone: patient?.timezone ?? undefined,
              })}
              {requiredChartProperties.isRequired(name, property.name) &&
              requiredChartProperties.isMissing(
                { ...property, value: value[property.name] },
                { name, interactionId },
              ) ? (
                <RequiredBadge
                  data-testid="list-view-item-required-badge"
                  className={styles.requiredAsterisk}
                />
              ) : null}
            </li>
          );
        })}
        <div className={styles.editIcon}>
          <IconButton
            classes={{ root: styles.listItemIcon, disabled: styles.listItemIconDisabled }}
            data-testid="list-control-edit"
            disabled={disabled}
            title="Edit item"
          >
            <SvgIcon component={EditIcon} />
          </IconButton>
        </div>
      </ol>
    </li>
  );
};
