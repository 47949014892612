import { useContext, useMemo } from 'react';

import { PEBBLE_TOPIC_BRIDGE_PRESCRIPTION_REQUEST } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { PEBBLE_TOPICS, PebbleTopic } from 'src/util/pebbles';

export const usePebbleTopics = () => {
  const shouldShowPebbleTopicBridgePrescriptionRequest =
    useContext(FeatureFlagContext)[PEBBLE_TOPIC_BRIDGE_PRESCRIPTION_REQUEST];
  const topics = useMemo(
    () =>
      PEBBLE_TOPICS.filter(topic =>
        topic.value === PebbleTopic.BridgePrescriptionRequest
          ? shouldShowPebbleTopicBridgePrescriptionRequest
          : true,
      ),
    [shouldShowPebbleTopicBridgePrescriptionRequest],
  );
  return topics;
};
