type EndorsementResult = {
  credentialError: boolean;
  stateError: boolean;
};

export const credentialError = (): EndorsementResult => ({
  credentialError: true,
  stateError: false,
});

export const stateError = (): EndorsementResult => ({ credentialError: false, stateError: true });

export const endorsementSuccess = (): EndorsementResult => ({
  credentialError: false,
  stateError: false,
});
