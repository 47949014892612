import { observer } from 'mobx-react';
import * as React from 'react';
import { ReactNode, useMemo, useState } from 'react';

import { manageFlagState } from 'src/components/featureflags/configCat';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const FeatureFlagProvider = ({
  rootStore: { auth },
  children,
}: {
  rootStore: RootStore;
  children: ReactNode | JSX.Element;
}) => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});
  useMemo(() => {
    if (auth.initialized) {
      // note that the user ID may be undefined if they're logged out and we're rendering the login
      // screen.
      manageFlagState(
        {
          sdkKey: process.env.REACT_APP_CONFIG_CAT_API_KEY,
        },
        setFlags,
        () => {
          return { identifier: auth.user?.id };
        },
      );
    }
  }, [auth.initialized, auth.user?.id, setFlags]);

  return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};

export default inject<typeof FeatureFlagProvider>('rootStore')(observer(FeatureFlagProvider));
