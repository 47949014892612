import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { BoulderColors } from 'src/util/brand';

const useInformationRowStyles = makeStyles({
  informationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'flex-start',

    '& .value': {
      lineHeight: '100%',
    },

    '& .label': {
      // These labels should all be a fixed size, which is
      // the width of the widest label, plus the icon width
      // (16px) and the padding on the left and right of the
      // text (8px and 16px).
      flexBasis: 155 + 8 + 16 + 16,
      flexGrow: 0,
      flexShrink: 0,
      fontSize: 10,
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: 1.2,
      marginRight: 16,
      color: BoulderColors.Gray6,
      lineHeight: '100%',

      display: 'flex',
      alignItems: 'center',

      '& svg': {
        width: 18,
        height: 18,
        marginRight: 8,
      },
    },
  },
});

type InformationRowProps = {
  icon: React.ReactNode;
  label: string;
  value?: string | React.ReactNode;
};

export const InformationRow = ({ icon, label, value }: InformationRowProps) => {
  const classes = useInformationRowStyles();

  return (
    <div className={classes.informationRow}>
      <div className="label">
        {icon}
        {label}
      </div>
      <div className="value">{value ?? '--'}</div>
    </div>
  );
};
