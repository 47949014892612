import IconButton from '@material-ui/core/IconButton';
import WavingIcon from '@material-ui/icons/EmojiPeople';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import RouterLink from 'src/components/general/routerLink';
import { useAcceptableClinicRequest } from 'src/dropInClinic/hooks/useAcceptableClinicVisit';
import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles(() => ({
  iconContainer: {
    borderRadius: 100,
    width: 48,
    height: 48,
    '&.highlighted': {
      backgroundColor: Colors.Stillwater,
    },
  },
  icon: {
    color: 'white',
  },
}));

export const ClinicNotificationIcon = () => {
  const classes = useStyles();
  const acceptableRequest = useAcceptableClinicRequest();
  const isVisitAvailable = !!acceptableRequest;

  const iconContainerClasses = [classes.iconContainer];

  if (isVisitAvailable) {
    iconContainerClasses.push('highlighted');
  }

  return (
    <>
      <div className={iconContainerClasses.join(' ')}>
        <RouterLink routeName="dropInClinicRequests">
          <IconButton className={classes.icon}>
            <WavingIcon />
          </IconButton>
        </RouterLink>
      </div>
    </>
  );
};
