import gql from 'graphql-tag';

export const GET_NEXT_VISIT = gql`
  query GetNextVisitDropInClinic($patientIds: [ID!]!) {
    nextEventForEach(attendees: $patientIds) {
      id
      type
      subType
      start
    }
  }
`;
