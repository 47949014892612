import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormLabel,
  withStyles,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ExpandMore } from '@material-ui/icons';
import { format, isValid } from 'date-fns';
import { observer } from 'mobx-react';
import { useRouterStore } from 'mobx-state-router';
import React, { useState, useCallback, useContext } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { ActiveVisitRow } from 'src/dropInClinic/ActiveVisitRow';
import { RequestRow } from 'src/dropInClinic/RequestRow';
import { requestTypeMap } from 'src/dropInClinic/RequestType';
import { QueueEntry, useClinicRequestQueue } from 'src/dropInClinic/hooks/useClinicRequestQueue';
import { useInterval } from 'src/dropInClinic/hooks/useInterval';
import { FIX_ZOMBIE_REQUEST_MUTATION, REMOVE_MUTATION } from 'src/dropInClinic/queries';
import { useStyles } from 'src/dropInClinic/styles';
import {
  CancelMutationResponse,
  CancelMutationArgs,
  FixZombieMutationResponse,
  FixZombieMutationArgs,
} from 'src/dropInClinic/types';
import { Provider } from 'src/shared/stores/resource';
import { observedParamState } from 'src/stores/paramState';
import useRootStore from 'src/stores/useRootStore';
import { ProviderRole } from 'src/stores/users/userType';

// The rate at which the queue requests should be auto-refreshed
export const AUTO_REFRESH_TIME_MS = 30 * 1000;

export enum DropInClinicRequestStatus {
  SUBMITTED = 'SUBMITTED',
  WITHDRAWN = 'WITHDRAWN',
  PREPARING = 'PREPARING',
  PREPARED = 'PREPARED',
  ACCEPTED = 'ACCEPTED',
  MISSED = 'MISSED',
  REMOVED = 'REMOVED',
  COMPLETED = 'COMPLETED',
}

enum RequestFilter {
  ALL = 'all',
  ACCEPTABLE = 'acceptOnly',
  ASSIGNED = 'assignedOnly',
}

const Accordion = withStyles({
  root: {
    margin: '1rem 0',
    '&$expanded': {
      margin: '1rem 0',
    },
  },
  expanded: {},
})(MuiAccordion);

export const CLINICIAN_REQUEST_TYPES: string[] = ['FOLLOW_UP', 'INTAKE', 'REENGAGEMENT'];

const roleToRequestTypes = {
  [ProviderRole.Clinician]: CLINICIAN_REQUEST_TYPES,
  [ProviderRole.RegisteredNurse]: ['FOLLOW_UP', 'REENGAGEMENT'],
  [ProviderRole.CareAdvocate]: ['OFT'],
  [ProviderRole.PeerCoach]: ['PEER'],
};

function ClinicRequestQueue() {
  const classes = useStyles();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [requestToRemove, setRequestToRemove] = useState<string | null>(null);

  const tick = useCallback(() => {
    setCurrentTime(new Date());
  }, [setCurrentTime]);
  useInterval(tick, 60000);

  const { apolloClient } = useContext(ApolloClientContext);

  const router = useRouterStore();
  const rootStore = useRootStore();
  const providerId = rootStore.auth.user?.id;

  const { allEntries, activeVisits, error, mutate } = useClinicRequestQueue(providerId ?? null);
  const providerRole = (rootStore.auth.user as Provider)?.teamRole as ProviderRole | null;

  const [requestUpdateError, setRequestUpdateError] = useState(null);
  const { value: requestFilter, setValue: setRequestFilter } = observedParamState(
    router,
    'requestFilter',
    { defaultValue: RequestFilter.ACCEPTABLE, replaceHistory: true },
  );

  const removeRequest = useCallback(
    async (requestId: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        await apolloClient.mutate<CancelMutationResponse, CancelMutationArgs>({
          mutation: REMOVE_MUTATION,
          variables: { requestId },
        });
      } catch (e) {
        setRequestUpdateError(e);
      }
      mutate();
    },
    [apolloClient, setRequestUpdateError],
  );

  const fixRequest = useCallback(
    async (requestId: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        await apolloClient.mutate<FixZombieMutationResponse, FixZombieMutationArgs>({
          mutation: FIX_ZOMBIE_REQUEST_MUTATION,
          variables: { requestId },
        });
      } catch (e) {
        setRequestUpdateError(e);
      }
      mutate();
    },
    [apolloClient],
  );

  if (error) {
    return <ErrorAlert message="Error fetching request queue" error={error} />;
  }

  if (!allEntries) {
    return <div>Loading queue</div>;
  }

  let requestFilterFn;
  if (requestFilter === RequestFilter.ALL) {
    requestFilterFn = () => true;
  } else if (requestFilter === RequestFilter.ACCEPTABLE) {
    requestFilterFn = (entry: QueueEntry) => entry.acceptableForProvider.acceptable;
  } else {
    requestFilterFn = (entry: QueueEntry) =>
      entry.scheduleSuggestion.details?.provider.id === providerId;
  }

  const unknownTypes = Array.from(
    new Set(
      allEntries
        .filter(({ request }) => !requestTypeMap[request.requestType])
        .map(({ request }) => request.requestType),
    ),
  );

  function isEnabledFilterByRole(requestType: string): boolean {
    if (!providerRole) return true;
    if (!(providerRole in roleToRequestTypes)) return true;

    const requestTypes = roleToRequestTypes[providerRole];
    return requestTypes.includes(requestType);
  }

  // Keep track of whether the user has filtered on a request type
  // via url params
  const requestTypeState = Object.fromEntries(
    Object.keys(requestTypeMap).map(requestType => [
      requestType,
      observedParamState(router, requestType, {
        defaultValue: isEnabledFilterByRole(requestType),
        replaceHistory: true,
      }),
    ]),
  );
  unknownTypes.forEach(requestType => {
    requestTypeState[requestType] = observedParamState(router, requestType, {
      defaultValue: true,
      replaceHistory: true,
    });
  });

  const entries = allEntries
    .filter(requestFilterFn)
    .filter(entry => !!requestTypeState[entry.request.requestType]?.value);
  const activeVisitRows = activeVisits
    .filter(entry => !!requestTypeState[entry.requestType]?.value)
    .map(visit => (
      <ActiveVisitRow currentTime={currentTime} onFixRequest={fixRequest} visit={visit} />
    ));

  return (
    <div className={classes.root}>
      {requestUpdateError && (
        <ErrorAlert message="Error updating request" error={requestUpdateError} />
      )}
      <RadioGroup
        value={requestFilter}
        onChange={event => setRequestFilter(event.target.value as RequestFilter)}
      >
        <FormControlLabel
          data-testid="all-radio"
          value={RequestFilter.ALL}
          control={<Radio />}
          label="All requests in queue"
        />
        <FormControlLabel
          data-testid="acceptable-radio"
          value={RequestFilter.ACCEPTABLE}
          control={<Radio />}
          label="Requests I can accept"
        />
        <FormControlLabel
          data-testid="assigned-radio"
          value={RequestFilter.ASSIGNED}
          control={<Radio />}
          label="My assigned requests"
        />
      </RadioGroup>
      <FormLabel>Show request types:</FormLabel>
      <FormGroup row>
        {Object.entries(requestTypeState).map(([requestType, state]) => (
          <FormControlLabel
            key={requestType}
            label={requestTypeMap[requestType] || requestType}
            control={
              <Checkbox
                data-testid={`filter-${requestType}`}
                checked={state.value || false}
                onChange={event => state.setValue(event.target.checked)}
              />
            }
          />
        ))}
      </FormGroup>

      <div>
        {entries.length > 0 ? (
          entries.map(entry => {
            return (
              <RequestRow
                key={entry.request.id}
                additionalNotes={entry.patient?.fyi ?? undefined}
                entry={{
                  ...entry,
                  status: entry.request.status,
                  acceptable: entry.acceptableForProvider.acceptable,
                  insuranceIsAcceptable:
                    entry.acceptableForProvider.insuranceIsAcceptable ?? undefined,
                  requestId: entry.request.id,
                  requestType: entry.request.requestType,
                  stateIsAcceptable: entry.acceptableForProvider.stateIsAcceptable ?? undefined,
                  patient: entry.patient,
                  queuedAt: entry.request.requestTime,
                  reason: entry.request.reason,
                }}
                providerRole={providerRole}
                mutate={mutate}
                currentTime={currentTime}
                onRemoveRequest={setRequestToRemove}
                onUpdateError={setRequestUpdateError}
                suggestedProvider={entry.scheduleSuggestion.details?.provider}
                suggestedTime={
                  entry.scheduleSuggestion.details?.startTime &&
                  isValid(entry.scheduleSuggestion.details?.startTime)
                    ? format(entry.scheduleSuggestion.details.startTime, 'p')
                    : null
                }
                unscheduledReason={
                  entry.scheduleSuggestion.outcome !== 'SCHEDULED'
                    ? entry.scheduleSuggestion.outcome
                    : null
                }
              />
            );
          })
        ) : (
          <>
            <br />
            Queue is empty
          </>
        )}
      </div>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>Active Pop-In Visits</AccordionSummary>
        <AccordionDetails className={classes.visitList}>{activeVisitRows}</AccordionDetails>
      </Accordion>

      {requestToRemove && (
        <ConfirmDialog
          isDestructive
          onSubmit={() => {
            removeRequest(requestToRemove);
            setRequestToRemove(null);
          }}
          onCancel={() => setRequestToRemove(null)}
          submitLabel="Remove Patient"
          cancelLabel="Don't Remove"
        >
          Are you sure that you would like to remove this patient from the queue?
        </ConfirmDialog>
      )}
    </div>
  );
}

export default observer(ClinicRequestQueue);
