import gql from 'graphql-tag';

import { TEAM_ACTIVITY } from 'src/stores/fragments/activity';
import { USER_SUMMARY, USER_SUMMARY_LIMITED } from 'src/stores/fragments/users';

export const PATIENT_ALL = gql`
  fragment PatientAll on Patient {
    ...UserSummary
    preferredName
    dob
    enrollmentStatus {
      status
      notes
    }
    prescriberActivity {
      ...teamActivity
    }
    peerActivity {
      ...teamActivity
    }
    coordinatorActivity {
      ...teamActivity
    }
    clinicalCarePlan {
      plan
      currentRx
      notes
    }
    careAdvocateCarePlan {
      oftPlan
      mamPlan
      notes
    }
    peerCoachCarePlan {
      plan
      notes
    }
    fyi

    email
    phone
    consentsToVoicemails
    homeAddress {
      street1
      street2
      city
      state
      zip
    }
    mailingAddress {
      street1
      street2
      city
      state
      zip
    }

    prescriber {
      ...UserSummary
    }
    peer {
      ...UserSummary
    }
    coordinator {
      ...UserSummary
    }
    caseManager {
      ...UserSummary
    }

    preferenceSendHrqolSurveys
    treatmentAgreement {
      createdAt
      completedAt
      documentVersion
    }
  }
  ${USER_SUMMARY}
  ${TEAM_ACTIVITY}
`;

export const PATIENT_SUMMARY = gql`
  fragment PatientSummary on Patient {
    ...UserSummary
    preferredName
    homeAddress {
      state
    }
    mailingAddress {
      state
    }
    dob
  }
  ${USER_SUMMARY}
`;

export const PATIENT_LIMITED = gql`
  fragment PatientLimited on Patient {
    ...UserSummary
    preferredName
    dob
    phone
    fyi

    clinicalCarePlan {
      plan
      notes
    }
    careAdvocateCarePlan {
      oftPlan
      mamPlan
      notes
    }
    peerCoachCarePlan {
      plan
      notes
    }

    email
    consentsToVoicemails

    enrollmentStatus {
      status
      notes
    }
    prescriberActivity {
      ...teamActivity
    }
    peerActivity {
      ...teamActivity
    }
    coordinatorActivity {
      ...teamActivity
    }
    homeAddress {
      city
      state
      zip
    }

    prescriber {
      ...UserSummaryLimited
    }
    peer {
      ...UserSummaryLimited
    }
    coordinator {
      ...UserSummaryLimited
    }
    caseManager {
      ...UserSummaryLimited
    }
  }
  ${USER_SUMMARY}
  ${USER_SUMMARY_LIMITED}
  ${TEAM_ACTIVITY}
`;
