import React from 'react';

import { PhoneDialerEnabled, PhoneDialerDisabled } from 'src/components/featureflags/currentFlags';

const PhoneNumber = ({ number }) => {
  return !number ? null : (
    <>
      <PhoneDialerEnabled>
        <a href={`tel:${number}`}>
          {`(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`}
        </a>
      </PhoneDialerEnabled>
      <PhoneDialerDisabled>
        <span>{`(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`}</span>
      </PhoneDialerDisabled>
    </>
  );
};

export default PhoneNumber;
