import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import React, { Fragment } from 'react';

import { RequestType } from 'src/dropInClinic/RequestType';

const ELIGIBILITY_REQUEST_TYPE_LIST = ['FOLLOW_UP', 'INTAKE', 'REENGAGEMENT'] as const;

type EligibilitySummaryProps = {
  eligibilityResponse: {
    FOLLOW_UP: Record<string, number>;
    INTAKE: Record<string, number>;
  };
};

type SummarySectionProps = {
  requestType: (typeof ELIGIBILITY_REQUEST_TYPE_LIST)[number];
  addedToday: number;
  other: number;
};

const SummarySection: React.FC<SummarySectionProps> = ({ requestType, addedToday, other }) => {
  return (
    <Fragment key={requestType}>
      <ListSubheader id={`eligibility-${requestType}-header`}>
        <RequestType requestType={requestType} />
      </ListSubheader>
      <ListItem>
        <ListItemText>Added today</ListItemText>
        <ListItemSecondaryAction id={`eligibility-${requestType}-today`}>
          {addedToday}
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText>Total eligible</ListItemText>
        <ListItemSecondaryAction id={`eligibility-${requestType}-total`}>
          {other + addedToday}
        </ListItemSecondaryAction>
      </ListItem>
    </Fragment>
  );
};

export const EligibilitySummary: React.FC<EligibilitySummaryProps> = ({ eligibilityResponse }) => {
  return (
    <Paper>
      <List dense>
        {ELIGIBILITY_REQUEST_TYPE_LIST.map(requestType => {
          const effectiveEligibility = eligibilityResponse[requestType] ?? {};
          const addedToday = effectiveEligibility.today ?? 0;
          const other = effectiveEligibility.other ?? 0;

          return (
            <SummarySection
              key={requestType}
              requestType={requestType}
              addedToday={addedToday}
              other={other}
            />
          );
        })}
      </List>
    </Paper>
  );
};
