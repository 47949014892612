import * as React from 'react';

import * as CurrentFlags from 'src/components/featureflags/currentFlags';

type FlagName = (typeof CurrentFlags)[Exclude<
  keyof typeof CurrentFlags,
  'PhoneDialerEnabled' | 'PhoneDialerDisabled'
>];

export type FlagsContext = {
  [K in FlagName]?: boolean;
};

const FeatureFlagContext = React.createContext<FlagsContext>({});

export default FeatureFlagContext;
