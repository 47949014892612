import { makeStyles } from '@material-ui/styles';
import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import { format, parseISO } from 'date-fns';
import { connect } from 'formik';
import gql from 'graphql-tag';
import propEq from 'lodash/fp/propEq';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import React, { useState, useContext, useMemo } from 'react';
import type { Option } from 'react-select/lib/filters';

import {
  SHORTER_OFT_VISIT_SLOTS,
  ENABLE_DISCHARGE_EVENTS_UI,
} from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import AttendeeControl from 'src/components/forms/controls/attendee';
import Autocomplete from 'src/components/forms/controls/autocomplete';
import DateAndTimesControl from 'src/components/forms/controls/dateAndTimes';
import RecurrenceControl from 'src/components/forms/controls/recurrence';
import { DebouncedTextControl } from 'src/components/forms/controls/text';
import EditForm, { EditFormProps } from 'src/components/forms/editForm';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import { getDefaultDurationFromSubType } from 'src/components/forms/resources/defaultEventDuration';
import { CollapsibleFindATimeControl } from 'src/components/forms/resources/editEvent.CollapsibleFindATimeControl';
import {
  credentialError,
  endorsementSuccess,
  stateError,
} from 'src/components/forms/validators/EndorsementValidationResult';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { isSubType, getParentType } from 'src/events/visitTypes';
import type { FindATimeProps } from 'src/scheduling/components/findATime';
import TimeslotSelectionControl, {
  TimeSlotSelectionProps,
} from 'src/scheduling/components/timeSlotSelectionControl';
import { usePatientCredentialCriteria } from 'src/scheduling/hooks/usePatientCredentialCriteria';
import { EVENT_VALIDATOR, isPatientRequired } from 'src/shared/client/forms/validation/event';
import {
  CLINICIAN_EVENT_SUBTYPES,
  RN_EVENT_SUBTYPES,
  isAllDay,
  getEnabledSubTypes,
  MEDICAL_VISIT_SUBTYPES,
} from 'src/shared/util/events';
import { ProviderRole } from 'src/stores/users/userType';
import { parseUnknownDate } from 'src/util/parseUnknownDate';

const SCHEDULING_HELPER_SUBTYPES = [...CLINICIAN_EVENT_SUBTYPES, ...RN_EVENT_SUBTYPES];

const isPatient = propEq('__typename', 'Patient');
const isClinician = propEq('teamRole', ProviderRole.Clinician);
const isRn = propEq('teamRole', ProviderRole.RegisteredNurse);

const enabledEventSubTypes = getEnabledSubTypes();

const LICENSE_ERROR_MESSAGE = "Provider is not licensed in the patient's state";
const CREDENTIAL_ERROR_MESSAGE = "Provider is not credentialed with the patient's insurer";

export type EndorsementsQueryResult = {
  providerEndorsements: {
    licensedStates: [string];
    credentials: [string];
  };
};

export const GET_PROVIDER_ENDORSEMENTS = gql`
  query getProviderEndorsements($providerId: String!) {
    providerEndorsements(providerId: $providerId) {
      credentials
      licensedStates
    }
  }
`;

const TimeslotSelection = connect<
  Record<string, never>,
  Pick<TimeSlotSelectionProps, 'attendees' | 'subType' | 'timezone' | 'start' | 'duration'>
>(({ formik }) =>
  useMemo(
    () => (
      <TimeslotSelectionControl
        attendees={formik.values.attendees}
        duration={formik.values.duration}
        setFieldValue={formik.setFieldValue}
        start={formik.values.start}
        subType={formik.values.subType}
        timezone={formik.values.timezone}
      />
    ),
    [
      formik.values.attendees,
      formik.values.duration,
      formik.setFieldValue,
      formik.values.start,
      formik.values.subType,
      formik.values.timezone,
    ],
  ),
);

const FindATime = connect<
  Record<string, never>,
  Pick<FindATimeProps, 'attendees' | 'duration' | 'start'>
>(({ formik }) => (
  <CollapsibleFindATimeControl {...formik.values} setFieldValue={formik.setFieldValue} />
));

const EditEvent: React.FC<
  {
    fixedAttendee: any;
    item: any;
  } & Partial<EditFormProps>
> = ({ fixedAttendee, item, ...rest }) => {
  const flags = useContext(FeatureFlagContext);
  const isDischargeSummaryEventEnabled = !!flags[ENABLE_DISCHARGE_EVENTS_UI];

  const classes = useStyles();
  const { dropdown, dropdownSpacing, ...formClasses } = classes;

  const enabledFlattenedSubTypeOptions = useMemo(
    () =>
      Object.keys(enabledEventSubTypes)
        .map(subTypeValue => ({
          label: enabledEventSubTypes[subTypeValue].label,
          value: subTypeValue,
          data: {},
        }))
        .filter(option =>
          option.value === 'discharge_summary' ? isDischargeSummaryEventEnabled : true,
        ),
    [enabledEventSubTypes, isDischargeSummaryEventEnabled],
  );

  const initialMedicalProviders = item.attendees?.filter(
    (attendee: { teamRole: ProviderRole }) => isClinician(attendee) || isRn(attendee),
  );

  const [subType, setSubType] = useState<string | null>(null);
  const [patientId, setPatientId] = useState<string | null>(null);
  const [selectedMedicalProviders, setSelectedMedicalProviders] =
    useState<string[]>(initialMedicalProviders);
  const [selectedDate, setSelectedDate] = useState<Date>(item.start || new Date());
  const [hasCredentialingError, setHasCredentialingError] = useState<boolean>(false);
  const [hasStateError, setHasStateError] = useState<boolean>(false);
  const [isMedicalVisit, setIsMedicalVisit] = useState<boolean>(false);

  const { state, credentialId } = usePatientCredentialCriteria(patientId, selectedDate);
  const { apolloClient } = useContext(ApolloClientContext);

  useMemo(async () => {
    const endorsementErrors = await setEndorsementErrors(
      patientId,
      isMedicalVisit,
      selectedMedicalProviders,
      apolloClient as ApolloClient<NormalizedCacheObject>,
      state,
      credentialId,
    );

    if (endorsementErrors.stateError) {
      setHasStateError(true);
      setHasCredentialingError(false);
    } else if (endorsementErrors.credentialError) {
      setHasStateError(false);
      setHasCredentialingError(true);
    } else {
      setHasStateError(false);
      setHasCredentialingError(false);
    }
  }, [patientId, isMedicalVisit, selectedMedicalProviders, apolloClient, state, credentialId]);

  let warningMessage: string | null = null;

  if (hasStateError) {
    warningMessage = LICENSE_ERROR_MESSAGE;
  } else if (hasCredentialingError) {
    warningMessage = CREDENTIAL_ERROR_MESSAGE;
  }

  return (
    <EditForm
      {...rest}
      validationSchema={EVENT_VALIDATOR}
      classes={formClasses}
      item={item}
      warningMessage={warningMessage}
      savingDisabled={hasStateError}
    >
      <FormikEffect onChange={formikOnChange} />
      <div className={dropdownSpacing}>
        {!!enabledFlattenedSubTypeOptions.length && (
          <Field name="subType">
            {({ form: { errors, touched, setFieldValue } }) => (
              <Autocomplete
                className={dropdown}
                error={errors.subType && touched.subType}
                helperText={touched.subType && errors.subType}
                label="Visit Type *" // Only renames label, internally remains subType
                labelFn={option => option.label}
                name="SubType"
                onChange={(newValue: Option) => {
                  setFieldValue('subType', newValue?.value);
                }}
                options={enabledFlattenedSubTypeOptions}
                valueFn={option => option.value}
              />
            )}
          </Field>
        )}
      </div>
      <Field name="title" component={DebouncedTextControl} label="Title" />
      <Field
        name="attendees"
        editEventDropdownStyling={dropdown}
        className={dropdownSpacing}
        component={AttendeeControl}
        fixed={fixedAttendee && [getSnapshot(fixedAttendee)]}
        patientRequired={isSubType(subType) && isPatientRequired(subType)}
      />
      <Field
        startName="start"
        durationName="duration"
        timezoneName="timezone"
        typeName="type"
        component={DateAndTimesControl}
        label="Date *"
        allDay={event => isAllDay(event.type)}
      />

      {isSubType(subType) && SCHEDULING_HELPER_SUBTYPES.includes(subType) && <TimeslotSelection />}

      <FindATime />

      <Field>
        {({ form: { values, errors } }) => (
          <RecurrenceControl
            label="Repeats"
            recurrence={values.recurrence}
            isValid={!errors.recurrence}
            start={values.start}
            duration={values.duration}
            timezone={values.timezone}
            allDay={isAllDay(values.type)}
          />
        )}
      </Field>
    </EditForm>
  );

  function formikOnChange({ values, setFieldValue }, prev) {
    // Update subType state to determine if patient field displays as being required
    if (values.subType !== prev.values.subType) {
      setSubType(values.subType);

      // Since there is no longer a Type dropdown, set the correct type for the event here.
      const eventType = getParentType(values.subType);
      setFieldValue('type', eventType);

      // Update the duration
      const shorterOftSlots = flags[SHORTER_OFT_VISIT_SLOTS];

      const defaultDuration = getDefaultDurationFromSubType(values.subType, shorterOftSlots);

      setFieldValue('duration', defaultDuration || 30);

      if (MEDICAL_VISIT_SUBTYPES.includes(values.subType)) {
        setIsMedicalVisit(true);
      }
    }

    if (values.type !== prev.values.type) {
      // If the type changed, we may have gone from a time-based event to an all-day event,
      // in which case we need to set the time to 0 since all day events require a time of UTC 0:00.
      if (values.start && values.timezone && isAllDay(values.type) && !isAllDay(prev.values.type)) {
        const start = parseUnknownDate(values.start);
        setFieldValue('start', parseISO(`${format(start, 'yyyy-MM-dd')}T00:00:00.000Z`));
      }

      // Or... we may have gone from all-day to not-all-day, in which case we need to
      // trigger an update to the timezone
      if (isAllDay(prev.values.type) && !isAllDay(values.type)) {
        const patient = (values.attendees as any[]).find(isPatient);
        const nextTimezone = patient?.timezone ?? values.timezone;

        setFieldValue('timezone', nextTimezone);
      }
    }

    if (values.attendees !== prev.values.attendees) {
      const medicalProviders = (values.attendees as any[])
        .filter(attendee => isClinician(attendee) || isRn(attendee))
        .map(medicalProvider => medicalProvider.id);
      setSelectedMedicalProviders(medicalProviders);
      setPatientId((values.attendees as any[]).find(isPatient)?.id);
    }

    if (values.start !== prev.values.start) {
      setSelectedDate(values.start);
    }

    if (values.attendees !== prev.values.attendees && !isAllDay(values.type)) {
      const patient = (values.attendees as any[]).find(isPatient);
      const previousPatient = (prev.values.attendees as any[]).find(isPatient);

      // Set the timezone to the patient's timezone
      if (patient?.timezone !== previousPatient?.timezone) {
        let nextTimezone;
        // If the patient was cleared and the timezone wasn't changed
        // from the patient's timezone, set it to the first provider's
        // timezone
        if (!patient && values.timezone === previousPatient?.timezone) {
          nextTimezone = values.attendees[0]?.timezone;
        } else {
          nextTimezone = patient?.timezone;
        }
        setFieldValue('timezone', nextTimezone ?? values.timezone);
      }
    }
  }
};

export const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',

    // Keep the buttons visible at the bottom of the modal
    position: 'sticky',
    backgroundColor: 'white',
    bottom: -8, // Accounting for 8px somewhere for some reason
    padding: '20px 0px',
    right: 0,
    zIndex: 10, // Sit on top of 9 index calendar column
  },
  dropdown: {
    width: '48%',
  },
  dropdownSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default observer(EditEvent);

function setEndorsementErrors(
  patientId: string | null,
  isMedicalVisit: boolean,
  selectedMedicalProviders: string[],
  apolloClient: ApolloClient<NormalizedCacheObject>,
  patientState: string | null,
  credentialId: string | null,
) {
  if (!(patientId && isMedicalVisit && selectedMedicalProviders.length > 0)) {
    return endorsementSuccess();
  }

  if (!patientState) {
    return stateError();
  }
  if (!credentialId) {
    return credentialError();
  }

  const endorsementPromises = selectedMedicalProviders.map(providerId => {
    return apolloClient?.query<EndorsementsQueryResult>({
      query: GET_PROVIDER_ENDORSEMENTS,
      variables: { providerId },
    });
  });

  return Promise.all(endorsementPromises).then(ret => {
    const credentialList = ret.flatMap(response => response?.data.providerEndorsements.credentials);
    const stateList = ret.flatMap(response => response?.data.providerEndorsements.licensedStates);

    if (!patientState || !stateList.includes(patientState)) {
      return stateError();
    } else if (!credentialList.includes(credentialId)) {
      return credentialError();
    }

    return endorsementSuccess();
  });
}
