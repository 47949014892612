import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import type { Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Home } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChatIcon from '@material-ui/icons/ChatBubble';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import AdminSidebar from 'src/components/app/adminSidebar';
import Body from 'src/components/app/body';
import {
  CLINIC_REQUEST_NOTIFICATION_ICON,
  MY_DAY_TODAY,
  DISABLE_PEBBLE_CREATION_SHORTCUT,
} from 'src/components/featureflags/currentFlags';
import FeatureFlag, { FeatureFlagNotTargeted } from 'src/components/featureflags/featureFlag';
import UserSelectItem from 'src/components/forms/UserSelectItem';
import Autocomplete from 'src/components/forms/controls/autocomplete';
import EventCreateIcon from 'src/components/general/eventCreateIcon';
import PebbleCreateIcon from 'src/components/general/pebbleCreateIcon';
import RouterLink from 'src/components/general/routerLink';
import { ClinicNotificationIcon } from 'src/dropInClinic/components/ClinicNotificationIcon';
import Colors from 'src/nightingale/Colors';
import boulderLogo from 'src/nightingale/assets/boulder-logo-white-140x31.png';
import { DoseSpotNotificationsIcon } from 'src/prescribing/doseSpot/components/DoseSpotNotificationsIcon';
import type { Provider } from 'src/shared/stores/resource';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';
import { patientsQueryParamsForUser, myPebblesQueryParams } from 'src/util/nav';

const isEnvQA = process.env.REACT_APP_ENVIRONMENT === 'qa';

const Nav: React.FC<{
  rootStore: RootStore;
}> = ({ rootStore }) => {
  const {
    auth,
    chat,
    flash,
    generateRouteUrl,
    pebbles: {
      domain: { myPebbleBadgeCount },
    },
    routerStore,
    searchUsers,
  } = rootStore;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const user = auth.user as Provider;

  const classes = useStyles();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    auth.logout();
    handleCloseMenu();
    routerStore.goTo('login');
    flash('You are now logged out.');
  };

  const handleProfile = () => {
    routerStore.goTo('editProvider', {
      params: {
        id: user.id,
      },
    });
    handleCloseMenu();
  };

  const handleGoToUser = targetUser => {
    routerStore.goTo(targetUser.__typename === 'Patient' ? 'showPatient' : 'showProvider', {
      params: { id: targetUser.id },
    });
  };

  const handleMyPebbles = () => {
    const generatedUrl = generateRouteUrl('pebbles', {}, myPebblesQueryParams(user));
    window.open(generatedUrl, '_blank');
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            className={classNames(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <FeatureFlag name={MY_DAY_TODAY}>
            <RouterLink
              className={classes.logoLink}
              routeName="myDayToday"
              params={{ id: rootStore.auth.user?.id ?? '' }}
            >
              <img
                src={boulderLogo}
                className={classes.logoGfx}
                data-testid="boulder-logo-link-mdt"
              />
              {isEnvQA ? <span className={classes.logoEnv}>QA</span> : null}
            </RouterLink>
          </FeatureFlag>
          <FeatureFlagNotTargeted name={MY_DAY_TODAY}>
            <RouterLink
              className={classes.logoLink}
              routeName="patients"
              queryParams={patientsQueryParamsForUser(user)}
            >
              <img
                src={boulderLogo}
                className={classes.logoGfx}
                data-testid="boulder-logo-link-patients"
              />
              {isEnvQA ? <span className={classes.logoEnv}>QA</span> : null}
            </RouterLink>
          </FeatureFlagNotTargeted>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <Autocomplete
              placeholder="Search People..."
              value={null}
              onChange={handleGoToUser}
              disableUnderline
              loadOptions={searchUsers}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
                placeholder: classes.inputPlaceholder,
                singleValue: classes.inputValue,
                valueContainer: classes.inputValue,
              }}
              formatOptionLabel={person => <UserSelectItem person={person} />}
              getOptionLabel={person => {
                const isProvider = person.__typename === 'Provider';
                if (isProvider) {
                  return `${person.firstName} ${person.lastName}`;
                } else {
                  return `${person.firstName} ${person.preferredName} ${person.lastName}`;
                }
              }}
              getOptionValue={option => option.id}
              async
              withoutIndicators
            />
          </div>

          <div className={classes.grow} />

          <FeatureFlag name={MY_DAY_TODAY}>
            <RouterLink routeName="myDayToday" params={{ id: rootStore.auth.user?.id ?? '' }}>
              <IconButton style={{ color: 'white' }}>
                <Home />
              </IconButton>
            </RouterLink>
          </FeatureFlag>

          {routerStore.getCurrentRoute()?.name !== 'dropInClinicRequests' && (
            <FeatureFlag name={CLINIC_REQUEST_NOTIFICATION_ICON}>
              <ClinicNotificationIcon />
            </FeatureFlag>
          )}

          <DoseSpotNotificationsIcon />
          <EventCreateIcon />
          <FeatureFlag name={DISABLE_PEBBLE_CREATION_SHORTCUT} notTargeted>
            <PebbleCreateIcon />
          </FeatureFlag>
          <IconButton style={{ color: 'white' }} onClick={handleMyPebbles}>
            <Badge badgeContent={myPebbleBadgeCount} classes={{ badge: classes.badgeColor }}>
              <ListIcon />
            </Badge>
          </IconButton>
          <RouterLink routeName="chat">
            <IconButton>
              <Badge
                badgeContent={chat.numWithUnread}
                classes={{
                  badge: classes.badgeColor,
                }}
                invisible={user.unreadMessageNotifications === false || chat.numWithUnread === 0}
              >
                <figure className={classes.chatButton}>
                  <ChatIcon className={classes.chatButtonIcon} />
                  {user.unreadMessageNotifications === false && (
                    <div className={classes.chatButtonSlash} />
                  )}
                </figure>
              </Badge>
            </IconButton>
          </RouterLink>
          <IconButton onClick={handleMenu} color="inherit" data-testid="account-circle">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleProfile} data-testid="profile-menu-item">
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        open={open}
        variant="temporary"
        onClose={handleDrawerToggle}
        anchor="left"
        classes={{ paper: classes.drawer }}
      >
        <div onClick={handleDrawerToggle}>
          {/* @ts-expect-error typescript wants us to pass rootStore but it's wrong */}
          <AdminSidebar />
        </div>
      </Drawer>

      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Body />
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  logoLink: {
    color: Colors.White,
    display: 'block',
    paddingRight: 20,
  },
  logoGfx: {
    width: 70,
    position: 'relative',
    top: 1, // optical vertical align
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoEnv: {
    fontFamily: 'Tenor Sans, sans-serif',
    fontSize: '1.1em',
    fontWeight: 'bold',
    paddingLeft: 4,
    textTransform: 'uppercase',
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    minWidth: 710,
    position: 'relative',
    zIndex: 1,
  },
  chatButton: {
    position: 'relative',
    margin: 0,
    padding: 0,
    height: 24,
  },
  chatButtonIcon: {
    color: 'white',
  },
  chatButtonSlash: {
    width: 28,
    height: 0,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderTop: '2px solid #fff',
    position: 'absolute',
    top: 8,
    left: -3,
    transform: 'rotate(225deg)',
  },
  badgeColor: {
    color: 'white',
    backgroundColor: Colors.Coral,
  },
  chatBadgeColor: {
    color: 'white',
    backgroundColor: Colors.Coral,
  },
  appBar: {
    backgroundColor: isEnvQA ? Colors.Taupe : undefined,
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0, // So the Typography noWrap works
    minHeight: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: 220,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  iconButton: {
    paddingRight: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    flexBasis: 555,
    minWidth: 250,
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    width: 500,
  },
  inputValue: {
    color: 'white', // TODO should be inherit but doesn't work
  },
  inputPlaceholder: {
    paddingLeft: theme.spacing(6),
    color: 'inherit',
  },
  title: {
    paddingRight: 65,
    cursor: 'pointer',
    color: 'white',
  },
  drawer: {
    paddingTop: 20,
    width: 'auto',
    maxWidth: 300,
    flexShrink: 0,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  '@media print': {
    appBar: {
      display: 'none',
    },
    toolbar: {
      display: 'none',
    },
  },
}));

export default inject<typeof Nav>('rootStore')(observer(Nav));
