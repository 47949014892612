const DUPLICATE_PHONE_OR_EMAIL_MESSAGE =
  'This Phone/Email belongs to another patient record. Please search this phone/email in the search bar (without spaces) to see which other patient record it belongs to.';
const NON_NULLABLE_VALUE_MESSAGE = 'The First Name/Last Name cannot be cleared.';

export function getMessageForError(err: Error | null): string | undefined {
  if (!err) {
    return undefined;
  }

  if (isDuplicatePhoneOrEmailError(err)) {
    return DUPLICATE_PHONE_OR_EMAIL_MESSAGE;
  }

  if (isNonNullableValueError(err)) {
    return NON_NULLABLE_VALUE_MESSAGE;
  }

  return undefined;
}

function isDuplicatePhoneOrEmailError(err: Error) {
  return err.message?.includes('Unique property');
}

function isNonNullableValueError(err: Error) {
  return err.message?.includes('cannot be cleared');
}
