import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import upperFirst from 'lodash/upperFirst';
import { useContext } from 'react';
import useSWR from 'swr';
import type { SWRResponse } from 'swr';

import { INCLUDE_PEBBLES_IN_PATIENT_ACTIVITY } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { ActivityDataPebble, ActivityType } from 'src/components/pages/patientActivity/types';
import { ACTIVITY_PEBBLES_QUERY } from 'src/components/pages/patientActivity/useSWRActivityPebbles.gql';
import { PEBBLE_TOPIC_MAP, PebbleStatus, PebbleTopic } from 'src/util/pebbles';

function useSWRActivityPebbles(patientId: string): {
  data?: ActivityDataPebble[];
  error?: SWRResponse['error'];
} {
  const flags = useContext(FeatureFlagContext);

  // make sure the hook runs unconditionally, to avoid errors
  const swrKey = flags[INCLUDE_PEBBLES_IN_PATIENT_ACTIVITY]
    ? [ACTIVITY_PEBBLES_QUERY, { where: { participant_some: patientId } }]
    : null;
  const { data: pebblesData, error } = useSWR(swrKey, { revalidateOnFocus: false });

  if (!flags[INCLUDE_PEBBLES_IN_PATIENT_ACTIVITY]) {
    // done, but there are no results to display
    return { data: [] };
  }

  const data = pebblesData ? preparePebbles(pebblesData.pebbles) : undefined;

  return { data, error };
}

const preparePebbles = (pebbles: PebbleGraphQl[]): ActivityDataPebble[] => {
  return pebbles.map(pebble => {
    const status =
      pebble.status !== PebbleStatus.Completed && pebble.status !== PebbleStatus.WontDo
        ? 'scheduled'
        : 'complete';
    const date = pebble.updatedAt ?? pebble.createdAt;
    return {
      pebbleId: pebble.id,
      name: pebble.topic,
      label: `Pebble: ${PEBBLE_TOPIC_MAP[pebble.topic]}`,
      type: ActivityType.Pebble,
      start: formatInTimeZone(
        date,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'MMM d, yyyy h:mma zzz',
      ),
      attendees: [],
      eventNotes: getSummaryText(pebble, status),
      status,
      eventStatusInfo: {
        status,
        start: date.toString(),
        allDay: false,
        duration: 0,
      },
      sortDateEpoch: parseISO(date.toString()).getTime(),
    };
  });
};

const getSummaryText = (pebble: PebbleGraphQl, statusText: string): string => {
  const { title, updatedAt } = pebble;

  const summary: string[] = [upperFirst(statusText)];
  if (title) {
    summary.push(title);
  }
  if (updatedAt) {
    summary.push(`Last updated ${format(parseISO(updatedAt), 'MMM d, p')}`);
  }

  return summary.join('; ');
};

type PebbleGraphQl = {
  createdAt: string;
  id: string;
  status: PebbleStatus;
  title: string | null;
  topic: PebbleTopic;
  updatedAt: string | null;
};

export default useSWRActivityPebbles;
