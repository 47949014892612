import { compareAsc } from 'date-fns';
import { format, isValid } from 'date-fns/fp';
import MaterialTable from 'material-table';
import React from 'react';

import { PatientDetails } from 'src/dropInClinic/PatientDetails';
import { RequestType } from 'src/dropInClinic/RequestType';
import { EligibilityEntry, VisitInfo } from 'src/dropInClinic/eligibility/types';
import { labelForEventType } from 'src/shared/util/events';

type EnhancedEligibilityEntry<T> = EligibilityEntry<T> & {
  requestType: string;
  nextVisit?: VisitInfo<T> | null;
};

type EligibilityTableProps = {
  eligibilityList: Array<EnhancedEligibilityEntry<Date>>;
};

const formatDate = (date: Date) => (isValid(date) ? format('P', date) : '');

export const EligibilityTable: React.FC<EligibilityTableProps> = ({ eligibilityList }) => {
  return (
    <MaterialTable
      options={{
        header: true,
        paging: false,
        showTitle: false,
        sorting: true,
      }}
      columns={[
        {
          title: 'Patient',
          field: 'patient.firstName',
          width: '40%',
          render: rowData => <PatientDetails patient={rowData.patient} />,
        },
        {
          title: 'Eligibility Type',
          field: 'requestType',
          render: rowData => <RequestType requestType={rowData.requestType} />,
        },
        {
          title: 'Made Eligible',
          field: 'eligibility.lastSet',
          render: rowData => formatDate(rowData.eligibility.lastSet),
        },
        {
          title: 'Eligibility Expiration',
          field: 'eligibility.expires',
          render: rowData => formatDate(rowData.eligibility.expires),
        },
        {
          title: 'Medication Run-out Date',
          field: 'patient.medicationRunOutDate',
          render: rowData => formatDate(rowData.patient.medicationRunOutDate),
          customSort: (rowData1, rowData2) => {
            const date1 = rowData1?.patient?.medicationRunOutDate;
            const date2 = rowData2?.patient?.medicationRunOutDate;

            if (!date1 && !date2) return 0;
            if (!date1) return 1;
            if (!date2) return -1;
            return compareAsc(date1, date2);
          },
        },
        {
          title: 'Next Visit',
          field: 'nextVisit',
          render: rowData => {
            const { nextVisit } = rowData;
            if (!nextVisit) {
              return null;
            }
            return (
              <>
                <div>{labelForEventType(nextVisit.type, nextVisit.subType)}</div>
                <div>{formatDate(nextVisit.start)}</div>
              </>
            );
          },
        },
      ]}
      data={eligibilityList}
    />
  );
};
