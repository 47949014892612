import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { MY_DAY_TODAY } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { inject } from 'src/util/inject';

/**
 * Determines where to redirect the user when they come to the root page of the staff app. If the
 * `myDayToday` feature flag is on, they'll go there, otherwise they'll go to the legacy view,
 * which is the provider calendar view.
 *
 * This is temporary until the new My Day Today feature is baked in and we get rid of the feature
 * flag, at which time this component can go away.
 */
const InterstitialLandingPage: React.FC<{ rootStore }> = ({ rootStore }) => {
  const flags = useContext(FeatureFlagContext);
  const isMyDayTodayOn = flags[MY_DAY_TODAY];
  if (isMyDayTodayOn === undefined) {
    return null;
  }

  const providerId = rootStore.auth.user?.id;
  if (isMyDayTodayOn) {
    rootStore.routerStore.goTo('myDayToday', {
      params: { id: providerId },
    });
  } else {
    rootStore.routerStore.goTo('showProvider', {
      params: { id: providerId },
    });
  }
  return null;
};

export default inject('rootStore')(observer(InterstitialLandingPage));
