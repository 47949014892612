/**
 * Replacement for MaterialUi IconButton that just shows the icon instead of the icon in a big
 * circle -- useful for spots where you want a row of small icon buttons and the MaterialUi ones
 * are too big.
 */
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

class MiniIconButton extends React.Component {
  handleClick = evt => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(evt);
    }
  };

  render() {
    const { children, disabled, color = 'primary', classes } = this.props;

    const colorClass = disabled ? classes.disabled : classes[color];

    return (
      <div onClick={this.handleClick} className={classNames([classes.button, colorClass])}>
        {children}
      </div>
    );
  }
}

const styles = theme => ({
  button: {
    transition: theme.transitions.create('color'),
    cursor: 'pointer',
  },
  primary: {
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
});

export default withStyles(styles)(MiniIconButton);
