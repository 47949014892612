import zipObject from 'lodash/zipObject';

const queueTypeSuffixes = [
  'CaseManagerVisitLimit',
  'FollowUpLimit',
  'IntakeLimit',
  'OftLimit',
  'PeerVisitLimit',
  'ReengagementLimit',
] as const;

/** The settings to show in each column of the Global section */
export const globalSettingNames = queueTypeSuffixes.map(suffix => `global${suffix}`);

/** The states we can control queues for */
export const stateCodePrefixes = ['AK', 'CO', 'NC', 'OH', 'OR', 'WA'] as const;

/** The kinds of state-specific queues we have */
const stateQueueTypeSuffixes = ['FollowUpLimit', 'IntakeLimit', 'ReengagementLimit'] as const;

/** The names of the various state-specific queue settings, organized by state */
export const stateSettingNames = zipObject(
  stateCodePrefixes,
  stateCodePrefixes.map(state => stateQueueTypeSuffixes.map(suffix => `${state}${suffix}`)),
);
