import { Toolbar, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import MoreVert from '@material-ui/icons/MoreVert';
import VideoIcon from '@material-ui/icons/Videocam';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import upperFirst from 'lodash/upperFirst';
import React, { SyntheticEvent, useContext, useState } from 'react';

import CancelEventForm from 'src/components/forms/resources/cancelEvent';
import CompleteEventForm from 'src/components/forms/resources/completeEvent';
import RescheduleEventForm from 'src/components/forms/resources/rescheduleEvent';
import VideoFeedbackForm from 'src/components/forms/resources/videoFeedbackForm';
import Tooltip from 'src/components/general/Tooltip';
import RouterLink, { RouterLinkDiv } from 'src/components/general/routerLink';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import EventStatusActionsPopup from 'src/components/pages/pageElements/eventStatusActionsPopup';
import RecurrenceActionsPopup from 'src/components/pages/pageElements/recurrenceActionsPopup';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { UpdateSummary } from 'src/events/UpdateSummary';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';
import {
  scheduleSummary,
  isAllDay,
  EVENT_STATUSES,
  SCHEDULE_CHANGE_REASONS,
  UNSCHEDULED_EVENT_STATUSES,
  labelForEventType,
} from 'src/shared/util/events';
import rruleToString from 'src/shared/util/rruleToString';
import { EventInstance } from 'src/stores/models/event';
import { UserDisplayType } from 'src/stores/models/userDisplay';
import { VALIDATE_REQUIRED_VISIT_PROPERTIES } from 'src/stores/mutations/events';
import { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const EventHeader: React.FC<{
  additionalProps?: {
    appointmentNotes?: string;
    scheduleChangeNotes?: string;
    scheduleChangeReason?: keyof typeof SCHEDULE_CHANGE_REASONS;
    signedAt?: string;
    signedByDisplay?: UserDisplayType;
    countersignedAt?: string;
    countersignedByDisplay?: UserDisplayType;
    updatedAt?: string;
    updatedByDisplay?: UserDisplayType;
  };
  event: EventInstance;
  hideRecurrenceDetails?: boolean;
  rootStore: RootStore;
  showActionButtons?: boolean;
}> = ({
  additionalProps,
  event,
  hideRecurrenceDetails,
  rootStore: { events: eventsStore, auth: authStore },
  showActionButtons,
}) => {
  const [eventStatusMenuParent, setEventStatusMenuParent] = useState<HTMLButtonElement | null>(
    null,
  );
  const [recurrenceMenuParent, setRecurrenceMenuParent] = useState<HTMLButtonElement | null>(null);
  const [action, setAction] = useState<Action | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [missingRequiredFields, setMissingRequiredFields] = useState<string[]>([]);

  const { apolloClient } = useContext(ApolloClientContext);

  const classes = useStyles();

  const eventActions = {
    feedback: {
      title: 'Send Video Call Feedback',
      formComponent: props => <VideoFeedbackForm {...props} />,
      onSubmit: async values => {
        await onSubmitFeedback(values.message);
      },
    },

    cancel: {
      title: 'Cancel Event',
      formComponent: props => <CancelEventForm {...props} />,
      onSubmit: async values => {
        await eventsStore.updateEventAndSave(event, {
          ...event,
          status: EVENT_STATUSES.CANCELED,
          scheduleChangeNotes: values.scheduleChangeNotes,
          scheduleChangeReason: values.scheduleChangeReason,
        });
      },
    },

    reschedule: {
      title: 'Reschedule Event',
      formComponent: props => (
        <RescheduleEventForm
          {...props}
          disableDatepicker={props.item.status === EVENT_STATUSES.RESCHEDULED}
        />
      ),
      onSubmit: async values => {
        if (event.status === EVENT_STATUSES.RESCHEDULED) {
          await eventsStore.rescheduleEvent(event, {
            scheduleChangeReason: values.scheduleChangeReason,
            scheduleChangeNotes: values.scheduleChangeNotes,
          });
        } else {
          await eventsStore.rescheduleEvent(event, {
            scheduleChangeReason: values.scheduleChangeReason,
            start: values.start,
            duration: values.duration,
            timezone: values.timezone,
            scheduleChangeNotes: values.scheduleChangeNotes,
          });
        }
      },
    },

    signAndComplete: {
      title: 'Sign & Complete Event',
      formComponent: props => (
        <CompleteEventForm
          saveButtonText="Sign & Save"
          missingRequiredFields={missingRequiredFields}
          {...props}
        />
      ),
      onSubmit: async values => {
        await eventsStore.signAndCompleteEvent(event, values.appointmentNotes);
      },
    },

    cancelRecurrence: {
      title: 'Cancel Recurrence',
      useConfirmDialog: true,
      modalContents:
        'Are you sure that you would like to cancel this and all future instances of this event?',
      submitLabel: 'Cancel Recurrence',
      cancelLabel: `Don't Cancel`,
      isDestructive: true,
      onSubmit: async () => {
        await eventsStore.cancelRecurrence(event);
      },
    },
  };

  type Action = keyof typeof eventActions;

  const handleOpenVc = async () => {
    await eventsStore.openVideoConverence(event);
  };

  const showEventStatusMenu = (e: SyntheticEvent<HTMLButtonElement>) => {
    setEventStatusMenuParent(e.currentTarget);
  };

  const showRecurrenceActionMenu = (e: SyntheticEvent<HTMLButtonElement>) => {
    setRecurrenceMenuParent(e.currentTarget);
  };

  const onCloseMenu = () => {
    setEventStatusMenuParent(null);
    setRecurrenceMenuParent(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onSubmitFeedback = async (message: string) => {
    const sessionId = await eventsStore.getSessionId(event);
    const { patientAttendee, providerAttendees } = event;
    const patientId = patientAttendee?.id || '[Patient ID missing]';
    const providerIds = providerAttendees
      .map(provider => provider.id)
      .filter((id): id is string => !!id);

    await eventsStore.sendVideoFeedback({
      sessionId,
      patientId,
      providerIds,
      message,
    });
  };

  const openActionModal = (selectedAction: Action) => {
    setAction(selectedAction);
    setEventStatusMenuParent(null);
    setRecurrenceMenuParent(null);
    setShowModal(true);
  };

  const onValidateSignAndComplete = (errors: string[]) => {
    setAction('signAndComplete');
    setEventStatusMenuParent(null);
    setRecurrenceMenuParent(null);
    setMissingRequiredFields(errors);
    setShowModal(true);
  };

  // Unlike the other actions that are triggered through the eventStatusActionsPopup
  // component, "Un-completing" a completed event does not require a
  // confirmation modal. We just make the change to status immediately.
  // Note that we intentionally do not blow away the scheduleChangeReason or
  // scheduleChangeNotes so they may persist if the event is re-canceled later.
  const uncompleteEvent = async () => {
    await eventsStore.unsignAndUncompleteEvent(event);

    onCloseMenu();
  };

  const countersignEvent = async () => {
    await eventsStore.countersignEvent(event);

    onCloseMenu();
  };

  const unCountersignEvent = async () => {
    await eventsStore.uncountersignEvent(event);
    onCloseMenu();
  };

  const validateSignAndComplete = async () => {
    if (!apolloClient) {
      return;
    }

    const {
      data: { validateRequiredVisitProperties = [] },
    } = await apolloClient.query({
      query: VALIDATE_REQUIRED_VISIT_PROPERTIES,
      variables: {
        id: event.id,
      },
    });

    onValidateSignAndComplete(validateRequiredVisitProperties);
  };

  const patient = event.patientAttendee;

  let currentAction;
  let CurrentForm;
  if (action) {
    currentAction = eventActions[action];
    CurrentForm = currentAction.formComponent;
  }

  let eventNotes: string | undefined | null = null;
  let changeReason: keyof typeof SCHEDULE_CHANGE_REASONS | undefined | null = null;
  if (event.status === EVENT_STATUSES.COMPLETED) {
    eventNotes = event.appointmentNotes || additionalProps?.appointmentNotes;
  } else if (UNSCHEDULED_EVENT_STATUSES.includes(event.status as string)) {
    eventNotes = event.scheduleChangeNotes || additionalProps?.scheduleChangeNotes;
    changeReason = event.scheduleChangeReason || additionalProps?.scheduleChangeReason;
  }

  const signedBy = event.signedBy ?? additionalProps?.signedByDisplay;
  const signedAt =
    event.signedAt ?? (additionalProps?.signedAt ? parseISO(additionalProps.signedAt) : null);

  const countersignedBy = event.countersignedBy ?? additionalProps?.countersignedByDisplay;
  const countersignedAt =
    event.countersignedAt ??
    (additionalProps?.countersignedAt ? parseISO(additionalProps.countersignedAt) : null);

  const updatedBy = event.updatedByDisplay || additionalProps?.updatedByDisplay;
  const updatedAt =
    event.updatedAt ?? (additionalProps?.updatedAt ? parseISO(additionalProps.updatedAt) : null);
  return (
    <div className={classes.headerText}>
      <Toolbar disableGutters className={classes.toolbar}>
        <PageTitle variant="h5" className={classes.title}>
          {patient && <span>{patient.preferredFullName} - </span>}
          {labelForEventType(event.type, event.subType)}
        </PageTitle>
        {showActionButtons && (
          <Tooltip title="Edit Visit Note">
            <RouterLinkDiv routeName="showEvent" params={{ id: event.id }}>
              <IconButton className={classes.clickableIcon}>
                <EditIcon />
              </IconButton>
            </RouterLinkDiv>
          </Tooltip>
        )}
        {!showActionButtons && event.type === 'appointment_virtual' && (
          <Tooltip title="Open Video Conference">
            <IconButton onClick={() => handleOpenVc()} className={classes.clickableIcon}>
              <VideoIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <Toolbar disableGutters>
        <div className={classes.eventDetails}>
          <span className={classes.status} data-testid="event-header-status">
            <UpdateSummary label="Signed & Completed" by={signedBy} at={signedAt} />
            {!signedBy && upperFirst(event.status ?? undefined)}
            {changeReason && ` (${SCHEDULE_CHANGE_REASONS[changeReason].label})`}
          </span>
          <UpdateSummary
            className={classes.italic}
            label="Countersigned"
            by={countersignedBy}
            at={countersignedAt}
          />
          <span className={(event.status && classes[event.status]) || undefined}>
            {scheduleSummary(event)}
          </span>
          <UpdateSummary label="Last updated" by={updatedBy} at={updatedAt} />
          {event.rescheduledTo && (
            <span className={classes.rescheduledToLink}>
              Now:{' '}
              <RouterLink
                routeName="showEvent"
                params={{ id: event.rescheduledTo.id }}
                data-testid="event-header-rescheduled-link"
              >
                {scheduleSummary(event.rescheduledTo)}
              </RouterLink>
            </span>
          )}
          {eventNotes && <span className={classes.eventNotes}>{eventNotes}</span>}
        </div>
        {!showActionButtons && (
          <Tooltip title="Event Status Options">
            <IconButton
              onClick={evt => showEventStatusMenu(evt)}
              className={classes.clickableIcon}
              data-testid="event-status-options-button"
            >
              <MoreVert />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      {event.isRecurring && !hideRecurrenceDetails && (
        <Toolbar disableGutters>
          <div className={classNames(classes.eventDetails, classes.italic)}>
            <span>
              {rruleToString(
                event.recurrence,
                isAllDay(event.type),
                event.start,
                event.duration,
                event.timezone,
              )}
            </span>
          </div>
          <Tooltip title="Edit Recurrence Details">
            <IconButton onClick={showRecurrenceActionMenu} className={classes.clickableIcon}>
              <MoreVert />
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
      {eventStatusMenuParent && (
        <EventStatusActionsPopup
          event={event}
          user={authStore.user}
          parentElement={eventStatusMenuParent}
          onClose={onCloseMenu}
          onCancel={() => openActionModal('cancel')}
          onComplete={() => validateSignAndComplete()}
          onFeedback={() => openActionModal('feedback')}
          onReschedule={() => openActionModal('reschedule')}
          onUncomplete={() => uncompleteEvent()}
          onCountersign={() => countersignEvent()}
          onUncountersign={() => unCountersignEvent()}
        />
      )}
      {recurrenceMenuParent && (
        <RecurrenceActionsPopup
          event={event}
          parentElement={recurrenceMenuParent}
          onClose={onCloseMenu}
          onCancelRecurrence={() => openActionModal('cancelRecurrence')}
        />
      )}
      {showModal && (
        <>
          {currentAction.useConfirmDialog ? (
            <ConfirmDialog
              isDestructive={currentAction.isDestructive}
              onSubmit={async () => {
                await currentAction.onSubmit();
                onCloseModal();
              }}
              onCancel={onCloseModal}
              submitLabel={currentAction.submitLabel}
              cancelLabel={currentAction.cancelLabel}
            >
              {currentAction.modalContents}
            </ConfirmDialog>
          ) : (
            <Dialog open maxWidth="md" fullWidth onClose={onCloseModal}>
              <DialogTitle>{currentAction.title}</DialogTitle>
              <DialogContent>
                <CurrentForm
                  item={event}
                  onCancel={onCloseModal}
                  onSave={async values => {
                    await currentAction.onSubmit(values);
                    onCloseModal();
                  }}
                  classes={{
                    buttons: classes.buttons,
                  }}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  headerText: {
    fontFamily: 'Nunito Sans',
  },
  toolbar: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'start',

    '&.MuiToolbar-root': {
      minHeight: 0,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Nunito Sans',
  },
  status: {
    fontWeight: 'bold',
  },
  eventDetails: {
    flexGrow: 1,
    '& span': {
      display: 'block',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  clickableIcon: {
    padding: 5,
  },
  [EVENT_STATUSES.CANCELED]: {
    textDecoration: 'line-through',
  },
  [EVENT_STATUSES.RESCHEDULED]: {
    textDecoration: 'line-through',
  },
  eventNotes: {
    fontStyle: 'italic',
    fontSize: '0.85em',
    whiteSpace: 'pre-line',
  },
  rescheduledToLink: {
    '& a': {
      color: 'inherit',
      '&:visited': {
        color: 'inherit',
      },
    },
  },
  '@media print': {
    clickableIcon: {
      display: 'none',
    },
  },
});

export default inject<typeof EventHeader>('rootStore')(EventHeader);
