import { ApolloError } from 'apollo-client';
import { parseISO } from 'date-fns';
import { KeyedMutator } from 'swr';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { extractReason } from 'src/dropInClinic/extractReason';
import { GET_QUEUE } from 'src/dropInClinic/hooks/useRequestQueue.gql';
import type { DropInClinicPatient } from 'src/dropInClinic/types';
import type { VisitReason } from 'src/dropInClinic/visitReason';
import { QueuedEntriesAndActiveVisitsQuery } from 'src/generated/gql/graphql';
import type { UserNames } from 'src/shared/stores/resource';

export type QueueEntry = {
  categories: string[];
  status: string;
  acceptable: boolean;
  insuranceIsAcceptable: boolean | undefined;
  requestId: string;
  requestType: string;
  stateIsAcceptable: boolean | undefined;
  patient: DropInClinicPatient;
  queuedAt: Date;
  preparedBy: UserNames | null;
  reason: VisitReason | null;
};

export type ActiveVisit = {
  categories: string[];
  requestId: string;
  requestType: string;
  event: {
    id: string;
    start: Date;
    status: string;
  };
  patient: DropInClinicPatient;
  queuedAt: Date;
  provider: UserNames;
};

type RequestQueueResponse =
  | {
      allEntries: QueueEntry[];
      activeVisits: ActiveVisit[];
      error: undefined;
      mutate: KeyedMutator<QueuedEntriesAndActiveVisitsQuery>;
    }
  | {
      allEntries: null;
      activeVisits: null;
      error: ApolloError | undefined;
      mutate: KeyedMutator<QueuedEntriesAndActiveVisitsQuery>;
    };

const AUTO_REFRESH_TIME_MS = 30 * 1000;

export function useRequestQueue(): RequestQueueResponse {
  const { data, error, mutate } = useTypedSWR(GET_QUEUE, { refreshInterval: AUTO_REFRESH_TIME_MS });
  if (!data?.getDropInClinicQueue) {
    return { allEntries: null, activeVisits: null, error, mutate };
  }

  return {
    allEntries: data.getDropInClinicQueue.map(entry => ({
      categories: entry.categories,
      status: entry.status,
      acceptable: entry.acceptable,
      insuranceIsAcceptable: entry.insuranceIsAcceptable ?? undefined,
      stateIsAcceptable: entry.stateIsAcceptable ?? undefined,
      requestId: entry.requestId,
      requestType: entry.requestType,
      queuedAt: parseISO(entry.queuedAt),
      patient: {
        id: entry.patient.id,
        firstName: entry.patient.firstName,
        fyi: entry.patient.fyi ?? undefined,
        lastName: entry.patient.lastName,
        preferredName: entry.patient.preferredName ?? undefined,
        homeState: entry.patient.homeState,
        medicationRunOutDate: entry.patient.medicationRunOutDate
          ? parseISO(entry.patient.medicationRunOutDate)
          : undefined,
        dob: entry.patient.dob ? parseISO(entry.patient.dob) : undefined,
      },
      preparedBy: entry.preparedBy,
      reason: extractReason(entry.reason),
    })),
    activeVisits: data.getActiveDropInClinicVisits
      .map(entry => ({
        // the graphql types for this query are kinda janky but we'll trust that active visits have
        // an event and provider
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        categories: entry.categories,
        requestId: entry.requestId,
        requestType: entry.requestType,
        queuedAt: parseISO(entry.queuedAt),
        event: {
          id: entry.event!.id!,
          start: parseISO(entry.event!.start),
          status: entry.event!.status!,
        },
        patient: {
          id: entry.patient.id,
          firstName: entry.patient.firstName,
          lastName: entry.patient.lastName,
          preferredName: entry.patient.preferredName ?? undefined,
          homeState: entry.patient.homeState,
        },
        provider: entry.provider!,
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }))
      .filter(entry => !!entry.event),
    error: undefined,
    mutate,
  };
}
