import { gql } from 'src/generated/gql';

export const GET_QUEUE = gql(`
  query QueuedEntriesAndActiveVisits {
    getDropInClinicQueue {
      categories
      patient {
        id
        firstName
        fyi
        lastName
        preferredName
        medicationRunOutDate
        homeState
        dob
      }
      preparedBy {
        firstName
        lastName
      }
      queuedAt
      requestId
      requestType
      status
      acceptable
      insuranceIsAcceptable
      stateIsAcceptable
      reason {
        reason
        questionOrConcern
        medicationsToRefill
        moreThanTwoDaysBup
      }
    }
    getActiveDropInClinicVisits(includeZombieVisits: true) {
      categories
      event {
        id
        start
        status
      }
      patient {
        id
        firstName
        lastName
        preferredName
        homeState
        dob
      }
      queuedAt
      requestId
      requestType
      provider {
        firstName
        lastName
      }
    }
  }
`);
