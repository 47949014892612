import { LogLevel } from 'configcat-common';
import * as configcat from 'configcat-js';

import {
  getDevFeatureFlags,
  getUrlOverrideFeatureFlags,
} from 'src/components/featureflags/devFlags';
import { isLocalhost } from 'src/shared/util/helpers';

export async function manageFlagState(config, setFlags, getUserObject) {
  const { sdkKey } = config;

  const logger = configcat.createConsoleLogger(LogLevel.Warn);

  async function getAllFlagValues() {
    const devFlags = getDevFeatureFlags();
    const overriddenFlags = getUrlOverrideFeatureFlags();
    const flagValues = (await configCatClient.getAllValuesAsync(getUserObject())).reduce(
      (accum, { settingKey, settingValue }) => ({
        ...accum,
        [settingKey]: overriddenFlags[settingKey] ?? devFlags[settingKey] ?? settingValue ?? false,
      }),
      {},
    );

    if (isLocalhost) {
      console.info('Feature flags', flagValues);
    }

    return flagValues;
  }

  const configCatClient = configcat.createClientWithAutoPoll(sdkKey, {
    logger,
    configChanged: () => getAllFlagValues().then(setFlags).catch(console.error),
  });

  setFlags(await getAllFlagValues());

  return getAllFlagValues;
}
