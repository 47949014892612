import { getParentOfType, flow, getEnv, getRoot, getType, types } from 'mobx-state-tree';
import type { Instance } from 'mobx-state-tree';

import Chat from 'src/stores/chat/chat';
import { DateType } from 'src/stores/chat/date';
import { Message } from 'src/stores/chat/message';
import { ConversationUser } from 'src/stores/chat/user';
import {
  MARK_READ,
  UPDATE_ARCHIVED,
  UPDATE_ARCHIVED_FOR_ALL,
} from 'src/stores/mutations/conversations';
import { SEND_MESSAGE } from 'src/stores/mutations/messages';
import {
  LOAD_MESSAGES,
  LOAD_EARLIER_MESSAGES,
  LOAD_LATEST_MESSAGES,
} from 'src/stores/queries/messages';
import { UserType } from 'src/stores/users/userType';

const BATCH_SIZE = 20;
const DEFAULT_AVATAR_URL = '/avatar.png';

type RootStore = {
  chat: typeof Chat;
};

export const Conversation = types
  .model('Conversation', {
    id: types.maybeNull(types.string),
    users: types.array(ConversationUser),
    otherProviders: types.maybeNull(types.array(ConversationUser)),
    messages: types.optional(types.array(Message), []),
    lastMessageAt: types.maybeNull(DateType),
    lastMessageText: types.maybeNull(types.string),
    closed: types.maybeNull(types.boolean),
  })
  .volatile(() => ({
    // Properties that shouldn't persist when a snapshot is taken
    messagesLoaded: false, // have we done the initial load?
    loadingLatest: false, // are we currently loading latest?
    loadLatestNeeded: false, // should we reload the latest?
    hasEarlier: true, // are there more messages to load?
    loadingEarlier: false, // are we currently loading earlier?
  }))
  .views(self => ({
    /**
     * List all the users in the conversation excluding the current user, eg to generate
     * a summary of the users in the thread.
     */
    get otherUsers() {
      const me = getParentOfType(self, Chat).currentUser;
      return self.users.filter(user => user.userDisplay.id !== me.id);
    },

    /**
     * Return the last read time in the conversation for the given user.
     */
    get lastRead() {
      const me = getParentOfType(self, Chat).currentUser;
      const user = [...self.users, ...(self.otherProviders || [])].find(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        user => user.userDisplay.id === me.id,
      );
      return user ? user.lastRead : null;
    },
  }))
  .views(self => ({
    /**
     * Get the avatar url for a conversation.
     */
    get otherUsersAvatar() {
      // We stopped loading avatars for conversation users in e3278470
      return DEFAULT_AVATAR_URL;
    },

    /**
     * List all the other providers in the conversation, excluding the current user
     */
    get otherProviderUsers() {
      return self.otherUsers.filter(user => user.userDisplay.userType === UserType.Provider);
    },

    /**
     * Does this conversation contain messages that the given user hasn't read yet?
     */
    get hasUnread() {
      const { lastRead } = self;
      if (!lastRead) {
        return true;
      }

      return self.lastMessageAt.getTime() > lastRead;
    },

    get reverseMessages() {
      return self.messages.slice().reverse();
    },

    get mostRecentVideoMessage() {
      return self.messages.find(message => getType(message).name === 'VideoConferenceMessage');
    },

    /**
     * Returns a map from message ID to the list of conversation users who last read that message.
     */
    get lastReadMessages() {
      const lastReadUserMap = self.users.reduce((map, user) => {
        const { lastRead } = user;
        if (lastRead) {
          const lastReadUsers = map[lastRead.valueOf()];
          if (!lastReadUsers) {
            // eslint-disable-next-line no-param-reassign
            map[lastRead.valueOf()] = [user];
          } else {
            lastReadUsers.push(user);
          }
        }
        return map;
      }, {});
      return self.messages.reduce((map, { id, createdAt }) => {
        const users = lastReadUserMap[createdAt.valueOf()];
        if (users) {
          // eslint-disable-next-line no-param-reassign
          map[id] = users;
        }
        return map;
      }, {});
    },

    /**
     * Does the conversation have any Patients included?
     */
    get hasPatients() {
      return self.users.some(user => user.userDisplay.userType !== UserType.Provider);
    },

    /**
     * The patient in this conversation
     */
    get patient() {
      return self.users.find(user => user.userDisplay.userType !== UserType.Provider);
    },

    /**
     * Returns whether the conversation was archived by the current user
     */
    get isArchivedForCurrentUser() {
      const currentUserId = getParentOfType(self, Chat).currentUser.id;
      return self.users.find(({ userDisplay }) => userDisplay.id === currentUserId)?.archived;
    },

    /**
     * Returns whether the conversation is archived for all users in the conversation.
     */
    get isArchivedForAllInConversation() {
      return self.users?.every(user => user.archived);
    },

    /**
     * Returns whether the current user is also the chat owner of the chat the
     * conversation is in.
     */
    get isOwnedByCurrentUser() {
      const parentChat = getParentOfType(self, Chat);
      return parentChat.currentUser.id === parentChat.chatOwner.id;
    },
  }))
  .views(self => ({
    /**
     * Returns if the conversation can be set as archived by the current user.
     */
    get canBeArchivedByCurrentUser() {
      return self.isOwnedByCurrentUser && self.hasPatients;
    },
  }))
  .views(self => ({
    /**
     * Returns if the conversation can be archived for all users in the conversation by the current user.
     */
    get canBeArchivedForAllByCurrentUser() {
      const canBeArchivedAndOtherProviders =
        self.canBeArchivedByCurrentUser && self.otherProviderUsers?.length;
      const isMonitoredConversation = getRoot<RootStore>(self).chat?.monitoredConversation(self.id);
      return canBeArchivedAndOtherProviders || isMonitoredConversation;
    },

    /**
     * The URL for the avatar associated with a given user on the conversation.
     * This allows us to avoid making additional requests for something that
     * can't currently change within a conversation.
     */
    avatarUrlForUser() {
      // We stopped loading avatars for conversation users in e3278470
      return DEFAULT_AVATAR_URL;
    },
  }))
  .actions(self => {
    /**
     * Utility function for sending a message regardless of type.
     */
    const sendMessage = flow(function* sendMessage(messageData) {
      const me = getParentOfType(self, Chat).currentUser;

      // Add to existing conversation
      if (self.id) {
        const response = yield getEnv(self).apolloClient.mutate({
          mutation: SEND_MESSAGE,
          variables: { fromId: me.id, conversationId: self.id, ...messageData },
        });
        yield Promise.all(
          getParentOfType(self, Chat)
            .allConversationsWithId(self.id)
            .map(conv => conv.loadLatestMessages()),
        );
        return response.data.createMessage;
      } else {
        return getParentOfType(self, Chat).sendMessageInNewConversation(messageData);
      }
    });

    return {
      sendTextMessage(text) {
        return sendMessage({ text });
      },

      sendVideoConferenceMessage() {
        return sendMessage({ videoConference: true });
      },

      setMeta(updatedConversation) {
        self.users = updatedConversation.users;
        self.otherProviders = updatedConversation.otherProviders;
        self.lastMessageAt = updatedConversation.lastMessageAt;
        self.lastMessageText = updatedConversation.lastMessageText;
        self.closed = updatedConversation.closed;
      },

      /**
       * Update the conversation with any new messages. This can be called as often as needed. When
       * a message is sent or received, rather than push the message directly onto the messages
       * array, we instead ignore the payload and just call this method to fetch the latest. This
       * avoids having to maintain consistency between the client and the server since we always
       * get the latest from the server, at the cost of an extra round-trip.
       */
      loadLatestMessages: flow(function* loadLatestMessages() {
        // If this is a new conversation, there's nothing to load.
        if (!self.id) {
          return;
        }

        // If a load is already happening, we don't wait to load another one in parallel and risk
        // adding the same message twice, so just set a flag indicating that we should do a second
        // load when the first is complete. This guarantees that the results will be up to date.
        if (self.loadingLatest) {
          self.loadLatestNeeded = true;
          return;
        }

        self.loadingLatest = true;
        try {
          // If the initial messages are already loaded, just fetch the latest.
          if (self.messagesLoaded && self.messages.length > 0) {
            const response = yield getEnv(self).apolloClient.query({
              query: LOAD_LATEST_MESSAGES,
              variables: { conversationId: self.id, before: self.messages[0].id },
            });
            self.messages.unshift(...response.data.messages);
            self.lastMessageAt = self.messages[0].createdAt;
            self.lastMessageText = response.data.conversations?.[0]?.lastMessageText;
          }
          // If this is the first load or there weren't any messages before,
          // load all the messages.
          else {
            const response = yield getEnv(self).apolloClient.query({
              query: LOAD_MESSAGES,
              variables: { conversationId: self.id, first: BATCH_SIZE },
            });
            if (response.data.conversations[0]?.otherProviders) {
              self.otherProviders = response.data.conversations[0].otherProviders;
            }
            self.messages = response.data.messages;
            if (self.messages.length > 0) {
              self.lastMessageAt = self.messages[0].createdAt;
            }
            self.lastMessageText = response.data.conversations?.[0]?.lastMessageText;
            self.messagesLoaded = true;
          }
        } finally {
          // The load is now complete, so check whether anyone requested newer data while we were
          // loading, in which case we need to start the whole thing again.
          self.loadingLatest = false;
          if (self.loadLatestNeeded) {
            self.loadLatestNeeded = false;
            yield (
              self as typeof self & { loadLatestMessages: typeof loadLatestMessages }
            ).loadLatestMessages();
          }
        }
      }),

      loadEarlierMessages: flow(function* loadEarlierMessages() {
        // We're already at the beginning. Rather than use the more complex connection API, we just
        // keep loading earlier messages until we get a result that's shorter than we asked for, and
        // then know we're done.
        if (!self.hasEarlier) {
          return;
        }
        // You can't load earlier messages until you've loaded the initial messages.
        if (!self.messagesLoaded) {
          return;
        }
        // Don't allow multiple simultaneous queries. Instead of the complex logic in loadLatestMessages
        // that guarantees we always get every message, we just ignore any parallel requests since
        // we care less about keeping the old messages up to date.
        if (self.loadingEarlier) {
          return;
        }

        // No way to find messages earlier than <undefined>
        if (!self.messages.length) {
          return;
        }

        const response = yield getEnv(self).apolloClient.query({
          query: LOAD_EARLIER_MESSAGES,
          variables: {
            conversationId: self.id,
            after: self.messages[self.messages.length - 1].id,
            first: BATCH_SIZE,
          },
        });
        self.messages.push(...response.data.messages);

        if (response.data.messages.length < BATCH_SIZE) {
          self.hasEarlier = false;
        }
      }),

      /**
       * Update the last read date to indicate we've seen this conversation.
       */
      markRead: flow(function* markRead() {
        // Don't bother if it's already up to date, ie there are no unread messages.
        if (!self.hasUnread) {
          return;
        }

        // We do this client-side because we want to ensure that we only mark messages as read if we've actually received them.
        const me = getParentOfType(self, Chat).currentUser;
        const conversationUser = [...self.users, ...(self.otherProviders || [])].find(
          user => user.userDisplay.id === me.id,
        );
        const newLastRead = self.lastMessageAt;
        conversationUser.lastRead = newLastRead;

        yield getEnv(self).apolloClient.mutate({
          mutation: MARK_READ,
          variables: { conversationId: self.id, value: newLastRead },
        });

        // Update the top most chat store's unread message count. This top most chat store is the
        // one that will be used by various parts of the interface to display the unread message
        // count. We need to call the top most because we may be in a monitored chat and updating
        // that count wouldn't be seen by anyone watching the count.
        yield getRoot<RootStore>(self).chat.loadUnreadMessageCount();
      }),

      /**
       * Update the archived value of the current conversation user with the passed in value.
       */
      updateArchived: flow(function* updateArchived(newArchived) {
        const currentUserId = getParentOfType(self, Chat).currentUser.id;
        const conversationUser = self.users.find(({ user }) => user.id === currentUserId);
        conversationUser.archived = newArchived;

        yield getEnv(self).apolloClient.mutate({
          mutation: UPDATE_ARCHIVED,
          variables: { conversationId: self.id, value: newArchived },
        });
      }),

      /**
       * Update the archived value of all users in the current conversation with the passed in value.
       */
      updateArchivedForAll: flow(function* updateArchivedForAll(newArchived) {
        if (!self.users?.length) {
          return;
        }

        self.users.forEach(user => {
          user.setArchived(newArchived);
        });
        self.otherProviders.forEach(user => {
          user.setArchived(newArchived);
        });

        yield getEnv(self).apolloClient.mutate({
          mutation: UPDATE_ARCHIVED_FOR_ALL,
          variables: { conversationId: self.id, value: newArchived },
        });
      }),

      /**
       * Updates local conversation closed value after mutation.
       * Closed conversations cannot recieve new messages from patients.
       */
      updateClosed(closed) {
        self.closed = closed;
      },
    };
  });

export type ConversationInstance = Instance<typeof Conversation>;
