import { ApolloError } from 'apollo-client';
import { parseISO } from 'date-fns';
import { KeyedMutator } from 'swr';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { extractReason } from 'src/dropInClinic/extractReason';
import { GET_CLINIC_QUEUE } from 'src/dropInClinic/hooks/useClinicRequestQueue.gql';
import { DropInClinicPatient } from 'src/dropInClinic/types';
import type { VisitReason } from 'src/dropInClinic/visitReason';
import { ClinicDropInQueueQuery } from 'src/generated/gql/graphql';
import type { UserNames } from 'src/shared/stores/resource';

type ClinicAcceptabilityDetails = {
  providerId: string;
  acceptable: boolean;
  insuranceIsAcceptable: boolean | null;
  stateIsAcceptable: boolean | null;
  acceptableForRole: boolean | null;
};

export type QueueEntry = {
  request: {
    id: string;
    requestTime: Date;
    status: string;
    requestType: string;
    reason: VisitReason | null;
  };
  categories: string[];
  acceptableForProvider: ClinicAcceptabilityDetails;
  acceptableForAvailableProviders: ClinicAcceptabilityDetails[];
  patient: DropInClinicPatient;
  preparedBy: UserNames | null;
  scheduleSuggestion: {
    outcome: string;
    details: {
      startTime: Date | null;
      duration: number;
      provider: { id: string; firstName: string; lastName: string };
    } | null;
  };
};

export type ActiveVisit = {
  categories: string[];
  requestId: string;
  requestType: string;
  event: {
    id: string;
    start: Date;
    status: string;
  };
  patient: DropInClinicPatient;
  queuedAt: Date;
  provider: UserNames;
};

type RequestQueueResponse =
  | {
      allEntries: QueueEntry[];
      activeVisits: ActiveVisit[];
      error: undefined;
      mutate: KeyedMutator<ClinicDropInQueueQuery>;
    }
  | {
      allEntries: null;
      activeVisits: null;
      error: ApolloError | undefined;
      mutate: KeyedMutator<ClinicDropInQueueQuery>;
    };

const AUTO_REFRESH_TIME_MS = 30 * 1000;

export function useClinicRequestQueue(providerId: string | null): RequestQueueResponse {
  const { data, error, mutate } = useTypedSWR(
    [providerId ? GET_CLINIC_QUEUE : null, { providerId }],
    {
      refreshInterval: AUTO_REFRESH_TIME_MS,
    },
  );
  if (!data?.staff_getClinicDropInQueue) {
    return { allEntries: null, activeVisits: null, error, mutate };
  }

  return {
    allEntries: data.staff_getClinicDropInQueue.map(entry => ({
      request: {
        id: entry.request.id,
        requestTime: parseISO(entry.request.requestTime),
        status: entry.request.status,
        requestType: entry.request.requestType,
        reason: extractReason(entry.request.reason),
      },
      categories: entry.categories,
      patient: {
        id: entry.patient.id,
        firstName: entry.patient.firstName,
        fyi: entry.patient.fyi ?? undefined,
        lastName: entry.patient.lastName,
        preferredName: entry.patient.preferredName ?? undefined,
        homeState: entry.patient.homeState,
        medicationRunOutDate: entry.patient.medicationRunOutDate
          ? parseISO(entry.patient.medicationRunOutDate)
          : undefined,
        dob: entry.patient.dob ? parseISO(entry.patient.dob) : undefined,
      },
      preparedBy: entry.preparedBy,
      acceptableForAvailableProviders: entry.acceptableForAvailableProviders,
      acceptableForProvider: entry.acceptableForProvider,
      scheduleSuggestion: {
        outcome: entry.scheduleSuggestion.outcome,
        details: entry.scheduleSuggestion.details
          ? {
              ...entry.scheduleSuggestion.details,
              startTime: parseISO(entry.scheduleSuggestion.details.startTime),
            }
          : null,
      },
    })),

    activeVisits: data.getActiveDropInClinicVisits
      .map(entry => ({
        // the graphql types for this query are kinda janky but we'll trust that active visits have
        // an event and provider
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        categories: entry.categories,
        requestId: entry.requestId,
        requestType: entry.requestType,
        queuedAt: parseISO(entry.queuedAt),
        event: {
          id: entry.event!.id!,
          start: parseISO(entry.event!.start),
          status: entry.event!.status!,
        },
        patient: {
          id: entry.patient.id,
          firstName: entry.patient.firstName,
          lastName: entry.patient.lastName,
          preferredName: entry.patient.preferredName ?? undefined,
          homeState: entry.patient.homeState,
        },
        provider: entry.provider!,
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }))
      .filter(entry => !!entry.event),
    error: undefined,
    mutate,
  };
}
