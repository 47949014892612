import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';

import EventDetailsForm from 'src/components/forms/eventDetailsForm';
import Tooltip from 'src/components/general/Tooltip';
import EventFetcher from 'src/components/general/eventFetcher';
import RouterLink from 'src/components/general/routerLink';
import EventHeader from 'src/components/pages/pageElements/eventHeader';
import { DISABLED_TOOLTIP, eventIsUneditable } from 'src/shared/util/events';
import { colors } from 'src/util/colors';

const EventPopup = ({ calendar, classes, item, itemElement, position, onClose, onSave }) => {
  const [editingEventDetails, setEditingEventDetails] = useState(false);

  const handleEditEventDetails = () => {
    setEditingEventDetails(!editingEventDetails);
  };

  const handleCancelEventDetails = () => {
    setEditingEventDetails(false);
  };

  const handleSaveEventDetails = async values => {
    await onSave(values);
    setEditingEventDetails(false);
  };

  if (!item) {
    return null;
  }

  // Get the mobx object so we're able to take advantage of actions/views
  // This may be undefined in the edgecase where one of the actions in the
  // <EventHeader> below resulted in removing the event from the calendar (i.e.
  // cancellation). The calendar should update it's selection after the store
  // reflects the new changes, but there will be a split second where this
  // popover will still show. TODO: This could be avoided with modals managed
  // in a different way.
  const event = calendar.eventInstanceById(item.id) || item;

  // Additional event props that need to be fetched for the event popup to work
  // properly. These are not loaded initially to improve calendar performance.
  // TODO Ideally we wouldn't have GraphQL details in the UI code. Find a better
  // place for this.
  const eventProps = [
    `signedByDisplay {
      ...on UserDisplay {
        id
        firstName
        lastName
        pronouns
        preferredName
        patientFacingDisplayName
      }
    }`,
    'signedAt',
    `countersignedByDisplay {
      ...on UserDisplay {
        id
        firstName
        lastName
        pronouns
        preferredName
        patientFacingDisplayName
      }
    }`,
    'countersignedAt',
    `recurrence {
      type
      freq
      interval
      byDay
      byMonthDay
      bySetPos
      byDayForMonth
      until
    }`,
    'scheduleChangeReason',
    `createdByDisplay {
      ...on UserDisplay {
        id
        firstName
        lastName
        pronouns
        preferredName
        patientFacingDisplayName
      }
    }`,
    'scheduleChangeNotes',
    'appointmentNotes',
    `vc {
      sessionId
      token
      guestUrl
    }`,
    `updatedByDisplay {
      ...on UserDisplay {
        firstName
        lastName
        preferredName
      }
    }`,
    `updatedAt`,
  ];

  const disableEdit = eventIsUneditable(event);
  return (
    <EventFetcher
      eventId={event.id}
      eventProps={eventProps}
      render={({ event: fetchedEvent }) => (
        <Menu
          className={classes.viewContainer}
          classes={{
            paper: classes.viewContainer,
          }}
          open
          anchorEl={itemElement}
          anchorPosition={position}
          anchorReference={position ? 'anchorPosition' : 'anchorEl'}
          onClose={() => onClose()}
        >
          <div className={classes.eventHeader}>
            <EventHeader event={event} additionalProps={fetchedEvent} />
          </div>
          <hr className={classes.divider} />
          {!editingEventDetails && (
            <Tooltip title={disableEdit ? DISABLED_TOOLTIP : ''} className={classes.tooltip}>
              <span>
                <IconButton
                  disabled={disableEdit}
                  onClick={handleEditEventDetails}
                  className={classes.editButton}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <div
            className={classNames(
              classes.eventDetails,
              editingEventDetails && classes.scrollableDetails,
            )}
            style={{
              // Hack to prevent the modal from resizing itself off screen
              // when clicking on the pencil to edit
              height:
                70 *
                ['type', 'subType', 'attendees', 'title'].reduce((accumulator, key) => {
                  return accumulator + (event[key] !== undefined ? 1 : 0);
                }, 0),
            }}
          >
            <EventDetailsForm
              event={event}
              additionalProps={fetchedEvent}
              isEditing={editingEventDetails}
              onSave={handleSaveEventDetails}
              onCancel={handleCancelEventDetails}
            />
          </div>
          <div className={classes.actions}>
            <RouterLink routeName="showEvent" params={{ id: item.id }}>
              <Button className={classes.viewButton} component="span" size="small">
                <span className={classes.viewTitle}>View</span>
              </Button>
            </RouterLink>
          </div>
        </Menu>
      )}
    />
  );
};

const styles: Styles<Theme, any> = () => ({
  divider: {
    borderColor: 'rgba(0, 0, 0, 0.12)',
    // Only need it to be 1px width, not 2
    borderTop: 0,
  },
  editButton: {
    // This centers the pencil with the label for the first field in the form
    marginTop: 2,
    // This matches the padding used by the 3 dot icon button above
    padding: 5,
  },
  tooltip: {
    // right align the tooltip and edit button with the other menu icons
    position: 'absolute',
    right: 20,
  },
  eventHeader: {
    padding: '20px 20px 0px',
    '&:focus': {
      outline: 'none',
    },
  },
  eventDetails: {
    // This allows us to scroll the
    overflowY: 'scroll',
    padding: '0 20px',
    '&:focus': {
      outline: 'none',
    },
  },
  scrollableDetails: {
    // Borrowed from https://stackoverflow.com/a/31278448
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  actions: {
    backgroundColor: 'white',
  },
  viewButton: {
    color: colors.primary,
    float: 'right',
    fontSize: 14,
    padding: 10,
    textTransform: 'uppercase',
  },
  viewContainer: {
    maxWidth: 450,
    width: '100%',
  },
  viewTitle: {
    paddingLeft: 6,
  },
});

export default withStyles(styles)(EventPopup);
