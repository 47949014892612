export type AcceptMutationArgs = {
  requestId: string;
};

export type AcceptMutationResponse = {
  acceptDropInClinicRequest: {
    eventId: string | null;
  };
};

export type PrepareMutationArgs = {
  requestId: string;
};

export type PrepareMutationResponse = {
  prepareDropInClinicRequest: {
    eventId: string | null;
  };
};

export type CompletePrepMutationArgs = {
  requestId: string;
};

export type CompletePrepMutationResponse = {
  completePrepDropInClinicRequest: {
    status: string;
  };
};

export type CancelMutationArgs = {
  requestId: string;
};

export type CancelMutationResponse = {
  removeDropInClinicRequest: {
    status: string;
  };
};

export type FixZombieMutationArgs = {
  requestId: string;
};

export type FixZombieMutationResponse = {
  fixZombieDropInClinicRequest: {
    status: string;
  };
};

export type DropInClinicPatient = {
  id: string;
  firstName: string;
  fyi?: string;
  lastName: string;
  preferredName?: string;
  medicationRunOutDate?: Date;
  homeState: string;
  dob?: Date;
};

export type DropInClinicProvider = {
  id: string;
  firstName: string;
  lastName: string;
};

export type DropInClinicPatientGraphql = Omit<
  DropInClinicPatient,
  'dob' | 'medicationRunOutDate'
> & {
  dob?: string;
  medicationRunOutDate?: string;
};

export type DropInQueueEntry = {
  categories: string[];
  status: string;
  acceptable: boolean;
  insuranceIsAcceptable?: boolean;
  requestId: string;
  requestType: string;
  stateIsAcceptable?: boolean;
  patient: DropInClinicPatient;
  queuedAt: Date;
  provider?: DropInClinicProvider;
  preparedBy?: DropInClinicProvider;
};

type TimeRangeGraphql = {
  start: string;
  end: string;
};

export type ProviderAvailabilityGraphql = {
  dropInProvider: {
    firstName: string;
    lastName: string;
  };
  availability: TimeRangeGraphql;
};

export type OverviewGraphql = {
  windows: TimeRangeGraphql[];
  hasAvailability: boolean;
};

export type GeneralAvailabilityGraphql = {
  providerAvailability: ProviderAvailabilityGraphql[];
  overview: OverviewGraphql;
};

export type AvailabilityQueryReturn = {
  getClinicAvailabilitiesByDate: Record<string, GeneralAvailabilityGraphql>;
};

type TimeRange = {
  start: Date;
  end: Date;
};

export type ProviderAvailability = {
  dropInProvider: {
    firstName: string;
    lastName: string;
  };
  availability: TimeRange;
};

export type Overview = {
  windows: TimeRange[];
  hasAvailability: boolean;
};

export type GeneralAvailability = {
  providerAvailability: ProviderAvailability[];
  overview: Overview;
};

export enum UnscheduledRequestReason {
  INTERNAL_ERROR = 'INTERNAL_ERROR',

  // Multiple providers could see this patient, but we could not fit them
  // into the available time
  NO_TIME = 'NO_TIME',

  // No providers could see this patient in the given availability windows
  NO_CREDENTIALED_PROVIDER = 'NO_CREDENTIALED_PROVIDER',

  // Only a single provider could see this patient, and their schedule was
  // full before this patient could be added to it
  SINGLE_PROVIDER_OVERBOOKED = 'SINGLE_PROVIDER_OVERBOOKED',
}

export type ClinicScheduleSuggestionGraphql = {
  request: DropInClinicRequest;
  startTime: string;
  duration: number;
  provider: DropInClinicProvider;
};

type DropInClinicRequest = {
  id: string;
};

export type ClinicScheduleSuggestion = {
  request: DropInClinicRequest;
  startTime: Date;
  duration: number;
  provider: DropInClinicProvider;
};

type UnscheduledRequest = {
  request: DropInClinicRequest;
  reason: UnscheduledRequestReason;
};

export type SuggestedClinicScheduleGraphql = {
  suggestions: ClinicScheduleSuggestionGraphql[];
  unscheduledRequests: UnscheduledRequest[];
};

export type SuggestedClinicSchedule = {
  suggestions: ClinicScheduleSuggestion[];
  unscheduledRequests: UnscheduledRequest[];
};
