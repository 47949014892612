import { types, Instance, IAnyModelType, ITypeUnion } from 'mobx-state-tree';

import {
  DateType,
  File,
  Patient,
  Provider,
  Recurrence,
  Resource,
  Updatable,
} from 'src/shared/stores/resource';
import { isAllDay, SCHEDULE_CHANGE_REASONS, INQUIRY_SUBTYPES } from 'src/shared/util/events';
import { generateEventNotes } from 'src/stores/events/util';
import { UserDisplay } from 'src/stores/models/userDisplay';

type ScheduleChangeReasonKeys = keyof typeof SCHEDULE_CHANGE_REASONS;
type Instantiated<Model extends Instance<unknown>, RequiredKeys extends keyof Model> = Omit<
  Model,
  RequiredKeys
> & {
  [K in RequiredKeys]: NonNullable<Model[K]>;
};

export type EventResult = Instance<typeof EventResultModel>;
const EventResultModel = types.model({
  type: types.string,
  version: types.string,
  results: types.frozen<Record<string, unknown>>(),
});

export type EventInstance = Instance<typeof Event>;
export type InstantiatedEvent = Instantiated<
  EventInstance,
  'id' | 'type' | 'eventResults' | 'start' | 'timezone'
>;

export const Event = Updatable.named('Event')
  .props({
    id: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    subType: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    recurrence: types.maybeNull(Recurrence),
    scheduleChangeReason: types.maybeNull(
      types.union(
        ...Object.keys(SCHEDULE_CHANGE_REASONS).map(key => types.literal(key)),
        // We cast here because the union is too big for mobx-state-tree to infer
      ) as ITypeUnion<ScheduleChangeReasonKeys, ScheduleChangeReasonKeys, ScheduleChangeReasonKeys>,
    ),
    scheduleChangeNotes: types.maybeNull(types.string),
    appointmentNotes: types.maybeNull(types.string),
    attendees: types.optional(types.array(Resource), []),
    createdByDisplay: types.maybeNull(UserDisplay),
    createdVia: types.maybeNull(types.string),
    hasVisitNotes: types.maybeNull(types.boolean),
    signedByDisplay: types.maybeNull(UserDisplay),
    signedAt: types.maybeNull(DateType),
    countersignedByDisplay: types.maybeNull(UserDisplay),
    countersignedAt: types.maybeNull(DateType),
    start: types.maybeNull(DateType),
    duration: types.maybeNull(types.integer),
    timezone: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    vc: types.maybeNull(
      types.model({
        sessionId: types.string,
        token: types.string,
        guestUrl: types.maybeNull(types.string),
      }),
    ),
    patientResults: types.maybeNull(
      types.model({
        barcode: types.maybeNull(types.string),
        barcodePhoto: types.maybeNull(File),
        photos: types.optional(types.array(File), []),
      }),
    ),
    eventResults: types.optional(types.array(EventResultModel), []),
    // We use IAnyModelType here to avoid a circular type reference
    rescheduledTo: types.maybeNull(types.late((): IAnyModelType => Event)),
    createdAt: types.maybeNull(DateType),
    isNightingale: types.maybeNull(types.boolean),
    updatedAt: types.maybeNull(DateType),
    updatedByDisplay: types.maybeNull(UserDisplay),
    isVideoComplete: types.maybeNull(types.boolean),
    videoEnd: types.maybeNull(DateType),
  })
  .views(self => ({
    get signedBy() {
      return self.signedByDisplay;
    },
    get countersignedBy() {
      return self.countersignedByDisplay;
    },
    get createdBy() {
      return self.createdByDisplay;
    },
    get allDay(): boolean {
      return isAllDay(self.type);
    },
    get isRecurring(): boolean {
      return Boolean(self.recurrence && self.recurrence.type === 'scheduled');
    },
    get providerAttendees(): Provider[] {
      return self.attendees.filter(value => value.__typename === 'Provider') as Provider[];
    },
    get patientAttendee(): Patient | null {
      return self.attendees.filter(value => value.__typename === 'Patient').length === 1
        ? (self.attendees.filter(value => value.__typename === 'Patient')[0] as Patient)
        : null;
    },
    get eventNotes(): string {
      // Use `this` instead of `self` to avoid ts error caused by type EventInstance having computed values that are not present in the
      // type of `self`. This is a proposed solution from the MST docs: https://mobx-state-tree.js.org/tips/typescript#typing-self-in-actions-and-views.
      return generateEventNotes(this);
    },

    // Helper view for Inquiries specifically
    get formDetails() {
      const isRightSubType = self.subType && INQUIRY_SUBTYPES.includes(self.subType);
      const hasEventResults = self.eventResults?.length > 0;
      if (isRightSubType && hasEventResults) {
        const formDetailsEventResults = self.eventResults.find(
          ({ type }) => type === 'inquiryFormDetails',
        );
        return formDetailsEventResults?.results;
      }
      return undefined;
    },
  }))
  .actions(self => ({
    addEventResult(result: EventResult): void {
      self.eventResults.push(result);
    },
    deleteEventResult(resultIndex: number): void {
      if (resultIndex > -1 && self.eventResults.length > resultIndex) {
        self.eventResults.splice(resultIndex, 1);
      }
    },
    updateEventResult(resultIndex: number, results: Record<string, unknown>): void {
      if (resultIndex > -1 && self.eventResults.length > resultIndex) {
        self.eventResults[resultIndex].results = results;
      }
    },
    update(updates): void {
      Object.assign(self, updates);
    },
  }));
