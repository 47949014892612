import { GET_VISIT_REASON } from 'src/components/forms/hooks/useVisitReason.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { extractReason } from 'src/dropInClinic/extractReason';
import { VisitReason } from 'src/dropInClinic/visitReason';

export const useVisitReason = (eventId: string | undefined | null): string | null => {
  const { data } = useTypedSWR([
    eventId ? GET_VISIT_REASON : null,
    {
      eventId,
    },
  ]);

  if (!data) {
    return null;
  }

  let reason: VisitReason | null;
  try {
    reason = extractReason(data.staff_getVisitReasonFromEvent);
  } catch {
    return 'Internal server error: invalid visit reason';
  }

  if (!reason) {
    return null;
  }

  switch (reason.reason) {
    case 'outOfMedication':
      return `Needs refill: ${reason.needMedications.join(', ')}`;
    case 'medicationQuestion':
      return `Question about medication: ${reason.question}`;
    case 'discussion':
      return `Requested clinician discussion: ${reason.question}`;
    case 'receivedText':
      return reason.sufficientBupe
        ? 'Received text notification'
        : 'Received text notification: less than 2 days bup remaining';
    default:
      return 'Internal server error: invalid visit reason';
  }
};
