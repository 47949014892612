import gql from 'graphql-tag';

export const GET_PATIENTS_ELIGIBLE_FOR_DROP_IN_CLINIC_QUERY = gql`
  fragment ClinicPatientFragment on DropInQueueEligibilityEntry {
    patient {
      id
      dob
      firstName
      homeState
      lastName
      medicationRunOutDate
      preferredName
    }
  }

  query {
    getPatientsEligibleForDropInClinic {
      followUp {
        eligibility {
          expires
          lastSet
        }
        ...ClinicPatientFragment
      }
      intake {
        eligibility {
          lastSet
        }
        ...ClinicPatientFragment
      }
      reengagement {
        eligibility {
          lastSet
        }
        ...ClinicPatientFragment
      }
    }
  }
`;
