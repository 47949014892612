import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, withStyles } from '@material-ui/core/styles';
import type { ClassNameMap } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as DeverifiedSvg } from 'src/components/general/assets/deverified-icon.svg';
import { ReactComponent as VerifiedSvg } from 'src/components/general/assets/verified-check.svg';
import Colors from 'src/nightingale/Colors';

const verificationIconStyles = () =>
  createStyles({
    verification: {
      bottom: 9,
      height: 40,
      position: 'absolute',
      right: -4,
      width: 40,
    },
  });

const photoStyles = () =>
  createStyles({
    profilePhotoButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      marginBottom: 0,
      marginLeft: -16,
      marginRight: -16,
      marginTop: -10,
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 10,
      position: 'relative',

      '&::after': {
        backgroundColor: Colors.ChartGray,
        content: '""',
        height: 1,
        left: 0,
        position: 'absolute',
        right: 0,
        top: '100%',
      },
    },
    profilePhoto: {
      background: Colors.BoulderBlue,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      borderColor: Colors.BoulderBlue,
      borderRadius: 98,
      borderStyle: 'solid',
      borderWidth: 2,
      boxSizing: 'border-box',
      color: Colors.White,
      display: 'block',
      // 98: the 130px sidebar width, minus the 16px left + right padding
      height: 98,
      imageResolution: '30dpi',
      margin: 0,
      // 98: the 130px sidebar width, minus the 16px left + right padding
      width: 98,
      // `cover` will center the image in the provided space and crop any overflow
      objectFit: 'cover',
      '&.deverified': {
        borderColor: Colors.Coral,
      },
    },
    embiggenedProfilePhoto: {
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
      width: '100%',
    },
  });

const VerifiedCheck = withStyles(verificationIconStyles)(
  ({ classes }: { classes: ClassNameMap }) => (
    <SvgIcon className={classes.verification} data-testid="verified-check">
      <VerifiedSvg />
    </SvgIcon>
  ),
);

const DeverifiedIcon = withStyles(verificationIconStyles)(
  ({ classes }: { classes: ClassNameMap }) => (
    <SvgIcon className={classes.verification} data-testid="deverified-icon">
      <DeverifiedSvg />
    </SvgIcon>
  ),
);

export type ProfileAvatarProps = {
  altText: string;
  classes: ClassNameMap;
  isVerified: boolean | null;
  photoUrl: string;
};

export const ProfileAvatar = withStyles(photoStyles)(({
  altText,
  classes,
  isVerified,
  photoUrl,
}: ProfileAvatarProps) => {
  const [embiggenProfile, setEmbiggenProfile] = useState(false);

  const imageClassList = [classes.profilePhoto];
  if (isVerified === false) {
    imageClassList.push('deverified');
  }

  return (
    <>
      <button
        type="button"
        className={`${classes.profilePhotoButton}`}
        onClick={() => setEmbiggenProfile(true)}
      >
        {isVerified === true && <VerifiedCheck />}
        {isVerified === false && <DeverifiedIcon />}
        <figure
          className={classNames(imageClassList)}
          style={{ backgroundImage: `url(${photoUrl})` }}
          title={altText}
        />
      </button>
      <Dialog open={embiggenProfile} onClose={() => setEmbiggenProfile(false)}>
        <DialogContent>
          <img
            data-testid="profile-avatar-large"
            className={classes.embiggenedProfilePhoto}
            src={photoUrl}
            alt={altText}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});
