import type { VisitReason } from 'src/dropInClinic/visitReason';
import {
  QueuedEntriesAndActiveVisitsQuery,
  Staff_DropInReasonType as DropInReason,
} from 'src/generated/gql/graphql';

type GqlReason = QueuedEntriesAndActiveVisitsQuery['getDropInClinicQueue'][number]['reason'];

export const extractReason = (reason: GqlReason): VisitReason | null => {
  if (!reason) {
    return null;
  }
  switch (reason.reason) {
    case DropInReason.OutOfMedication:
      return { reason: 'outOfMedication', needMedications: reason.medicationsToRefill ?? [] };
    case DropInReason.Discussion:
      return { reason: 'discussion', question: reason.questionOrConcern ?? '' };
    case DropInReason.MedicationQuestion:
      return { reason: 'medicationQuestion', question: reason.questionOrConcern ?? '' };
    case DropInReason.ReceivedText:
      return { reason: 'receivedText', sufficientBupe: !!reason.moreThanTwoDaysBup };
    default:
      throw new Error(`Unexpected visit reason ${reason.reason}`);
  }
};
