import keyBy from 'lodash/keyBy';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { CLINIC_QUEUE_SETTINGS_QUERY } from 'src/dropInClinic/queueSettings/queueSettings.gql';
import { Staff_QueueSchedule as QueueSchedule } from 'src/generated/gql/graphql';

/** Mapping from setting names to their values and data types */
type QueueSettings = Partial<Record<string, { dataType: string; value: unknown }>>;

export function useQueueSettings(): {
  activeQueueSettings: QueueSettings;
  hasError: boolean;
  isLoading: boolean;
  weekdayQueueSettings: QueueSettings;
  weekendQueueSettings: QueueSettings;
} {
  const { data: activeQueueSettingsData, error: activeQueueSettingsError } = useTypedSWR([
    CLINIC_QUEUE_SETTINGS_QUERY,
    { schedule: QueueSchedule.Active },
  ]);
  const activeQueueSettings = activeQueueSettingsData
    ? keyBy(activeQueueSettingsData.staff_clinicQueueSettings, 'name')
    : {};

  const { data: weekdayQueueSettingsData, error: weekdayQueueSettingsError } = useTypedSWR([
    CLINIC_QUEUE_SETTINGS_QUERY,
    { schedule: QueueSchedule.Weekday },
  ]);
  const weekdayQueueSettings = weekdayQueueSettingsData
    ? keyBy(weekdayQueueSettingsData.staff_clinicQueueSettings, 'name')
    : {};

  const { data: weekendQueueSettingsData, error: weekendQueueSettingsError } = useTypedSWR([
    CLINIC_QUEUE_SETTINGS_QUERY,
    { schedule: QueueSchedule.Weekend },
  ]);
  const weekendQueueSettings = weekendQueueSettingsData
    ? keyBy(weekendQueueSettingsData.staff_clinicQueueSettings, 'name')
    : {};

  const isLoading = !(
    activeQueueSettingsData &&
    weekdayQueueSettingsData &&
    weekendQueueSettingsData
  );
  const hasError = Boolean(
    activeQueueSettingsError || weekdayQueueSettingsError || weekendQueueSettingsError,
  );

  return {
    activeQueueSettings,
    hasError,
    isLoading,
    weekdayQueueSettings,
    weekendQueueSettings,
  };
}
