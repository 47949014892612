import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import classNames from 'classnames';
import format from 'date-fns/format';
import pick from 'lodash/pick';
import React, { useContext, useState } from 'react';
import useSWR from 'swr';

import { ADD_IDS_TO_LIST_CHART_PROPERTY_ITEMS } from 'src/components/featureflags/currentFlags';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ErrorAlert from 'src/components/general/ErrorAlert';
import Tooltip from 'src/components/general/Tooltip';
import RouteLink from 'src/components/general/routeLink';
import { ChartContext } from 'src/nightingale/components/ChartContext/ChartContext';
import { useStyles } from 'src/nightingale/components/ChartPropertyHistory/ChartPropertyHistory.Modal.styles';
import { ChartPropertyHistoryEntryValue } from 'src/nightingale/components/ChartPropertyHistory/ChartPropertyHistory.Value';
import {
  GetPropertyHistoryResult,
  GET_PROPERTY_HISTORY,
} from 'src/nightingale/components/ChartPropertyHistory/queries';
import type { HistoricalValue } from 'src/nightingale/components/ChartPropertyHistory/types';
import { getEmptyValueForChartProperty } from 'src/nightingale/data/ChartProperty.utils';
import type { AnyChartProperty } from 'src/nightingale/types/types';
import { ChartPropertyAction, ChartPropertyTypes } from 'src/nightingale/types/types';

export const ChartPropertyHistoryModal: React.FC<{
  definition: AnyChartProperty;
  updateProperty: ({
    value,
    notes,
    isEmpty,
  }: Pick<HistoricalValue, 'value' | 'notes' | 'isEmpty'>) => void;
  setShowHistoryModal: (showHistoryModal: boolean) => void;
  readOnly?: boolean;
}> = ({ definition, updateProperty, setShowHistoryModal, readOnly }) => {
  const { name: propertyName, type: propertyType } = definition;

  const styles = useStyles();
  const [viewToggleChecked, setViewToggleChecked] = useState(true);
  const { patientId, interactionReferenceId } = useContext(ChartContext);
  const flags = useContext(FeatureFlagContext);

  const omitInteractionReferenceId = interactionReferenceId && viewToggleChecked;
  const viewContext = interactionReferenceId ? 'visit' : 'chart';

  const { data: history, error } = useSWR<GetPropertyHistoryResult>([
    GET_PROPERTY_HISTORY,
    { patientId, propertyName, ...(!omitInteractionReferenceId && { interactionReferenceId }) },
  ]);
  const historyEntries = history?.propertyHistory;

  const isLegacyList = (cpv: HistoricalValue): boolean => {
    const isMissingInternalIds = Array.isArray(cpv.value)
      ? cpv.value.some(listItem => !listItem._internalListItemId)
      : true;
    return !!isMissingInternalIds;
  };

  return (
    <Dialog
      classes={{ scrollPaper: styles.scrollPaper }}
      data-testid="history-modal"
      disablePortal
      open
      maxWidth={
        propertyType === ChartPropertyTypes.List || propertyType === ChartPropertyTypes.Object
          ? 'md'
          : 'sm'
      }
      fullWidth
      onClose={() => setShowHistoryModal(false)}
    >
      <DialogTitle data-testid="history-modal-title" classes={{ root: styles.title }}>
        History
      </DialogTitle>
      <DialogContent classes={{ root: styles.content }}>
        {viewContext === 'visit' ? (
          <FormControlLabel
            label="Display history across all visits"
            labelPlacement="start"
            control={<Switch onChange={event => setViewToggleChecked(event.target.checked)} />}
            className={styles.switch}
            checked={viewToggleChecked}
          />
        ) : null}
        {historyEntries?.length ? (
          <ol className={styles.entries}>
            {historyEntries.map(entry => {
              const isLegacyListValue =
                flags[ADD_IDS_TO_LIST_CHART_PROPERTY_ITEMS] &&
                propertyType === ChartPropertyTypes.List &&
                isLegacyList(entry);

              return (
                <li
                  className={styles.entry}
                  key={`${propertyName}-${entry.createdAt}-${entry.editorName}`}
                >
                  <div className={styles.entryValue}>
                    <div>
                      <ChartPropertyHistoryEntryValue definition={definition} entry={entry} />
                    </div>
                    {entry.notes ? <div className={styles.notes}>Notes: {entry.notes}</div> : null}
                  </div>
                  <div className={styles.entryMeta}>
                    {format(new Date(entry.createdAt), "MMM d, yyyy 'at' p")}
                    {viewContext === 'visit' && viewToggleChecked ? (
                      <VisitEditingLocation
                        cpvInteractionReferenceId={entry.interactionReferenceId}
                        interactionReferenceId={interactionReferenceId}
                      />
                    ) : null}
                    {viewContext === 'chart' ? (
                      <ChartEditingLocation
                        cpvInteractionReferenceId={entry.interactionReferenceId}
                      />
                    ) : null}
                    <div className={styles.editorName}>{entry.editorName}</div>
                  </div>
                  <div className={styles.entryActions}>
                    {!readOnly && entry.action === ChartPropertyAction.Update ? (
                      <Tooltip title={isLegacyListValue ? 'Legacy list cannot be restored' : ''}>
                        <span>
                          <Button
                            disabled={readOnly || isLegacyListValue}
                            onClick={() => {
                              const update = pick(entry, ['value', 'isEmpty', 'notes']);
                              updateProperty({
                                ...update,
                                notes:
                                  update.notes ||
                                  getEmptyValueForChartProperty(ChartPropertyTypes.Text),
                              });
                              setShowHistoryModal(false);
                            }}
                            classes={{ root: classNames(styles.button, styles.restoreButton) }}
                          >
                            Restore
                          </Button>
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                </li>
              );
            })}
          </ol>
        ) : null}
        {/* Only show this message when we successfully got the results and they're empty. Don't show
        it if we just failed to get a response. */}
        {historyEntries?.length === 0
          ? historyEntries !== undefined && <span className={styles.noHistory}>No history.</span>
          : null}
        {error ? <ErrorAlert message="Error loading history." error={error.message} /> : null}
      </DialogContent>
      <DialogActions classes={{ root: styles.footer }}>
        <Button
          tabIndex="-1"
          classes={{ root: classNames(styles.button, styles.closeButton) }}
          onClick={() => setShowHistoryModal(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const VisitEditingLocation: React.FC<{
  interactionReferenceId?: string;
  cpvInteractionReferenceId?: string;
}> = ({ interactionReferenceId, cpvInteractionReferenceId }) => {
  const styles = useStyles();
  if (!cpvInteractionReferenceId) {
    return <div className={styles.editorName}>In the chart</div>;
  } else if (interactionReferenceId === cpvInteractionReferenceId) {
    return <div className={styles.editorName}>In this visit</div>;
  } else if (interactionReferenceId !== cpvInteractionReferenceId) {
    return (
      <RouteLink
        className={styles.otherVisitLink}
        route="showEvent"
        routeParams={[{ id: cpvInteractionReferenceId }]}
        newWindow
      >
        <div className={styles.editorName}>In another visit</div>
      </RouteLink>
    );
  }

  return null;
};

const ChartEditingLocation: React.FC<{ cpvInteractionReferenceId?: string }> = ({
  cpvInteractionReferenceId,
}) => {
  const styles = useStyles();
  return cpvInteractionReferenceId ? (
    <RouteLink
      className={styles.otherVisitLink}
      route="showEvent"
      routeParams={[{ id: cpvInteractionReferenceId }]}
      newWindow
    >
      <div className={styles.editorName}>In a visit</div>
    </RouteLink>
  ) : (
    <div className={styles.editorName}>In the chart</div>
  );
};
