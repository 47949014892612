import { makeStyles } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  errorIcon: {
    marginBottom: 10,
    verticalAlign: 'middle',
    fill: Colors.Coral,
  },
  errorMessage: {
    color: Colors.Coral,
    fontFamily: 'Nunito Sans',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '17.4px',
    textAlign: 'center',
  },
  obstructionist: {
    alignItems: 'center',
    background: 'rgba(255,255,255,0.5)',
    bottom: 0,
    cursor: 'wait',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    margin: 0,
    padding: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  obstructionistError: {
    background: 'rgba(255,255,255,0.7)',
    cursor: 'auto',
    pointerEvents: 'none',
  },
});

export const ChartElementSaveError: React.FC<{ message: string | undefined }> = ({ message }) => {
  const styles = useStyles();

  return (
    <figure
      className={classNames(styles.obstructionist, styles.obstructionistError)}
      data-testid="chart-element-editor-save-error"
    >
      <span className={styles.errorMessage}>
        <ErrorOutlineIcon className={styles.errorIcon} />
        <br />
        Unable to save. <br />
        {message ?? null}
        Please try refreshing the page to try again.
      </span>
    </figure>
  );
};
